import Timesheet from "shared-components/src/models/Timesheet";
import Utils from "shared-components/src/utils/Utils";
import ApiService from "./ApiService";

export default class TimesheetService {
  public static async getById(id: string): Promise<Timesheet | null> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/timesheet/getById/${id}`, "")
        .then((result) => {
          if (result.data) {
            result.data.Date = new Date(result.data.Date);
            result.data.Date = Utils.removeTimezoneOffset(result.data.Date);
            resolve({ id: result.data.id, ...result.data } as Timesheet);
          } else {
            resolve(null);
          }
        })
        .catch((error) => reject(error));
    });
  }

  public static async saveTimesheet(model: Timesheet): Promise<string> {
    (model.Date as any) = Utils.getDateString(model.Date);
    return new Promise((resolve, reject) => {
      ApiService.post("/timesheet/save", model)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  }

  public static async getListInDateRange(teammemberId: string | string[], startDate: Date, endDate: Date): Promise<Timesheet[]> {
    return new Promise((resolve, reject) => {
      const url = "/timesheet/GetDateRangeList";
      const fromDate = Utils.addTimezoneOffsetToStartDate(startDate);
      const toDate = Utils.addTimezoneOffsetToEndDate(endDate);

      ApiService.post(url, { TeammemberId: teammemberId, startDate: fromDate, endDate: toDate })
        .then((result) => {
          if (result.data) {
            result.data.forEach((element: any) => {
              element.Date = new Date(element.Date);
            });
            resolve(result.data as Timesheet[]);
          } else {
            resolve([]);
          }
        })
        .catch((error) => reject(error));
    });
  }

  public static delete(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      ApiService.delete(`/timesheet/remove/${id}`)
        .then((result) => {
          resolve();
        })
        .catch((error) => reject(error));
    });
  }
}
