import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-container" }
const _hoisted_2 = { class: "actions pt-0" }
const _hoisted_3 = { class: "login-link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SignupForm = _resolveComponent("SignupForm")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SignupForm, {
      "form-id": "signup-form",
      onSubmitting: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onSubmitting($event)))
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_v_btn, {
        color: "accent",
        class: "pt-0",
        type: "submit",
        disabled: _ctx.submitting,
        loading: _ctx.submitting,
        form: "signup-form"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode(" Register ")
        ])),
        _: 1
      }, 8, ["disabled", "loading"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_router_link, { to: "/register/login" }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode("I have an account")
        ])),
        _: 1
      })
    ])
  ]))
}