import { AppState } from ".";
import { state } from "./state";

export const getters = {
  userIsAuthenticated: (state: AppState) => {
    return state.userInfo && state.userInfo.isAuthenticated;
  },
  userIsTeamMember: (state: AppState) => {
    return (
      state.userInfo &&
      state.userInfo.isAuthenticated &&
      state.userInfo.roles &&
      (state.userInfo.roles === "Teammember" || state.userInfo.roles === "Linemanager")
    );
  },
  hasPersonalDetail: (state: AppState) => {
    if (state.teamMemberInfo) {
      return state.teamMemberInfo.FirstName &&
        state.teamMemberInfo.LastName &&
        state.teamMemberInfo.Phone &&
        state.teamMemberInfo.Email &&
        state.teamMemberInfo.Country &&
        state.teamMemberInfo.City &&
        state.teamMemberInfo.Nationality &&
        state.teamMemberInfo.BirthDate
        ? true
        : false;
    }
  },
  hasProfessionalDetail: (state: AppState) => {
    if (state.teamMemberInfo) {
      return state.teamMemberInfo.ProfessionalDetail &&
        state.teamMemberInfo.ProfessionalDetail.JobTitleId &&
        state.teamMemberInfo?.SpecificRoles &&
        state.teamMemberInfo?.SpecificRoles.length > 0 &&
        state.teamMemberInfo.ProfessionalDetail.ExperienceYear &&
        state.teamMemberInfo.ProfessionalDetail.CareerObjectives &&
        state.teamMemberInfo.ProfessionalDetail.CareerObjectives.length > 0 &&
        state.teamMemberInfo.ProfessionalDetail.Personality
        ? true
        : false;
    }
  },
  hasCareerHistory: (state: AppState) => {
    if (state.teamMemberInfo) {
      return state.teamMemberInfo.CareerHistoryDescription ? true : false;
    }
  },
  hasProfileDetail: (state: AppState) => {
    if (state.teamMemberInfo) {
      return state.teamMemberInfo.Description && state.teamMemberInfo.CV && state.teamMemberInfo.PhotoUrl ? true : false;
    }
  },
  userIsLinemanager: (state: AppState) => {
    return state.userInfo && state.userInfo.roles && state.userInfo.roles === "Linemanager";
  },
  teamMemberInfo: (state: AppState) => {
    return state.teamMemberInfo;
  },
  careerObjectiveInfo: (state: AppState) => {
    return state.careerObjectives;
  },
  userInfo: (state: AppState) => {
    return state.userInfo;
  },
  showLoading: (state: AppState) => {
    return state.showLoading;
  },
  getSnakbars: (state: AppState) => {
    return state.snakbars;
  },
  getSnakbar: (state: AppState) => (id: any) => {
    return state.snakbars.find((s) => s.id === id);
  },
  profileDropdown: (state: AppState) => {
    return state.isProfileDropdownOpen;
  },
  notificationDropdown: (state: AppState) => {
    return state.isNotificationOpen;
  },
  updateNotificationListFlag: (state: AppState) => {
    return state.updateNotificationListFlag;
  },
};
