import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withKeys as _withKeys } from "vue"

const _hoisted_1 = { style: {"width":"20%"} }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "operation text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_TextField = _resolveComponent("TextField")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_SelectField = _resolveComponent("SelectField")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_confirm_dialog = _resolveComponent("confirm-dialog")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, null, {
          default: _withCtx(() => _cache[8] || (_cache[8] = [
            _createTextVNode(" Authors ")
          ])),
          _: 1
        }),
        _createVNode(_component_v_card_text, { class: "d-flex" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_TextField, {
                class: "me-2 pl-2",
                placeholder: "Search Author",
                modelValue: _ctx.filterText,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterText) = $event)),
                readonly: false
              }, null, 8, ["modelValue"])
            ]),
            _createVNode(_component_v_switch, {
              class: "me-2",
              label: "Unassigned",
              modelValue: _ctx.isUnassigned,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isUnassigned) = $event)),
              color: "success"
            }, null, 8, ["modelValue"]),
            _cache[9] || (_cache[9] = _createTextVNode("    ")),
            _createVNode(_component_v_switch, {
              class: "me-2",
              label: "Show Ignored",
              modelValue: _ctx.showIgnored,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showIgnored) = $event)),
              color: "success"
            }, null, 8, ["modelValue"]),
            _cache[10] || (_cache[10] = _createTextVNode("    "))
          ]),
          _: 1
        }),
        _createVNode(_component_v_card_text, { class: "author-body" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_btn, {
              class: "me-2",
              color: "secondary",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showIgnoreSelectedConfirmDialog())),
              style: {"margin-bottom":"5px"}
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Ignore Selected Authors (" + _toDisplayString(_ctx.selectedAuthors.length) + ")", 1)
              ]),
              _: 1
            }),
            _createVNode(_component_v_data_table, {
              items: _ctx.tempAuthors,
              headers: _ctx.headers,
              loading: _ctx.loading,
              modelValue: _ctx.selected,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selected) = $event))
            }, {
              "item.Select": _withCtx((props) => [
                (!props.item.TeamMemberId)
                  ? (_openBlock(), _createBlock(_component_v_checkbox, {
                      key: 0,
                      modelValue: props.item.Selected,
                      "onUpdate:modelValue": ($event: any) => ((props.item.Selected) = $event)
                    }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                  : _createCommentVNode("", true)
              ]),
              "item.Author": _withCtx((props) => [
                _createElementVNode("strong", null, _toDisplayString(props.item.Author), 1)
              ]),
              "item.TeamMemberId": _withCtx(({ item }) => [
                (!item.TeamMemberId)
                  ? (_openBlock(), _createBlock(_component_SelectField, {
                      key: 0,
                      class: "mt-6",
                      style: {"width":"200px"},
                      placeholder: "Teammembers",
                      items: _ctx.teammembers,
                      "item-title": "FullName",
                      "item-value": "Id",
                      modelValue: item.TeamMemberId,
                      "onUpdate:modelValue": ($event: any) => ((item.TeamMemberId) = $event),
                      outlined: "",
                      onChange: ($event: any) => (_ctx.save(item))
                    }, null, 8, ["items", "modelValue", "onUpdate:modelValue", "onChange"]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(item.TeamMemberName), 1))
              ]),
              "item.Actions": _withCtx(({ item }) => [
                _createElementVNode("span", _hoisted_3, [
                  _createVNode(_component_v_menu, { location: "bottom" }, {
                    activator: _withCtx(({ props}) => [
                      _createVNode(_component_v_icon, _normalizeProps(_guardReactiveProps(props)), {
                        default: _withCtx(() => _cache[11] || (_cache[11] = [
                          _createTextVNode("mdi-dots-vertical")
                        ])),
                        _: 2
                      }, 1040)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_list, null, {
                        default: _withCtx(() => [
                          (item.TeamMemberId)
                            ? (_openBlock(), _createBlock(_component_v_list_item, {
                                key: 0,
                                onClick: ($event: any) => (_ctx.showClearConfirmDialog(item)),
                                "prepend-icon": "mdi-account-remove",
                                title: "Remove Assignment"
                              }, null, 8, ["onClick"]))
                            : _createCommentVNode("", true),
                          _createVNode(_component_v_list_item, {
                            onClick: ($event: any) => (_ctx.showIgnoreConfirmDialog(item)),
                            "prepend-icon": "mdi-account-off",
                            title: "Ignore Author"
                          }, null, 8, ["onClick"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ])
              ]),
              _: 1
            }, 8, ["items", "headers", "loading", "modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_dialog, {
      persistent: "",
      modelValue: _ctx.showConfirmDialog,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.showConfirmDialog) = $event)),
      onKeydown: _cache[7] || (_cache[7] = _withKeys(($event: any) => (_ctx.showConfirmDialog = false), ["esc"])),
      "max-width": "400px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_confirm_dialog, {
          loading: _ctx.dialogLoading,
          onConfirm: _ctx.confirmMethod,
          onCancel: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showConfirmDialog = false)),
          message: _ctx.confirmModel
        }, null, 8, ["loading", "onConfirm", "message"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}