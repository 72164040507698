<template>
  <div>
    <v-row>
      <v-col cols="5">
        <v-text-field
          v-model="searchTitle"
          @keyup.enter="loadRoadMaps"
          placeholder="Search For..."
          outlined
          @click:append-inner="loadRoadMaps"
          hide-details
        >
          <v-icon color="black" @click="loadRoadMaps" class="clickable-icon"> mdi-magnify </v-icon>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="loading-container" v-if="isLoading">
        <v-progress-circular color="primary" indeterminate :size="80"> Loading</v-progress-circular>
      </v-col>
      <v-col v-for="item in roadMaps" :key="item.Id" cols="12" sm="6" md="4" lg="3" v-if="!isLoading">
        <div class="flip-box">
          <div class="flip-box-inner">
            <div class="flip-box-front">
              <v-card class="project-card" :class="{ 'started-card': item.StartedByTM }" @click="navigateToRoadmap(item)">
                <div class="roadmap-details">
                  <div class="roadmap-title">
                    {{ item.Title }}
                  </div>
                  <div class="roadmap-info">
                    <div class="details-section">
                      <div>
                        <v-icon>mdi-subtitles-outline</v-icon>
                        {{ truncate(item.Description) }}
                      </div>
                      <div class="information">
                        <v-icon class="pr-1">mdi-book-open-page-variant</v-icon>
                        <span>{{ getSteps(item) }} </span>
                      </div>
                      <div class="information">
                        <v-icon>mdi-timer-sand-empty</v-icon>
                        <span>{{ formatDuration(item.Duration) }}</span>
                      </div>
                      <div class="information">
                        <v-icon>mdi-account</v-icon>
                        <span>{{ item.Talents.length }}</span>
                      </div>
                      <div>
                        <div v-if="item.Tags">
                          <v-icon class="pr-1">mdi-tag-outline</v-icon>
                          <v-chip v-for="(tag, index) in item.Tags" :key="index" outlined>
                            {{ tag }}
                          </v-chip>
                        </div>
                      </div>
                    </div>
                    <div class="status-section">
                      <div v-if="item.StartedByTM">
                        <v-icon class="icon-color"> mdi-check</v-icon>
                        <span class="pl-2 icon-color">Started</span>
                      </div>
                      <div v-else>
                        <v-icon class="notStarted">mdi-close</v-icon>
                        <span class="pl-2 notStarted">Not Started</span>
                      </div>
                      <div>
                        <v-progress-circular class="mr-5 mb-2" :rotate="-90" :size="50" :width="5" :value="item.value" color="#4caf50">
                          {{ item.ProcessPercent }}
                        </v-progress-circular>
                      </div>
                    </div>
                  </div>
                </div>
              </v-card>
            </div>
            <div class="flip-box-back">
              <v-card class="project-card" :class="{ 'started-card': item.StartedByTM }" @click="navigateToRoadmap(item)">
                <div class="roadmap-details">
                  <div class="roadmap-title">
                    {{ item.Title }}
                  </div>
                  <div class="roadmap-info">
                    <div class="details-section">
                      <div>
                        <v-icon>mdi-subtitles-outline</v-icon>
                        {{ item.Description }}
                      </div>
                    </div>
                  </div>
                </div>
              </v-card>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import TmRoadMapModel from "shared-components/src/models/TmRoadMapModel";
import RoadMapService from "@/services/RoadMapService";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      roadMaps: [] as TmRoadMapModel[],
      isLoading: false,
      searchTitle: "",
    };
  },
  async mounted() {
    await this.loadRoadMaps();
  },
  methods: {
    searchHandler() {},
    truncate(item: any) {
      if (item && item.length > 20) {
        return item.substring(0, 20) + "...";
      } else {
        return item;
      }
    },
    getSteps(roadMap: TmRoadMapModel) {
      return `${roadMap.Steps.filter((item: any) => item.Type == "Course").length} Courses - ${
        roadMap.Steps.filter((item: any) => item.Type == "Assessment").length
      } Assessments`;
    },
    navigateToRoadmap(item: TmRoadMapModel) {
      this.$router.push({
        name: "myRoadMapDetail",
        params: { roadMapId: item.Id },
      });
    },
    async loadRoadMaps() {
      this.isLoading = true;
      this.roadMaps = await RoadMapService.GetTeammemberRoadMaps(this.searchTitle);
      this.isLoading = false;
    },
    formatDuration(duration: number) {
      const hours = Math.floor(duration / 60);
      const minutes = duration % 60;
      return hours > 0 ? `${hours} h, ${minutes} m` : `${minutes} m`;
    },
  },
});
</script>

<style scoped lang="scss">
@import "node_modules/shared-components/assets/colors.scss";

.flip-box {
  background-color: transparent;
  width: 270px;
  height: 380px;
  perspective: 1000px;

  .project-card {
    width: 100%;
    height: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.2) !important;
    border-radius: 10px !important;
    border: 1px solid $text_color !important;
    box-sizing: border-box;

    .roadmap-details {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 15px;
      gap: 20px;

      .roadmap-title {
        font-size: 1.2em;
        font-weight: bold;
        margin-top: 10px;
      }

      .roadmap-info {
        display: flex;
        flex-direction: column;
        height: 100%;

        .details-section {
          display: flex;
          flex-direction: column;
          gap: 20px;
          flex-grow: 1;
        }
        .status-section {
          font-weight: 700;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          flex-grow: 1;
        }
      }
    }
  }

  &:hover .flip-box-inner {
    transform: rotateY(180deg);
  }

  .flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: left;
    transition: transform 0.8s;
    transform-style: preserve-3d;

    .flip-box-front,
    .flip-box-back {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }

    .flip-box-front {
      color: black;
    }

    .flip-box-back {
      color: white;
      transform: rotateY(180deg);
    }
  }
}

.started-card {
  border: 1px solid $success !important;
}

.icon-color {
  color: $success;
}

.notStarted {
  color: $racecar;
}

.information {
  color: #8f8f8f;
  font-weight: 600;
  padding-top: 0px;
}
.search {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 15px;
  margin-bottom: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}
</style>
