<template>
  <v-card :loading="pageLoading" v-if="token && isVerified">
    <v-col cols="12" md="12">
      <h3>Your email address is verified successfully.</h3>
    </v-col>
  </v-card>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import UserService from "@/services/UserService";
import AppHelper from "shared-components/src/utils/AppHelper";
import store from "@/store";

export default defineComponent({
  data() {
    return {
      pageLoading: false,
      isLoading: false,
      token: null as string | null,
      isVerified: false,
    };
  },
  async mounted() {
    if (AppHelper.IsAzureEnv()) {
      this.token = this.$route.query.token as string;
      if (this.token) {
        this.pageLoading = true;
        UserService.validateEmailConfirmation(this.token)
          .then((result) => {
            this.isVerified = true;
            setTimeout(() => {
              this.$router.push("/logout");
            }, 1000);
          })
          .catch((err) => {
            store.dispatch("showErrorMessage", err)
          })
          .finally(() => {
            this.pageLoading = false;
          });
      }
    }
  },
});
</script>
