<template>
  <v-card :disabled="isLoading" :loading="isLoading">
    <div class="course-container">
      <v-row v-if="courseDetail">
        <div class="back-btn">
          <v-btn icon @click="goBack">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </div>
        <!-- <div class="favorite-btn">
          <v-tooltip location="bottom" v-if="courseDetail.IsFavorite != true">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" style="min-width: 0" variant="text" @click="addToFavorite">
                <v-icon> mdi-heart </v-icon>
              </v-btn>
            </template>
            Add to Favorites
          </v-tooltip>
          <v-tooltip location="bottom" v-if="courseDetail.IsFavorite == true">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" style="min-width: 0" variant="text" @click="addToFavorite">
                <v-icon class="heart_icon"> mdi-heart </v-icon>
              </v-btn>
            </template>
            Remove from Favorites
          </v-tooltip>
        </div> -->
        <v-col cols="12">
          <v-card-text>
            <v-row>
              <v-col lg="5" md="5" sm="6" class="d-flex">
                <v-img :src="courseDetail.ImageDownloadUrl" :width="300" cover aspect-ratio="16/9" class="course-image"></v-img>
              </v-col>
              <v-col lg="7" md="7" sm="6" class="details-section">
                <div>
                  <h2>{{ courseDetail.Title }}</h2>
                </div>

                <div class="f-body-1">
                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ props }">
                      <span class="description" v-bind="props">
                        {{ courseDetail.Summary }}
                      </span>
                    </template>
                    Summary
                  </v-tooltip>
                </div>

                <div class="f-body-2">
                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ props }">
                      <span v-bind="props">
                        <v-icon>mdi-human-male-board</v-icon>
                        {{ courseDetail.Author }}
                      </span>
                    </template>
                    Author
                  </v-tooltip>
                </div>

                <div class="f-body-2">
                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ props }">
                      <span v-bind="props">
                        <v-icon>mdi-timer-sand</v-icon>
                        {{ formatDuration(courseDetail.Duration) }}
                      </span>
                    </template>
                    Duration
                  </v-tooltip>
                  -
                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ props }">
                      <span v-bind="props">
                        <v-icon>mdi-bulletin-board</v-icon>
                        {{ courseDetail.Steps.length }} Step
                      </span>
                    </template>
                    {{ getStepDetails }}
                  </v-tooltip>
                  -
                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ props }">
                      <span v-bind="props">
                        <v-icon v-for="(i, index) in getLevelStarsCount(courseDetail.Level)" :key="index">mdi-star</v-icon>
                        {{ courseDetail.Level }}
                      </span>
                    </template>
                    Level
                  </v-tooltip>
                </div>

                <div class="flex-section f-body-2" v-if="courseDetail.Tags.length > 0">
                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ props }">
                      <span v-bind="props">
                        <v-icon>mdi-tag</v-icon>
                      </span>
                    </template>
                    Tags
                  </v-tooltip>
                  <v-chip v-for="(tag, index) in courseDetail.Tags" :key="index">
                    {{ tag.Title }}
                  </v-chip>
                </div>

                <div class="flex-section f-body-2" v-if="courseDetail.Skills.length > 0">
                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ props }">
                      <span v-bind="props">
                        <v-icon>mdi-shape</v-icon>
                      </span>
                    </template>
                    Skill
                  </v-tooltip>
                  <v-chip v-for="(skill, index) in courseDetail.Skills" :key="index">
                    {{ getSkillName(skill) }}
                  </v-chip>
                </div>

                <div class="f-body-2">
                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ props }">
                      <span v-bind="props">
                        <v-icon>mdi-update</v-icon>
                        {{ courseDetail.UpdatedAt }}
                      </span>
                    </template>
                    Last Update
                  </v-tooltip>
                </div>

                <div class="f-body-2" v-if="courseDetail.Dependencies.length > 0">
                  <v-row>
                    <v-col cols="12"> Dependencies: </v-col>
                    <v-col cols="12" v-for="(dependency, index) of courseDetail.Dependencies" :key="index">
                      <a :href="dependency.DependencyId" target="_blank" v-if="dependency.DependencyId" class="dependency-link"
                        >{{ dependency.Text }}
                      </a>
                      <p v-else>{{ dependency.Text }}</p>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="f-body-1">
                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ props }">
                      <span class="description" v-bind="props">
                        {{ courseDetail.Description }}
                      </span>
                    </template>
                    Description
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col cols="12">
                <strong>Steps: </strong>
              </v-col>
              <v-col cols="12">
                <v-expansion-panels variant="accordion">
                  <v-expansion-panel v-for="(step, index) in courseDetail.Steps" :key="index">
                    <div v-if="step.Type == 'Lesson'">
                      <v-expansion-panel-title>
                        <v-row no-gutters>
                          <v-col cols="6">{{ step.Title }}</v-col>
                          <v-col cols="6"> Duration: {{ formatDuration(step.Duration) }} </v-col>
                        </v-row>
                      </v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <div v-html="step.Content"></div>
                      </v-expansion-panel-text>
                    </div>
                    <div v-if="step.Type == 'Assessment'">
                      <v-expansion-panel-title>
                        <v-row no-gutters class="align-center">
                          <v-col cols="6">{{ step.AssessmentDetails?.Title }}</v-col>
                          <v-col cols="4">
                            Duration:
                            {{ getAssessmentDuration(step) }}
                          </v-col>
                          <v-col cols="2">
                            <v-btn @click.stop class="secondary_btn">Preview Assessment</v-btn>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <div>
                          <v-row>
                            <v-col cols="12" v-if="step.AssessmentDetails?.Description">
                              <strong>Description: </strong>
                              {{ step.AssessmentDetails?.Description }}
                            </v-col>
                            <v-col cols="12" v-if="step.AssessmentDetails?.Questions">
                              <strong>Questions: </strong>
                            </v-col>
                            <v-col class="ml-5" cols="12" v-if="step.AssessmentDetails?.Questions">
                              <v-row v-for="(question, index) in step.AssessmentDetails?.Questions" :key="index">
                                <v-col cols="6">
                                  {{ truncate(question.Question) }}
                                </v-col>
                                <v-col cols="4">
                                  <strong>Technologies: </strong>
                                  {{ getAssessmentTechnologies(question) }}
                                </v-col>
                                <v-col cols="2" v-if="step.AssessmentDetails?.Questions">
                                  <strong>Level: </strong>
                                  {{ question.Level }}
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </div>
                      </v-expansion-panel-text>
                    </div>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row> -->
          </v-card-text>
        </v-col>
        <v-col>
          <v-btn variant="tonal" class="primary_btn"> Request to Access </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CourseModel from "../../../../../ProjectPortal-SharedComponents/src/models/CourseModel";
import CourseService from "../../../services/CourseService";
import CoreSkill from "shared-components/src/models/CoreSkill";
import CoreSkillService from "../../../services/CoreSkillService";
import { Question } from "shared-components/src/models/AssessmentModel";

export default defineComponent({
  async mounted() {
    try {
      this.isLoading = true;
      var courseId = this.$route.params.courseId as string;
      await this.loadSkillsList();
      this.courseDetail = await CourseService.GetById(courseId);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      this.isLoading = false;
    }
  },
  data() {
    return {
      courseDetail: null as CourseModel | null,
      isLoading: false,
      skills: [] as CoreSkill[],
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getAssessmentTechnologies(question: Question) {
      return question.Technologies.map((tcItem) => tcItem.Text).join(", ");
    },
    getAssessmentDuration(step: any) {
      return this.formatDuration(
        step.AssessmentDetails?.Questions.reduce((total: any, item: any) => {
          return total + item.Duration;
        }, 0)
      );
    },
    getLevelStarsCount(level: string) {
      switch (level) {
        case "Junior":
          return 1;
        case "MidLevel":
          return 2;
        case "Senior":
          return 3;
      }
    },
    async loadSkillsList() {
      this.skills = await CoreSkillService.getList();
    },
    getSkillName(id: string) {
      return this.skills.filter((item: any) => item.id == id)[0].Text;
    },
    formatDuration(duration: number) {
      const hours = Math.floor(duration / 60);
      const minutes = duration % 60;
      return hours > 0 ? `${hours} h, ${minutes} m` : `${minutes} m`;
    },
    addToFavorite() {
      if (this.courseDetail) {
        this.courseDetail.IsFavorite = !this.courseDetail.IsFavorite;
      }
    },
    editCourse() {
      if (this.courseDetail && this.courseDetail?.Id != null) {
        this.$router.push({
          name: "edit-course",
          params: { courseId: this.courseDetail.Id },
        });
      }
    },
    truncate(item: any) {
      if (item && item.length > 50) {
        return item.substring(0, 50) + "...";
      } else {
        return item;
      }
    },
  },
  computed: {
    getStepDetails(): string {
      if (this.courseDetail) {
        return `${this.courseDetail.Steps.filter((item: any) => item.Type == "Lesson").length} Lesson and ${
          this.courseDetail.Steps.filter((item: any) => item.Type == "Assessment").length
        } Assessment`;
      }
      return "";
    },
  },
});
</script>

<style scoped lang="scss">
@import "node_modules/shared-components/assets/style/font.scss";
@import "node_modules/shared-components/assets/style/color.scss";
.course-container {
  padding: 20px;

  .back-btn {
    position: absolute;
    left: 5px;
  }

  .course-image {
    border-radius: 5px;
  }

  .dependency-link {
    color: $c-white;
  }

  .details-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .flex-section {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  .favorite-btn {
    position: absolute;
    right: 5px;
  }
  .heart_icon {
    color: $c-red;
  }
}
</style>
