import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "border" }
const _hoisted_2 = { class: "search" }
const _hoisted_3 = { class: "lesson-part" }
const _hoisted_4 = { class: "text-container" }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "completed-btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_RoadMapAssessmentDetails = _resolveComponent("RoadMapAssessmentDetails")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_tab_item = _resolveComponent("v-tab-item")!
  const _component_v_tabs = _resolveComponent("v-tabs")!

  return (_openBlock(), _createBlock(_component_v_card, {
    class: "main-container",
    loading: _ctx.isLoading
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_v_toolbar_title, { class: "title" }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Search")
            ])),
            _: 1
          }),
          _createVNode(_component_v_text_field, {
            placeholder: "Search in course",
            outlined: "",
            "append-icon": "mdi-magnify"
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_v_tabs, {
            vertical: "",
            modelValue: _ctx.activeTabIndex,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTabIndex) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, {
                class: "left-chevron tab-expand-icon",
                onClick: _ctx.hideMenu
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(!_ctx.menuExpanded ? "mdi-chevron-left" : "mdi-chevron-right"), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.courseModel.Steps, (step, index) => {
                return (_openBlock(), _createBlock(_component_v_tab, {
                  key: index,
                  class: "menu"
                }, {
                  default: _withCtx(() => [
                    (step.IsCompeleted)
                      ? (_openBlock(), _createBlock(_component_v_icon, {
                          key: 0,
                          left: "",
                          class: "completed-btn"
                        }, {
                          default: _withCtx(() => _cache[2] || (_cache[2] = [
                            _createTextVNode("mdi-checkbox-marked-circle")
                          ])),
                          _: 1
                        }))
                      : (_openBlock(), _createBlock(_component_v_icon, {
                          key: 1,
                          left: ""
                        }, {
                          default: _withCtx(() => _cache[3] || (_cache[3] = [
                            _createTextVNode("mdi-circle-outline")
                          ])),
                          _: 1
                        })),
                    (!_ctx.menuExpanded)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(step.Type == "Lesson" ? step.Title : step.AssessmentDetails?.Title), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1024))
              }), 128)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.courseModel.Steps, (step, index) => {
                return (_openBlock(), _createBlock(_component_v_tab_item, { key: index }, {
                  default: _withCtx(() => [
                    (_ctx.activeTabIndex === index)
                      ? (_openBlock(), _createBlock(_component_v_card, {
                          key: 0,
                          class: "tab-container",
                          flat: ""
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("template", null, [
                              (step.Type == 'Lesson')
                                ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    innerHTML: step.Content
                                  }, null, 8, _hoisted_6))
                                : _createCommentVNode("", true),
                              (step.Type == 'Assessment' && step.AssessmentDetails)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                    _createVNode(_component_RoadMapAssessmentDetails, {
                                      assessmentModel: step.AssessmentDetails,
                                      courseId: _ctx.courseId,
                                      roadMapId: _ctx.roadMapId,
                                      onNextStep: _ctx.goToNextLesson,
                                      onAssessmentSubmited: _ctx.AssessmentSubmited
                                    }, null, 8, ["assessmentModel", "courseId", "roadMapId", "onNextStep", "onAssessmentSubmited"])
                                  ]))
                                : _createCommentVNode("", true)
                            ]),
                            _createVNode(_component_v_row, null, {
                              default: _withCtx(() => [
                                (!step.IsCompeleted && step.Type != 'Assessment')
                                  ? (_openBlock(), _createBlock(_component_v_col, {
                                      key: 0,
                                      class: "buttons"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_btn, {
                                          onClick: ($event: any) => (_ctx.updateCourseStepState(true, step.Id)),
                                          loading: _ctx.isLoading,
                                          disabled: _ctx.isLoading
                                        }, {
                                          default: _withCtx(() => _cache[4] || (_cache[4] = [
                                            _createTextVNode("Mark as Completed")
                                          ])),
                                          _: 2
                                        }, 1032, ["onClick", "loading", "disabled"])
                                      ]),
                                      _: 2
                                    }, 1024))
                                  : _createCommentVNode("", true),
                                (step.IsCompeleted && step.Type != 'Assessment')
                                  ? (_openBlock(), _createBlock(_component_v_col, {
                                      key: 1,
                                      cols: "12",
                                      class: "buttons"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_btn, {
                                          class: "secondary_btn",
                                          onClick: _ctx.goToNextLesson,
                                          loading: _ctx.isLoading,
                                          disabled: _ctx.isLoading
                                        }, {
                                          default: _withCtx(() => _cache[5] || (_cache[5] = [
                                            _createTextVNode("Next Step")
                                          ])),
                                          _: 1
                                        }, 8, ["onClick", "loading", "disabled"]),
                                        _createElementVNode("span", _hoisted_8, [
                                          _cache[7] || (_cache[7] = _createTextVNode(" completed ")),
                                          _createVNode(_component_v_icon, { class: "completed-btn" }, {
                                            default: _withCtx(() => _cache[6] || (_cache[6] = [
                                              _createTextVNode("mdi-check")
                                            ])),
                                            _: 1
                                          })
                                        ])
                                      ]),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["loading"]))
}