import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "assessment-list" }
const _hoisted_2 = { class: "card-list" }
const _hoisted_3 = {
  key: 0,
  class: "loading-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_overlay = _resolveComponent("v-overlay")!
  const _component_AssessmentFilter = _resolveComponent("AssessmentFilter")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_AssessmentItems = _resolveComponent("AssessmentItems")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_empty_course_card = _resolveComponent("empty-course-card")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_alert = _resolveComponent("v-alert")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_overlay, {
      modelValue: _ctx.overlay,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.overlay) = $event)),
      "scroll-strategy": "block",
      "location-strategy": "connected",
      opacity: "0.8",
      persistent: "",
      onClick: _ctx.clickOnOverlay
    }, null, 8, ["modelValue", "onClick"]),
    _createVNode(_component_AssessmentFilter, {
      onShowOverlay: _ctx.filterToggle,
      onTimeChipClosed: _ctx.handelTimeChipClosed,
      onDoSearch: _ctx.handleSearchFilter,
      showAssessmentBtn: _ctx.assessmentBtnVisiblity,
      top: _ctx.topValue,
      height: _ctx.heightValue,
      padding: _ctx.paddingValue,
      overlay: _ctx.overlay,
      showFilter: _ctx.showFilter,
      skills: _ctx.skills
    }, null, 8, ["onShowOverlay", "onTimeChipClosed", "onDoSearch", "showAssessmentBtn", "top", "height", "padding", "overlay", "showFilter", "skills"]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_v_progress_circular, {
              color: "primary",
              indeterminate: "",
              size: 80
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode(" Loading")
              ])),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          (!_ctx.isLoading)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.assessments, (assessment) => {
                return (_openBlock(), _createBlock(_component_v_col, {
                  xs: "12",
                  sm: "4",
                  md: "3",
                  key: assessment.Id
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_AssessmentItems, { assessment: assessment }, null, 8, ["assessment"])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            : _createCommentVNode("", true),
          (_ctx.showEmptyAssessmentCard && !_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_v_col, {
                key: 1,
                cols: "12"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_empty_course_card)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    (_ctx.showErrorAlert)
      ? (_openBlock(), _createBlock(_component_v_alert, {
          key: 0,
          type: "error",
          dismissible: "",
          onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showErrorAlert = false))
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode(" Error fetching data. Please try again. ")
          ])),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}