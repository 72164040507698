<template>
  <v-row>
    <v-col md="1" class="pa-1" v-for="date in dates" :key="`date-${date.name}`">
      <v-col class="dateItem" :class="date.isClicked ? 'selectedDate' : ''" @click="selectMonth(date)">
        <span>{{ date.name }}</span>
      </v-col>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Utils from "shared-components/src/utils/Utils";
interface Month {
  name: string;
  startDate: Date;
  endDate: Date;
  isClicked: boolean;
}
export default defineComponent({
  props: ["selectLastMonth"],
  data() {
    return {
      dates: [] as Month[],
    };
  },
  async mounted() {
    this.lasttwelvemonth();

    await this.$emit("filterDataByDate", { startDate: this.dates[0].startDate, endDate: this.dates[11].endDate });
  },
  methods: {
    selectMonth(date: Month) {
      var dateItem = this.dates.find((c) => c.startDate === date.startDate);
      if (dateItem) {
        dateItem.isClicked = !dateItem.isClicked;
      }
      var selectedDates = this.dates.filter((c) => c.isClicked);
      this.$emit("filterDataByDate", selectedDates);
    },
    subtractMonths(date: Date, months: number) {
      date.setMonth(date.getMonth() - months);
      return date;
    },
    lasttwelvemonth() {
      var dates = [] as Date[];
      var d = new Date();
      var y = d.getFullYear();
      var m = d.getMonth() + 1;
      const today = new Date();
      const lastTwelveMonths = [] as Month[];
      for (let i = 11; i >= 0; i--) {
        var firstDayOfMonth = new Date(today.getFullYear(), today.getMonth() - i, 1);
        var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() - i + 1, 0);
        lastDayOfMonth = Utils.setDateTimes(lastDayOfMonth, 23, 59, 59, 999) as Date;
        lastTwelveMonths.push({
          startDate: firstDayOfMonth,
          endDate: lastDayOfMonth,
          name: firstDayOfMonth.toLocaleString("en-us", { month: "short" }),
          isClicked: false,
        });
      }
      this.dates= lastTwelveMonths;
    },
  },
  watch: {
    selectLastMonth(newVal) {
      if (newVal) {
        this.selectMonth(this.dates[this.dates.length - 1]);
      }
    },
  },
});
</script>
<style lang="scss" scoped>
@import "node_modules/shared-components/assets/colors.scss";
@import "node_modules/shared-components/assets/sizes.scss";
.dateItem {
  text-align: center;
  border-radius: 10px;
  border: 1px solid $track_lane;
  cursor: pointer;
}
.selectedDate {
  box-shadow: 0px 0px 10px 3px $racecar;
  color: $racecar;
}
</style>
