import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "action-btns mt-3" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "completed-btn" }
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item_avatar = _resolveComponent("v-list-item-avatar")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_content = _resolveComponent("v-list-item-content")!
  const _component_v_list_item_action = _resolveComponent("v-list-item-action")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_AssessmentEditor = _resolveComponent("AssessmentEditor")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_ctx.assessmentModel)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.assessmentInProgressOrNotStarted)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "12",
                    class: "text-center"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("h2", null, "Test Time: " + _toDisplayString(_ctx.formatDuration(_ctx.assessmentModel.Questions.reduce((total, a) => total + a.Duration, 0))), 1),
                      (_ctx.assessmentModel.PassScore)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Pass Score: " + _toDisplayString(_ctx.assessmentModel.PassScore), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    class: "f-body-1"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.assessmentModel.Title), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    class: "f-body-2"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.assessmentModel.Description), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_4, [
                (_ctx.assessmentModel.SubmitTime == null && _ctx.assessmentModel.EndTime == null)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 0,
                      class: "primary_btn",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.startAssessment())),
                      disabled: _ctx.isLoading,
                      loading: _ctx.isLoading
                    }, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode("Start")
                      ])),
                      _: 1
                    }, 8, ["disabled", "loading"]))
                  : _createCommentVNode("", true),
                (_ctx.assessmentModel.SubmitTime != null || _ctx.EndDateIsPassed())
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      (_ctx.assessmentModel.IsRestartable)
                        ? (_openBlock(), _createBlock(_component_v_btn, {
                            key: 0,
                            class: "primary_btn mr-2",
                            onClick: _ctx.RestartAssessment,
                            disabled: _ctx.isLoading,
                            loading: _ctx.isLoading
                          }, {
                            default: _withCtx(() => _cache[4] || (_cache[4] = [
                              _createTextVNode("Restart Assessment")
                            ])),
                            _: 1
                          }, 8, ["onClick", "disabled", "loading"]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_v_btn, {
                        class: "primary_btn",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.nextStep())),
                        disabled: _ctx.isLoading,
                        loading: _ctx.isLoading
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.courseId ? "Next Step" : "Return To RoadMap"), 1)
                        ]),
                        _: 1
                      }, 8, ["disabled", "loading"]),
                      _createElementVNode("span", _hoisted_6, [
                        _cache[6] || (_cache[6] = _createTextVNode(" completed ")),
                        _createVNode(_component_v_icon, { class: "completed-btn" }, {
                          default: _withCtx(() => _cache[5] || (_cache[5] = [
                            _createTextVNode("mdi-check")
                          ])),
                          _: 1
                        })
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        (!_ctx.assessmentInProgressOrNotStarted && _ctx.assessmentModel.Questions.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _createElementVNode("h2", null, "Remaining Time: " + _toDisplayString(_ctx.timerValue), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list, null, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.assessmentModel.Questions, (question, index) => {
                            return (_openBlock(), _createBlock(_component_v_list_item, { key: index }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_list_item_avatar, null, {
                                  default: _withCtx(() => [
                                    (question.Status != 'Submitted')
                                      ? (_openBlock(), _createBlock(_component_v_icon, { key: 0 }, {
                                          default: _withCtx(() => _cache[7] || (_cache[7] = [
                                            _createTextVNode("mdi-circle-outline")
                                          ])),
                                          _: 1
                                        }))
                                      : _createCommentVNode("", true),
                                    (question.Status == 'Submitted')
                                      ? (_openBlock(), _createBlock(_component_v_icon, { key: 1 }, {
                                          default: _withCtx(() => _cache[8] || (_cache[8] = [
                                            _createTextVNode("mdi-circle")
                                          ])),
                                          _: 1
                                        }))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_v_list_item_content, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_list_item_title, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(question.Title), 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_v_list_item_action, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_btn, {
                                      color: "grey-lighten-1",
                                      onClick: ($event: any) => (_ctx.startQuestion(question)),
                                      disabled: _ctx.isLoading,
                                      loading: _ctx.isLoading
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(question.Status != "Submitted" ? "Start" : "Edit"), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick", "disabled", "loading"])
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        color: "grey-lighten-1",
                        onClick: _ctx.SubmitAssessment,
                        disabled: _ctx.isLoading,
                        loading: _ctx.isLoading
                      }, {
                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                          _createTextVNode(" Submit Assessment ")
                        ])),
                        _: 1
                      }, 8, ["onClick", "disabled", "loading"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.showEditor,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showEditor) = $event)),
          fullscreen: "",
          "hide-overlay": "",
          scrollable: false,
          "content-class": "assessment-editor"
        }, {
          default: _withCtx(() => [
            (_ctx.selectedQuestion)
              ? (_openBlock(), _createBlock(_component_AssessmentEditor, {
                  key: 0,
                  Question: _ctx.selectedQuestion,
                  assessmentId: _ctx.assessmentModel.Id,
                  courseId: _ctx.courseId,
                  roadMapId: _ctx.roadMapId,
                  onSubmitted: _ctx.QuestionSubmitted
                }, null, 8, ["Question", "assessmentId", "courseId", "roadMapId", "onSubmitted"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]))
    : _createCommentVNode("", true)
}