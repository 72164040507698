<template>
  <div>
    <repository-list
      :repositories="repositories"
      :pageLoading="pageLoading"
      :commitments="commitments"
      :isAdmin="false"
      @fetchRepositories="fetchRepositories"
      @errorRaised="errorRaised"
    ></repository-list>
  </div>
</template>

<script lang="ts">
import RepositoryList from "shared-components/src/components/Repository/List.vue";
import RepositoryService from "shared-components/src/services/RepositoryService";
import { TeammemberRepositoryVM } from "shared-components/src/viewModels/response/repository/TeammemberRepositoryVM";
import { defineComponent } from "vue";
import { mapMutations } from "vuex";
import { HIDDEN_LOADING, SHOW_LOADING } from "@/store/types";
import store from "@/store";
import { UICommitment } from "shared-components/src/models/Commitment";
import TeamMemberService from "@/services/TeamMemberService";

export default defineComponent({
  components: {
    RepositoryList,
  },
  data() {
    return {
      repositories: [] as TeammemberRepositoryVM[],
      commitments: [] as UICommitment[],
      pageLoading: true,
    };
  },
  methods: {
    ...mapMutations([SHOW_LOADING, HIDDEN_LOADING]),
    errorRaised(msg: string): void {
      store.dispatch("showErrorMessage", msg);
    },
    async fetchRepositories() {
      try {
        this.pageLoading = true;
        this.commitments = await TeamMemberService.getCommitmentsByTeammeberId();
        this.repositories = await RepositoryService.getRepositoriesByTeamMember();
        this.pageLoading = false;
      } catch (error) {
        this.pageLoading = false;
      }
    },
  },
});
</script>
