import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column assesmentBox" }
const _hoisted_2 = { class: "d-flex flex-column justify-space-between ga-16" }
const _hoisted_3 = { style: {"width":"100%"} }
const _hoisted_4 = { class: "d-flex justify-end ga-2 pt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopBar = _resolveComponent("TopBar")!
  const _component_QuestionBox = _resolveComponent("QuestionBox")!
  const _component_AssessmentEditor = _resolveComponent("AssessmentEditor")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_AnswerBox = _resolveComponent("AnswerBox")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_card, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_TopBar, {
            minutes: _ctx.assessmentModel.Questions.reduce((total, a) => total + a.Duration, 0),
            currentQuestionNumber: _ctx.currentQuestionNumber,
            questionCount: _ctx.questionCount
          }, null, 8, ["minutes", "currentQuestionNumber", "questionCount"]),
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "titleBox" }, "Enter the Correct Answer", -1)),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_QuestionBox, {
              title: _ctx.selectedQuestion?.Question
            }, null, 8, ["title"]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_v_dialog, {
                modelValue: _ctx.showEditor,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showEditor) = $event)),
                fullscreen: "",
                "hide-overlay": "",
                scrollable: false,
                "content-class": "assessment-editor"
              }, {
                default: _withCtx(() => [
                  (_ctx.selectedQuestion)
                    ? (_openBlock(), _createBlock(_component_AssessmentEditor, {
                        key: 0,
                        Question: _ctx.selectedQuestion,
                        assessmentId: _ctx.assessmentModel.Id,
                        courseId: _ctx.courseId,
                        roadMapId: _ctx.roadMapId,
                        onSubmitted: _ctx.QuestionSubmitted
                      }, null, 8, ["Question", "assessmentId", "courseId", "roadMapId", "onSubmitted"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["modelValue"]),
              (_ctx.hasEditor)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 0,
                    onClick: _ctx.setShowEditor
                  }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode("Please click to open editors")
                    ])),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true),
              (!_ctx.hasEditor)
                ? (_openBlock(), _createBlock(_component_AnswerBox, {
                    key: 1,
                    options: [1, 2, 3, 4]
                  }))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_v_btn, {
              class: "back_btn",
              dark: "",
              onClick: _ctx.backQuestion,
              disabled: !_ctx.enabeldBack
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, { size: "x-large" }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("mdi-chevron-left")
                  ])),
                  _: 1
                }),
                _cache[3] || (_cache[3] = _createTextVNode(" Back "))
              ]),
              _: 1
            }, 8, ["onClick", "disabled"]),
            _createVNode(_component_v_btn, {
              class: "next_btn",
              dark: "",
              onClick: _ctx.nextQuestion,
              disabled: !_ctx.enabeldNext
            }, {
              default: _withCtx(() => [
                _cache[5] || (_cache[5] = _createTextVNode(" Next ")),
                _createVNode(_component_v_icon, { size: "x-large" }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode("mdi-chevron-right")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["onClick", "disabled"])
          ])
        ])
      ]),
      _: 1
    })
  ]))
}