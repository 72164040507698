<template>
  <timesheet-report :isAdmin="false" @errorRaised="errorRaised" />
</template>
<script lang="ts">
import { defineComponent } from "vue";
import TimesheetReport from "shared-components/src/components/Reports/Timesheet/TimesheetReport.vue";
import store from "@/store";

export default defineComponent({
  components: {
    TimesheetReport,
  },
  methods: {
    errorRaised(msg: string): void {
      store.dispatch("showErrorMessage", msg)
    },
  },
});
</script>
