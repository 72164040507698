import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "courses_container" }
const _hoisted_2 = { class: "card-list" }
const _hoisted_3 = {
  key: 0,
  class: "loading-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_overlay = _resolveComponent("v-overlay")!
  const _component_CourseFilterTM = _resolveComponent("CourseFilterTM")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_CourseListItems = _resolveComponent("CourseListItems")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_EmptyCourseCard = _resolveComponent("EmptyCourseCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_overlay, {
      class: "filter-overlay",
      modelValue: _ctx.overlay,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.overlay) = $event)),
      "scroll-strategy": "block",
      "location-strategy": "connected",
      opacity: "0.8",
      persistent: "",
      onClick: _ctx.clickOnOverlay
    }, null, 8, ["modelValue", "onClick"]),
    _createVNode(_component_CourseFilterTM, {
      onShowOverlay: _ctx.filterToggle,
      onBackBtnClicked: _ctx.handelBackToRoadMap,
      onDoSearch: _ctx.handleSearchFilter,
      onTimeChipClosed: _ctx.handelTimeChipClosed,
      ShowBackBtn: _ctx.backButtonVisibility,
      top: _ctx.topValue,
      height: _ctx.heightValue,
      padding: _ctx.paddingValue,
      overlay: _ctx.overlay,
      showFilter: _ctx.showFilter,
      skills: _ctx.skills
    }, null, 8, ["onShowOverlay", "onBackBtnClicked", "onDoSearch", "onTimeChipClosed", "ShowBackBtn", "top", "height", "padding", "overlay", "showFilter", "skills"]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_v_progress_circular, {
              color: "primary",
              indeterminate: "",
              size: 80
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode(" Loading")
              ])),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (!_ctx.isLoading)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.courses, (course) => {
            return (_openBlock(), _createBlock(_component_v_col, {
              cols: "12",
              key: course.Id
            }, {
              default: _withCtx(() => [
                _createVNode(_component_CourseListItems, {
                  course: course,
                  skills: _ctx.skills
                }, null, 8, ["course", "skills"])
              ]),
              _: 2
            }, 1024))
          }), 128))
        : _createCommentVNode("", true),
      (_ctx.showEmptyCourseCard && !_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_v_col, {
            key: 2,
            cols: "12"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_EmptyCourseCard)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}