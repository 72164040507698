import Timesheet from "shared-components/src/models/Timesheet";
import TeammemberDashboard from "shared-components/src/models/TeammemberDashboard";
import Utils from "shared-components/src/utils/Utils";
import ApiService from "./ApiService";
import { SystemErrors } from "shared-components/src/definitions/systemErrors";

export default class DashboardReportService {
  public static async getList(startDate: Date, endDate: Date): Promise<TeammemberDashboard> {
    return new Promise((resolve, reject) => {
      const sDate = Utils.addTimezoneOffsetToStartDate(startDate);
      const eDate = Utils.addTimezoneOffsetToEndDate(endDate);
      ApiService.post(`/reports/teammemberdahsboard`, { startDate: sDate, endDate: eDate })
        .then((result) => {
          if (!result.data || result.data.statusCode != SystemErrors.Authentication.Success || !result.data.value) {
            resolve({} as TeammemberDashboard);
          } else {
            var timesheets = [] as Timesheet[];
            var dashboardReport = result.data.value as TeammemberDashboard;
            result.data.value.timesheets.forEach((doc: any) => {
              if (doc.Date) {
                doc.Date = Utils.vsDateToDatetime(doc.Date);
              }
              const item = { id: doc.id, ...doc } as Timesheet;
              timesheets.push(item);
            });
            dashboardReport.timesheets = timesheets;
            resolve(dashboardReport);
          }
        })
        .catch((error) => reject(error));
    });
  }
}
