<template>
  <div class="header-title">
    <v-app-bar-nav-icon class="nav-icon">
      <i
        class="icon"
        :class="{
          'v-icon': true,
          notranslate: true,
          mdi: true,
          dark: false,
          [icon]: true,
        }"
      ></i>
    </v-app-bar-nav-icon>
    <div class="page-name">{{ title }}</div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      icon: "",
      title: "",
    };
  },
  created() {
    this.icon = this.$route.meta.icon;
    this.title = this.$route.meta.title;
  },
  watch: {
    $route() {
      this.icon = this.$route.meta.icon;
      this.title = this.$route.meta.title;
    },
  },
});
</script>

<style scoped lang="scss">
@import "node_modules/shared-components/assets/colors.scss";
.header-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .page-name {
    font-family: Teko, sans-serif !important;
    color: $dark_gray;
    font-size: 24px;
    font-weight: 700;
    margin-left: 5px;
  }
  .icon {
    color: $dark_gray;
    width: 45px;
  }
}
@media (max-width: 959px) {
  .nav-icon {
    display: none;
  }
}
</style>
