<template>
  <v-card :loading="isLoading">
    <div class="course-container">
      <v-row v-if="courseModel">
        <div class="back-btn">
          <v-btn icon @click="goBack">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </div>
        <div class="favorite-btn">
          <v-tooltip location="bottom">
            <template v-slot:activator="{ props }" class="buttons">
              <v-btn v-bind="props" style="min-width: 0" icon>
                <v-icon>mdi-heart</v-icon>
              </v-btn>
            </template>
            Add to Favorites
          </v-tooltip>
        </div>
        <v-col cols="12">
          <v-card-text>
            <v-row>
              <v-col lg="5" md="5" sm="6" class="d-flex">
                <v-img :src="courseModel.ImageDownloadUrl" :width="300" cover aspect-ratio="16/9" class="course-image"></v-img>
              </v-col>
              <v-col lg="7" md="7" sm="6" class="details-section">
                <div>
                  <h2>{{ courseModel.Title }}</h2>
                </div>

                <div class="f-body-1">
                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ props }">
                      <span class="description" v-bind="props">
                        {{ courseModel.Summary }}
                      </span>
                    </template>
                    Summary
                  </v-tooltip>
                </div>

                <div class="f-body-2">
                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ props }">
                      <span v-bind="props">
                        <v-icon>mdi-human-male-board</v-icon>
                        {{ courseModel.Author }}
                      </span>
                    </template>
                    Author
                  </v-tooltip>
                </div>

                <div class="f-body-2">
                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ props }">
                      <span v-bind="props">
                        <v-icon>mdi-timer-sand</v-icon>
                        {{ formatDuration(courseModel.Duration) }}
                      </span>
                    </template>
                    Duration
                  </v-tooltip>
                  -
                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon>mdi-bulletin-board</v-icon>
                        {{ courseModel.Steps.length }} Step
                      </span>
                    </template>
                    {{ courseModel.Steps.filter((item) => item.Type == "Lesson").length }}
                    Lesson and
                    {{ courseModel.Steps.filter((item) => item.Type == "Assessment").length }}
                    Assessment
                  </v-tooltip>
                  -
                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ props }">
                      <span v-bind="props">
                        <v-icon v-for="(i, index) in getLevelStarsCount(courseModel.Level)" :key="index">mdi-star</v-icon>
                        {{ courseModel.Level }}
                      </span>
                    </template>
                    Level
                  </v-tooltip>
                </div>

                <div class="flex-section f-body-2" v-if="courseModel.Tags.length > 0">
                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ props }">
                      <span v-bind="props">
                        <v-icon>mdi-tag</v-icon>
                      </span>
                    </template>
                    Tags
                  </v-tooltip>
                  <v-chip v-for="(tag, index) in courseModel.Tags" :key="index">
                    {{ tag.Title }}
                  </v-chip>
                </div>

                <div class="flex-section f-body-2" v-if="courseModel.Skills.length > 0">
                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ props }">
                      <span v-bind="props">
                        <v-icon>mdi-shape</v-icon>
                      </span>
                    </template>
                    Skill
                  </v-tooltip>
                  <v-chip v-for="(skill, index) in courseModel.Skills" :key="index">
                    {{ getSkillName(skill) }}
                  </v-chip>
                </div>

                <div class="f-body-2">
                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ props }">
                      <span v-bind="props">
                        <v-icon>mdi-update</v-icon>
                        {{ courseModel.UpdatedAt }}
                      </span>
                    </template>
                    Last Update
                  </v-tooltip>
                </div>

                <div class="f-body-2" v-if="courseModel.Dependencies.length > 0">
                  <v-row>
                    <v-col cols="12"> Dependencies: </v-col>
                    <v-col cols="12" v-for="(dependency, index) of courseModel.Dependencies" :key="index">
                      <a :href="dependency.DependencyId" target="_blank" v-if="dependency.DependencyId" class="dependency-link"
                        >{{ dependency.Text }}
                      </a>
                      <p v-else>{{ dependency.Text }}</p>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="f-body-1">
                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ props }">
                      <span class="description" v-bind="props">
                        {{ courseDetail.Description }}
                      </span>
                    </template>
                    Description
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <strong>Steps: </strong>
              </v-col>
              <v-col cols="12" class="pt-5 pb-5">
                <v-list>
                  <v-list-item v-for="(step, index) in courseModel.Steps" :key="index">
                    <div v-if="step.Type == 'Lesson'">{{ step.Title }} - {{ formatDuration(step.Duration) }}</div>
                    <div v-if="step.Type == 'Assessment'">
                      {{ step.AssessmentDetails.Title }} -
                      {{ formatDuration(step.AssessmentDetails.Questions.reduce((total, item) => total + item.Duration, 0)) }}
                    </div>
                  </v-list-item>
                </v-list>
                <v-btn variant="tonal" class="start-button" @click="navigateToLessons"> Start the Course </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script lang="ts">
import CourseModel from "../../../../../ProjectPortal-SharedComponents/src/models/CourseModel";
import CoreSkill from "shared-components/src/models/CoreSkill";
import RoadMapService from "@/services/RoadMapService";
import CoreSkillService from "@/services/CoreSkillService";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    roadMapId: {
      type: String,
      required: true,
    },
    courseId: {
      type: String,
      required: true,
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.loadSkillsList();
    await this.loadCourseModels();
    this.isLoading = false;
  },
  data() {
    return {
      courseModel: null as CourseModel | null,
      showErrorAlert: false,
      isLoading: false,
      skills: [] as CoreSkill[],
    };
  },
  methods: {
    async loadCourseModels() {
      if (this.roadMapId && this.courseId) {
        this.courseModel = await RoadMapService.GetTeammemberRoadMapCourseDetails(this.roadMapId, this.courseId);
      }
    },
    navigateToLessons() {
      this.$router.push({
        name: "LessonsMenu",
        params: {
          title: this.$route.params.title,
          id: this.$route.params.id,
        },
      });
    },
    getLevelStarsCount(level: string) {
      switch (level) {
        case "Junior":
          return 1;
        case "MidLevel":
          return 2;
        case "Senior":
          return 3;
      }
    },
    async loadSkillsList() {
      this.skills = await CoreSkillService.getList();
    },
    getSkillName(id: string) {
      return this.skills.filter((item: any) => item.id == id)[0]?.Text;
    },
    formatDuration(duration: number) {
      const hours = Math.floor(duration / 60);
      const minutes = duration % 60;
      return hours > 0 ? `${hours} h, ${minutes} m` : `${minutes} m`;
    },
    goBack() {
      this.$router.go(-1);
    },
  },
});
</script>

<style scoped lang="scss">
@import "node_modules/shared-components/assets/style/font.scss";
@import "node_modules/shared-components/assets/colors.scss";

.course-container {
  padding: 20px;

  .course-image {
    border-radius: 5px;
  }

  .details-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .flex-section {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  .favorite-btn {
    position: absolute;
    right: 5px;
  }

  .back-btn {
    position: absolute;
    left: 5px;
  }

  .heart_icon {
    color: $racecar;
  }

  .start-button {
    background-color: $racecar;
    left: 0px;
    margin-top: 15px;
    margin-bottom: 0px;
  }

  .single-line-content {
    max-height: 1.5em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .detail-lesson {
    padding: 15px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #242424 !important;
    border: 1px solid #242424 !important;
  }
}
</style>
