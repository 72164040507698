import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "h-100" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "loading-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_btn, {
      class: "back-button",
      onClick: _ctx.goBack
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_icon, null, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("mdi-arrow-left")
          ])),
          _: 1
        }),
        _cache[1] || (_cache[1] = _createTextVNode("Back"))
      ]),
      _: 1
    }, 8, ["onClick"]),
    (_ctx.roadMap && !_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("ol", _hoisted_2, [
          _createElementVNode("li", null, [
            _createVNode(_component_v_card, { class: "project-card start-end-card" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "title" }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("Start")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roadMap.Steps, (step, index) => {
            return (_openBlock(), _createElementBlock("li", { key: index }, [
              (step.Type == 'Course')
                ? (_openBlock(), _createBlock(_component_v_card, {
                    key: 0,
                    class: _normalizeClass(["project-card", _ctx.getStepStyle(step)]),
                    onClick: ($event: any) => (_ctx.navigateToCourseDetail(step)),
                    disabled: index != 0 && _ctx.roadMap.Steps[index - 1].TalentState != 'Completed'
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, { class: "title" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(step.CourseDetail.Title), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_v_card_text, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_row, null, {
                            default: _withCtx(() => [
                              (step.IsFavorite)
                                ? (_openBlock(), _createBlock(_component_v_col, { key: 0 }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_icon, { class: "icon-color" }, {
                                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                                          _createTextVNode("mdi-check")
                                        ])),
                                        _: 1
                                      }),
                                      _cache[4] || (_cache[4] = _createElementVNode("span", { class: "pl-2 icon-color" }, "Completed", -1))
                                    ]),
                                    _: 1
                                  }))
                                : (_openBlock(), _createBlock(_component_v_col, {
                                    key: 1,
                                    cols: "12",
                                    class: "rate"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_icon, null, {
                                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                                          _createTextVNode("mdi-star")
                                        ])),
                                        _: 1
                                      }),
                                      _cache[6] || (_cache[6] = _createElementVNode("span", { class: "pl-2" }, "3.5", -1))
                                    ]),
                                    _: 1
                                  })),
                              _createVNode(_component_v_col, { cols: "12" }, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", null, _toDisplayString(step.CourseDetail.Level), 1),
                                  _cache[7] || (_cache[7] = _createTextVNode(" - ")),
                                  _createElementVNode("span", null, _toDisplayString(_ctx.formatDuration(step.CourseDetail.Duration)), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["class", "onClick", "disabled"]))
                : _createCommentVNode("", true),
              (step.Type == 'Assessment')
                ? (_openBlock(), _createBlock(_component_v_card, {
                    key: 1,
                    class: _normalizeClass(["project-card", _ctx.getStepStyle(step)]),
                    onClick: ($event: any) => (_ctx.navigateToAssessmentDetail(step)),
                    disabled: index != 0 && _ctx.roadMap.Steps[index - 1].TalentState != 'Completed'
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, { class: "title" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(step.AssessmentDetail.Title), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_v_card_text, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_row, null, {
                            default: _withCtx(() => [
                              (step.IsFavorite)
                                ? (_openBlock(), _createBlock(_component_v_col, { key: 0 }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_icon, { class: "icon-color" }, {
                                        default: _withCtx(() => _cache[8] || (_cache[8] = [
                                          _createTextVNode("mdi-check")
                                        ])),
                                        _: 1
                                      }),
                                      _cache[9] || (_cache[9] = _createElementVNode("span", { class: "pl-2 icon-color" }, "Completed", -1))
                                    ]),
                                    _: 1
                                  }))
                                : (_openBlock(), _createBlock(_component_v_col, {
                                    key: 1,
                                    cols: "12",
                                    class: "rate"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_icon, null, {
                                        default: _withCtx(() => _cache[10] || (_cache[10] = [
                                          _createTextVNode("mdi-star")
                                        ])),
                                        _: 1
                                      }),
                                      _cache[11] || (_cache[11] = _createElementVNode("span", { class: "pl-2" }, "3.5", -1))
                                    ]),
                                    _: 1
                                  })),
                              _createVNode(_component_v_col, { cols: "12" }, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", null, _toDisplayString(step.AssessmentDetail.Questions.length) + " Questions", 1),
                                  _cache[12] || (_cache[12] = _createTextVNode(" - ")),
                                  _createElementVNode("span", null, _toDisplayString(_ctx.formatDuration(step.AssessmentDetail.Questions.reduce((total, a) => total + a.Duration, 0))), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["class", "onClick", "disabled"]))
                : _createCommentVNode("", true)
            ]))
          }), 128)),
          _createElementVNode("li", null, [
            _createVNode(_component_v_card, { class: "project-card start-end-card" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "title" }, {
                  default: _withCtx(() => _cache[13] || (_cache[13] = [
                    _createTextVNode("End")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_v_progress_circular, {
            color: "primary",
            indeterminate: "",
            size: 80
          }, {
            default: _withCtx(() => _cache[14] || (_cache[14] = [
              _createTextVNode(" Loading")
            ])),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}