<template>
  <div class="register" :class="{ 'secondary-is-open': openSecondaryPanel }">
    <div class="common-container">
      <div id="register-description" class="register__card card--secondary" v-if="openSecondaryPanel">
        <img class="logo" src="@/assets/images/code-clan-logo.svg" />
        <div class="tempale-container">
          <component :is="template"></component>
        </div>
      </div>
      <v-card class="register__card card--background register-box">
        <div class="register__header">
          <div class="register__title">
            <h4 class="headline" v-if="title">{{ title }}</h4>
            <div v-if="description">{{ description }}</div>
          </div>
          <img v-show="!openSecondaryPanel" class="logo" src="@/assets/images/code-clan-logo.svg" />
        </div>
        <div class="secondary-container"></div>
        <div class="forms pt-0 pb-0">
          <router-view />
        </div>
      </v-card>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {},
  data() {
    return {
      openSecondaryPanel: false,
      title: "",
      description: "",
      template: null,
    };
  },
  created() {
    this.initPage();
  },
  methods: {
    initPage() {
      this.title = this.$route.meta.title as string;
      this.description = this.$route.meta.description as string;
      (this.template as any) = this.$route.meta.template;
      (this.openSecondaryPanel as any)  = this.template && this.template !== null;
    },
  },
  watch: {
    $route() {
      this.initPage();
    },
  },
});
</script>

<style scoped lang="scss">
@import "node_modules/shared-components/assets/colors.scss";
@media (max-width: 820px) {
  #register-description {
    display: none;
  }
}

.register {
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  .common-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $racecar;
    .card--secondary {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 40px 10px 20px;
      .tempale-container {
        padding: 20px;
      }
      .logo {
        height: auto;
        width: 150px;
      }
    }
  }
  &.secondary-is-open {
    .card--background {
      background-color: $dark_gray !important;
    }
  }
  &__card {
    width: 24vw;
    min-width: 330px;
    min-height: 400px;
    border-radius: 0;
  }
  .card--background {
    background-color: $racecar !important;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    .logo {
      height: auto;
      width: 80px;
      margin-left: 20px;
    }
  }
}

.forms {
  padding: 20px;
  display: grid;
  align-items: center;
  background-color: $dark_gray;
}

.register-box {
  width: 30vw;
}
</style>
