import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "assessment-container form-inputs" }
const _hoisted_2 = {
  key: 0,
  class: "f-body-1"
}
const _hoisted_3 = {
  key: 1,
  class: "f-body-2"
}
const _hoisted_4 = {
  key: 2,
  class: "flex-section f-body-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    disabled: _ctx.isLoading,
    loading: _ctx.isLoading
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.assessmentDetail)
          ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, {
                              cols: "12",
                              class: "details-section"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", null, [
                                  _createElementVNode("h2", null, _toDisplayString(_ctx.assessmentDetail.Title), 1)
                                ]),
                                (_ctx.assessmentDetail.Description)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                      _createVNode(_component_v_tooltip, { bottom: "" }, {
                                        activator: _withCtx(({ props }) => [
                                          _createElementVNode("span", _mergeProps({ class: "description" }, props), _toDisplayString(_ctx.assessmentDetail.Description), 17)
                                        ]),
                                        default: _withCtx(() => [
                                          _cache[0] || (_cache[0] = _createTextVNode(" Description "))
                                        ]),
                                        _: 1
                                      })
                                    ]))
                                  : _createCommentVNode("", true),
                                (_ctx.assessmentDetail.Objective)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                      _createVNode(_component_v_tooltip, { bottom: "" }, {
                                        activator: _withCtx(({ props }) => [
                                          _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), [
                                            _createVNode(_component_v_icon, null, {
                                              default: _withCtx(() => _cache[1] || (_cache[1] = [
                                                _createTextVNode("mdi-human-male-board")
                                              ])),
                                              _: 1
                                            }),
                                            _createTextVNode(" " + _toDisplayString(_ctx.assessmentDetail.Objective), 1)
                                          ], 16)
                                        ]),
                                        default: _withCtx(() => [
                                          _cache[2] || (_cache[2] = _createTextVNode(" Objective "))
                                        ]),
                                        _: 1
                                      }),
                                      _cache[5] || (_cache[5] = _createTextVNode(" - ")),
                                      (_ctx.assessmentDetail.PassScore)
                                        ? (_openBlock(), _createBlock(_component_v_tooltip, {
                                            key: 0,
                                            bottom: ""
                                          }, {
                                            activator: _withCtx(({ props }) => [
                                              _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), [
                                                _createVNode(_component_v_icon, null, {
                                                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                                                    _createTextVNode("mdi-chart-box")
                                                  ])),
                                                  _: 1
                                                }),
                                                _createTextVNode(" " + _toDisplayString(_ctx.assessmentDetail.PassScore), 1)
                                              ], 16)
                                            ]),
                                            default: _withCtx(() => [
                                              _cache[4] || (_cache[4] = _createTextVNode(" Pass Score "))
                                            ]),
                                            _: 1
                                          }))
                                        : _createCommentVNode("", true)
                                    ]))
                                  : _createCommentVNode("", true),
                                (_ctx.assessmentDetail.Tags.length > 0)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                      _createVNode(_component_v_tooltip, { bottom: "" }, {
                                        activator: _withCtx(({ props }) => [
                                          _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), [
                                            _createVNode(_component_v_icon, null, {
                                              default: _withCtx(() => _cache[6] || (_cache[6] = [
                                                _createTextVNode("mdi-tag")
                                              ])),
                                              _: 1
                                            })
                                          ], 16)
                                        ]),
                                        default: _withCtx(() => [
                                          _cache[7] || (_cache[7] = _createTextVNode(" Tags "))
                                        ]),
                                        _: 1
                                      }),
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.assessmentDetail.Tags, (tag, index) => {
                                        return (_openBlock(), _createBlock(_component_v_chip, { key: index }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(tag.Title), 1)
                                          ]),
                                          _: 2
                                        }, 1024))
                                      }), 128))
                                    ]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      variant: "tonal",
                      class: "primary_btn"
                    }, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createTextVNode(" Request to Access ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["disabled", "loading"]))
}