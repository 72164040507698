import CourseModel from "shared-components/src/models/CourseModel";
import ApiService from "./ApiService";
import TmRoadMapModel from "shared-components/src/models/TmRoadMapModel";
import Utils from "shared-components/src/utils/Utils";
import moment from "moment";
import AssessmentModel from "shared-components/src/models/AssessmentModel";

export default class RoadMapService {
  public static async GetTeammemberRoadMaps(searchTitle: string | null): Promise<TmRoadMapModel[]> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/tmRoadMap?title=${searchTitle}`, "")
        .then((result) => {
          if (!result.data) {
            resolve([] as TmRoadMapModel[]);
          } else {
            var response = result.data as TmRoadMapModel[];
            resolve(response);
          }
        })
        .catch((error) => reject(error));
    });
  }

  public static async GetTeammemberRoadMapSteps(roadMapId: string): Promise<TmRoadMapModel> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/tmRoadMap/${roadMapId}`, "")
        .then((result) => {
          if (!result.data) {
            resolve({} as TmRoadMapModel);
          } else {
            var response = result.data as TmRoadMapModel;
            resolve(response);
          }
        })
        .catch((error) => reject(error));
    });
  }

  public static async GetTeammemberRoadMapCourseDetails(roadMapId: string, courseId: string): Promise<CourseModel> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/tmRoadMap/${roadMapId}/courses/${courseId}`, "")
        .then((result) => {
          if (!result.data) {
            resolve({} as CourseModel);
          } else {
            var response = result.data as CourseModel;
            resolve(response);
          }
        })
        .catch((error) => reject(error));
    });
  }

  public static async GetTeammemberRoadMapAssessmentDetails(roadMapId: string, assessmentId: string): Promise<AssessmentModel> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/tmRoadMap/${roadMapId}/assessments/${assessmentId}`, "")
        .then((result) => {
          if (!result.data) {
            resolve({} as AssessmentModel);
          } else {
            var response = result.data as AssessmentModel;
            resolve(response);
          }
        })
        .catch((error) => reject(error));
    });
  }

  public static async StartTeammemberRoadMapCourse(roadMapId: string, courseId: string): Promise<CourseModel> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/tmRoadMap/${roadMapId}/courses/${courseId}`, "")
        .then((result) => {
          if (!result.data) {
            resolve({} as CourseModel);
          } else {
            var response = result.data as CourseModel;
            resolve(response);
          }
        })
        .catch((error) => reject(error));
    });
  }

  public static async StartTeammemberRoadMapAssessment(assessmentId: string, courseId?: string, roadMapId?: string): Promise<Date> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/tmRoadMap/${roadMapId}/assessments/start`, { AssessmentId: assessmentId, CourseId: courseId })
        .then((result) => {
          if (!result.data) {
            reject("");
          } else {
            var response = moment.utc(result.data).toDate();
            resolve(response);
          }
        })
        .catch((error) => reject(error));
    });
  }

  public static async SubmitRoadMapAssessment(assessmentId: string, roadMapId: string, courseId?: string): Promise<Date> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/tmRoadMap/${roadMapId}/assessments/submit`, { AssessmentId: assessmentId, CourseId: courseId })
        .then((result) => {
          if (!result.data) {
            reject("");
          } else {
            var response = moment.utc(result.data).toDate();
            resolve(response);
          }
        })
        .catch((error) => reject(error));
    });
  }

  public static async RestartRoadMapAssessment(assessmentId: string, roadMapId: string, courseId?: string): Promise<Date> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/tmRoadMap/${roadMapId}/assessments/restart`, { AssessmentId: assessmentId, CourseId: courseId })
        .then((result) => {
          if (!result.data) {
            reject("");
          } else {
            var response = moment.utc(result.data).toDate();
            resolve(response);
          }
        })
        .catch((error) => reject(error));
    });
  }

  public static async SubmitAssessmentQuestionAnswer(
    answer: string,
    assessmentId: string,
    courseId?: string,
    questionId?: string,
    roadMapId?: string
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/tmRoadMap/${roadMapId}/assessments/questions/submit`, {
        Answer: answer,
        AssessmentId: assessmentId,
        CourseId: courseId,
        QuestionId: questionId,
      })
        .then((result) => {
          if (!result.data) {
            reject(false);
          } else {
            resolve(result.data);
          }
        })
        .catch((error) => reject(error));
    });
  }

  public static async GetById(roadMapId: string): Promise<TmRoadMapModel> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/roadMap/${roadMapId}`)
        .then((result) => {
          if (!result.data) {
            resolve({} as TmRoadMapModel);
          } else {
            var response = result.data as TmRoadMapModel;
            resolve(response);
          }
        })
        .catch((error) => reject(error));
    });
  }
}
