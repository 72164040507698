import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommitmentCardItem = _resolveComponent("CommitmentCardItem")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, null, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
        return (_openBlock(), _createBlock(_component_v_col, {
          lg: "4",
          md: "6",
          sm: "12",
          xs: "12",
          key: index
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CommitmentCardItem, {
              item: item,
              epics: _ctx.epicList,
              activities: _ctx.activityList,
              technologies: _ctx.technologyList,
              onFetchTechnologies: _ctx.fetchTechnologies
            }, null, 8, ["item", "epics", "activities", "technologies", "onFetchTechnologies"])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}