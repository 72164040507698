import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withKeys as _withKeys } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "success--text"
}
const _hoisted_2 = {
  key: 1,
  class: "red--text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_PayRunDetail = _resolveComponent("PayRunDetail")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, null, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode(" Pay Run List ")
          ])),
          _: 1
        }),
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_data_table, {
              items: _ctx.items,
              headers: _ctx.headers,
              loading: _ctx.loading
            }, {
              "item.Duration": _withCtx(({ item }) => [
                _createElementVNode("strong", null, _toDisplayString(_ctx.getDuration(item)), 1)
              ]),
              "item.TotalHours": _withCtx(({ item }) => [
                _createElementVNode("span", null, _toDisplayString(_ctx.getTotalHours(item)), 1)
              ]),
              "item.AUDTotalPay": _withCtx(({ item }) => [
                _createElementVNode("span", null, "$" + _toDisplayString(_ctx.formatNumber(item.AUDTotalPay)), 1)
              ]),
              "item.LCTotalPay": _withCtx(({ item }) => [
                _createElementVNode("span", null, _toDisplayString(_ctx.formatNumber(item.LCTotalPay)) + " " + _toDisplayString(item.ExchangeCurrency ? item.ExchangeCurrency : "TMN"), 1)
              ]),
              "item.TMApproved": _withCtx(({ item }) => [
                (item.TMApproved)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_1, "Approved"))
                  : (_openBlock(), _createElementBlock("span", _hoisted_2, "Waiting"))
              ]),
              "item.Actions": _withCtx(({ item }) => [
                _createElementVNode("div", null, [
                  _createVNode(_component_v_btn, {
                    class: "mt-1",
                    icon: "",
                    fab: "",
                    "x-small": "",
                    title: "show more inforamtion!",
                    onClick: ($event: any) => (_ctx.showPayrun(item))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                          _createTextVNode("mdi-magnify")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ])
              ]),
              _: 1
            }, 8, ["items", "headers", "loading"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.showDetailPopUp)
      ? (_openBlock(), _createBlock(_component_v_dialog, {
          key: `PDF-Modal-${_ctx.pdfVersion}`,
          class: "scrollable-dialog",
          "max-width": "800",
          modelValue: _ctx.showDetailPopUp,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showDetailPopUp) = $event)),
          persistent: "",
          onKeydown: _withKeys(_ctx.closePayrun, ["esc"])
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_component_PayRunDetail, {
              key: `PDF-${_ctx.pdfVersion}`,
              model: _ctx.selectedPayRun,
              payrunAuditTrail: _ctx.payrunAuditTrail,
              onClose: _ctx.closePayrun,
              onApproveByTM: _ctx.approve
            }, null, 8, ["model", "payrunAuditTrail", "onClose", "onApproveByTM"]))
          ]),
          _: 1
        }, 8, ["modelValue", "onKeydown"]))
      : _createCommentVNode("", true)
  ]))
}