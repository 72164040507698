<template>
  <div>
    <v-card>
      <v-card-title> Authors </v-card-title>
      <v-card-text class="d-flex">
        <div style="width: 20%">
          <TextField class="me-2 pl-2" placeholder="Search Author" v-model="filterText" :readonly="false" />
        </div>
        <v-switch class="me-2" label="Unassigned" v-model="isUnassigned" color="success"></v-switch> &nbsp;&nbsp;
        <v-switch class="me-2" label="Show Ignored" v-model="showIgnored" color="success"></v-switch> &nbsp;&nbsp;
      </v-card-text>
      <v-card-text class="author-body">
        <v-btn class="me-2" color="secondary" @click="showIgnoreSelectedConfirmDialog()" style="margin-bottom: 5px">
          Ignore Selected Authors ({{ selectedAuthors.length }})</v-btn
        >
        <v-data-table :items="tempAuthors" :headers="headers" :loading="loading" v-model="selected">
          <template v-slot:item.Select="props">
            <v-checkbox v-model="props.item.Selected" v-if="!props.item.TeamMemberId"></v-checkbox>
          </template>
          <template v-slot:item.Author="props">
            <strong>{{ props.item.Author }}</strong>
          </template>
          <template v-slot:item.TeamMemberId="{ item }">
            <SelectField
              class="mt-6"
              v-if="!item.TeamMemberId"
              style="width: 200px"
              placeholder="Teammembers"
              :items="teammembers"
              item-title="FullName"
              item-value="Id"
              v-model="item.TeamMemberId"
              outlined
              @change="save(item)"
            />
            <span v-else>{{ item.TeamMemberName }}</span>
          </template>
          <template v-slot:item.Actions="{ item }">
            <span class="operation text-center">
              <v-menu location="bottom">
                <template v-slot:activator="{ props}">
                  <v-icon v-bind="props">mdi-dots-vertical</v-icon>
                </template>
                <v-list>
                  <v-list-item @click="showClearConfirmDialog(item)" v-if="item.TeamMemberId" prepend-icon="mdi-account-remove" title="Remove Assignment"/>
                  <v-list-item @click="showIgnoreConfirmDialog(item)" prepend-icon="mdi-account-off" title="Ignore Author"/>
                </v-list>
              </v-menu>
            </span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog persistent v-model="showConfirmDialog" @keydown.esc="showConfirmDialog = false" max-width="400px">
      <confirm-dialog :loading="dialogLoading" @confirm="confirmMethod" @cancel="showConfirmDialog = false" :message="confirmModel"> </confirm-dialog>
    </v-dialog>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import AuthorService from "../../services/AuthorService";
import TeamMemberService from "@/services/TeamMemberService";
import Author from "shared-components/src/models/Author";
import { Teammember } from "shared-components/src/models/Teammember";
import ConfirmDialog from "shared-components/src/components/ConfirmDialog.vue";
import store from "@/store";

export default defineComponent({
  components: {
    ConfirmDialog,
  },
  data() {
    return {
      showConfirmDialog: false,
      dialogLoading: false,
      confirmMethod: null as Function | null,
      selectedItem: null as Author | null,
      selected: [],
      loading: true,
      authors: [] as Author[],
      isUnassigned: false,
      showIgnored: false,
      filterText: "",
      teammembers: [] as Teammember[],
      teamMemberInfo: {} as any,
      tempAuthors: [] as Author[],
      showNewItem: false,
      confirmModel: {
        text: "Are You Sure?",
      },
      headers: [
        { title: "Select", align: "start", value: "Select" },
        { title: "Author", align: "start", value: "Author" },
        { title: "Teammember", value: "TeamMemberId" },
        { title: "Actions", sortable: false, value: "Actions", align: "center" },
      ],
    };
  },
  async mounted() {
    this.teamMemberInfo = await TeamMemberService.getTeammemberSummary();
    this.loadTeamMembers();
    this.loadData();
  },
  computed: {
    ...mapGetters({ userInfo: "userInfo" }),
    selectedAuthors(): Author[] {
      return this.tempAuthors.filter((c) => c.Selected);
    },
  },
  methods: {
    async loadTeamMembers() {
      if (this.teamMemberInfo) {
        this.teammembers = await TeamMemberService.getActiveTeammembersByLinemanager(this.teamMemberInfo.LinemanagerId);
      }
    },
    async loadData() {
      this.filterText = "";
      this.loading = true;
      if (this.teamMemberInfo && this.teamMemberInfo.LinemanagerId) {
        this.authors = await AuthorService.getList(this.teamMemberInfo.LinemanagerId, this.showIgnored);
        this.tempAuthors = this.authors;
      }
      this.loading = false;
    },
    resetConfirmDialog() {
      this.selectedItem = null;
      this.showConfirmDialog = false;
    },
    showIgnoreConfirmDialog(item: any) {
      this.resetConfirmDialog();
      this.confirmMethod = this.ignore;
      this.selectedItem = item;
      this.showConfirmDialog = true;
    },
    async ignoreAuthors() {
      this.dialogLoading = true;
      if (this.selectedAuthors.length > 0) {
        try {
          const promises = [] as Promise<string>[];
          this.selectedAuthors.forEach((item) => {
            promises.push(AuthorService.ignore(item.Author, this.teamMemberInfo.LinemanagerId));
          });
          await Promise.all(promises);
          store.dispatch("showSuccessMessage", "Authors has been successfully ignored!")
          this.loadData();
          this.resetConfirmDialog();
        } catch (error) {
          store.dispatch("showErrorMessage", "An error has occurred!")
        } finally {
          this.dialogLoading = false;
        }
      } else {
        store.dispatch("showErrorMessage", "Author has not been selected yet")
        this.dialogLoading = false;
      }
    },
    async ignore() {
      try {
        this.dialogLoading = true;
        if (this.selectedItem != null) {
          await AuthorService.ignore(this.selectedItem.Author, this.teamMemberInfo.LinemanagerId);
          store.dispatch("showSuccessMessage", "Author has been successfully ignored!")
          this.loadData();
          this.resetConfirmDialog();
        } else {
          store.dispatch("showErrorMessage", "Author has not been selected yet")
        }
      } catch (error) {
        store.dispatch("showErrorMessage", "An error has occurred!")
      } finally {
        this.dialogLoading = false;
      }
    },
    showClearConfirmDialog(item: any) {
      this.resetConfirmDialog();
      this.confirmMethod = this.clear;
      this.selectedItem = item;
      this.showConfirmDialog = true;
    },
    showIgnoreSelectedConfirmDialog() {
      this.resetConfirmDialog();
      this.confirmMethod = this.ignoreAuthors;
      this.showConfirmDialog = true;
    },
    async clear() {
      try {
        this.dialogLoading = true;
        if (this.selectedItem != null) {
          const dataToRemove = { ...this.selectedItem };
          dataToRemove.TeamMemberId = "";
          await AuthorService.update(dataToRemove, this.teamMemberInfo.LinemanagerId);
          store.dispatch("showSuccessMessage", "Assigned Team Members have been successfully removed!")
          this.loadData();
          this.resetConfirmDialog();
        } else {
          store.dispatch("showErrorMessage", "Author has not been selected yet")
        }
      } catch (error) {
        store.dispatch("showErrorMessage", "An error has occurred!")
      } finally {
        this.dialogLoading = false;
      }
    },
    async save(item: any) {
      try {
        this.loading = true;
        await AuthorService.update(item, this.teamMemberInfo.LinemanagerId);
        this.showNewItem = false;
        store.dispatch("showSuccessMessage", "Author has been successfully saved!")
        this.loadData();
      } catch (error) {
        store.dispatch("showErrorMessage", "An error has occurred!")
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    isUnassigned(val) {
      if (val) {
        this.tempAuthors = this.tempAuthors.filter((c) => !c.TeamMemberId);
      } else {
        this.tempAuthors = this.authors;
      }
    },
    showIgnored() {
      this.loadData();
    },
    filterText(val) {
      if (val && val.length > 0) {
        this.tempAuthors = this.tempAuthors.filter((c) => c.Author && c.Author.length > 0 && c.Author.toLowerCase().includes(val.toLowerCase()));
      } else {
        this.tempAuthors = this.authors;
      }
    },
  },
});
</script>
