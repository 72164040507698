import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withKeys as _withKeys, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "filter-container" }
const _hoisted_2 = { class: "part" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "sticky-navbar",
      style: _normalizeStyle({ top: _ctx.top }),
      onClick: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"]))
    }, [
      _createVNode(_component_v_row, { class: "default-section" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, {
            onClick: _ctx.toggleFilter,
            class: "filter-button"
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode(" Filter ")
            ])),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_v_text_field, {
                      placeholder: "Category",
                      solo: "",
                      "hide-details": "",
                      "onClick:appendInner": _ctx.searchHandler,
                      onKeyup: _withKeys(_ctx.searchHandler, ["enter"]),
                      modelValue: _ctx.searchCategory,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchCategory) = $event))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, {
                          color: "black",
                          onClick: _ctx.searchHandler,
                          class: "clickable-icon"
                        }, {
                          default: _withCtx(() => _cache[6] || (_cache[6] = [
                            _createTextVNode(" mdi-magnify ")
                          ])),
                          _: 1
                        }, 8, ["onClick"])
                      ]),
                      _: 1
                    }, 8, ["onClick:appendInner", "onKeyup", "modelValue"])
                  ]),
                  (_ctx.criteria.selectedSkill.length > 0 || _ctx.criteria.selectedTime || _ctx.criteria.selectedLevel)
                    ? (_openBlock(), _createBlock(_component_v_row, {
                        key: 0,
                        class: "mt-1"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { cols: "12" }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.criteria.selectedSkill, (skill, index) => {
                                return (_openBlock(), _createBlock(_component_v_chip, {
                                  "onClick:close": ($event: any) => (_ctx.closeSkillChip(index)),
                                  close: "",
                                  key: index,
                                  class: "mr-2",
                                  color: _ctx.backgrounds.skill
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" Skill: " + _toDisplayString(_ctx.getSkillName(skill)), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["onClick:close", "color"]))
                              }), 128)),
                              (_ctx.criteria.selectedTime)
                                ? (_openBlock(), _createBlock(_component_v_chip, {
                                    key: 0,
                                    "onClick:close": _ctx.closeTimeChip,
                                    close: "",
                                    class: "mr-2",
                                    color: _ctx.backgrounds.duration
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(" Duration: Between " + _toDisplayString((_ctx.criteria.selectedTime as any).split(",")[0]) + " - " + _toDisplayString((_ctx.criteria.selectedTime as any).split(",")[1]) + "h ", 1)
                                    ]),
                                    _: 1
                                  }, 8, ["onClick:close", "color"]))
                                : _createCommentVNode("", true),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.criteria.selectedLevel, (skill, index) => {
                                return (_openBlock(), _createBlock(_component_v_chip, {
                                  "onClick:close": ($event: any) => (_ctx.closeLevelChip(index)),
                                  key: index,
                                  close: "",
                                  class: "mr-2",
                                  color: _ctx.backgrounds.level
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" Level: " + _toDisplayString(skill), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["onClick:close", "color"]))
                              }), 128))
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.showFilter)
        ? (_openBlock(), _createBlock(_component_v_row, {
            key: 0,
            class: "filter"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_form, { class: "w-100" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { cols: "12" }, {
                        default: _withCtx(() => [
                          _cache[7] || (_cache[7] = _createElementVNode("label", null, " Select your skill", -1)),
                          _createVNode(_component_v_autocomplete, {
                            dense: "",
                            outlined: "",
                            items: _ctx.mappedSkills,
                            "item-title": "text",
                            "item-value": "id",
                            modelValue: _ctx.criteria.selectedSkill,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.criteria.selectedSkill) = $event)),
                            chips: "",
                            multiple: "",
                            "hide-details": "",
                            "closable-chips": ""
                          }, null, 8, ["items", "modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { cols: "12" }, {
                        default: _withCtx(() => [
                          _cache[8] || (_cache[8] = _createElementVNode("label", null, " Select the time", -1)),
                          _createVNode(_component_v_select, {
                            "item-title": "text",
                            "item-value": "value",
                            dense: "",
                            outlined: "",
                            items: _ctx.filterData.duration,
                            modelValue: _ctx.criteria.selectedTime,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.criteria.selectedTime) = $event)),
                            "hide-details": "",
                            "return-object": false
                          }, null, 8, ["items", "modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { cols: "12" }, {
                        default: _withCtx(() => [
                          _cache[9] || (_cache[9] = _createElementVNode("label", null, " Select your Level ", -1)),
                          _createVNode(_component_v_select, {
                            "item-title": "text",
                            "item-value": "value",
                            dense: "",
                            outlined: "",
                            items: _ctx.filterData.level,
                            modelValue: _ctx.criteria.selectedLevel,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.criteria.selectedLevel) = $event)),
                            chips: "",
                            multiple: "",
                            "hide-details": "",
                            "closable-chips": ""
                          }, null, 8, ["items", "modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ], 4)
  ]))
}