<template>
  <v-card>
    <v-row class="card-style">
      <v-col cols="12" sm="5" lg="4">
        <v-img :src="course.ImageDownloadUrl" :aspect-ratio="1" cover class="course_image" @click="navigateToDetail"></v-img>
      </v-col>
      <v-col cols="12" sm="7" lg="8">
        <div class="course-details">
          <span class="title" @click="navigateToDetail(course.Id)"> {{ course.Title }}</span>
          <div>
            <v-tooltip location="bottom">
              <template v-slot:activator="{ props }">
                <span class="description" v-bind="props">
                  {{ course.Summary }}
                </span>
              </template>
              Summary
            </v-tooltip>
          </div>

          <div class="part">
            <div class="pr-1">
              <v-tooltip location="right">
                <template v-slot:activator="{ props }">
                  <span v-bind="props">
                    <v-icon>mdi-human-male</v-icon>
                    {{ course.Author }}
                  </span>
                </template>
                Author
              </v-tooltip>
            </div>
            -
            <div class="tag-section pl-1" v-if="course.Skills && course.Skills.length > 0">
              <v-tooltip location="bottom">
                <template v-slot:activator="{ props }">
                  <span v-bind="props">
                    <v-icon>mdi-shape</v-icon>
                  </span>
                </template>
                Skill
              </v-tooltip>
              <span v-for="(skill, index) in course.Skills" :key="index">
                {{ getSkillName(skill) }}{{ index != course.Skills.length - 1 ? "," : "" }}
              </span>
            </div>
          </div>
          <div>
            <v-tooltip location="bottom">
              <template v-slot:activator="{ props }">
                <span v-bind="props">
                  <v-icon>mdi-timer-sand</v-icon>
                  {{ formatDuration(course.Duration) }}
                </span>
              </template>
              Duration
            </v-tooltip>
            -
            <v-tooltip location="bottom">
              <template v-slot:activator="{ props }">
                <span v-bind="props">
                  <v-icon>mdi-bulletin-board</v-icon>
                  {{ course.Steps.length }} Step
                </span>
              </template>
              {{ course.Steps.filter((item) => item.Type == "Lesson").length }}
              Lesson and
              {{ course.Steps.filter((item) => item.Type == "Assessment").length }}
              Assessment
            </v-tooltip>
          </div>

          <div>
            <v-tooltip location="right">
              <template v-slot:activator="{ props }">
                <span v-bind="props">
                  <v-icon v-for="(i, index) in getLevelStarsCount(course.Level)" :key="index">mdi-star</v-icon>
                  {{ course.Level }}
                </span>
              </template>
              Level
            </v-tooltip>
          </div>

          <div class="tag-section" v-if="course.Tags.length > 0">
            <v-tooltip location="bottom">
              <template v-slot:activator="{ props }">
                <span v-bind="props">
                  <v-icon>mdi-tag</v-icon>
                </span>
              </template>
              Tags
            </v-tooltip>
            <v-chip v-for="tag in course.Tags" :key="tag.Id">{{ tag.Title }}</v-chip>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CourseModel from "../../../../../ProjectPortal-SharedComponents/src/models/CourseModel";
import CoreSkill from "shared-components/src/models/CoreSkill";

export default defineComponent({
  props: {
    course: {
      type: Object as () => CourseModel,
      required: true,
    },
    skills: {
      type: Array as () => CoreSkill[],
      default: [],
    },
  },
  methods: {
    formatDuration(duration: number) {
      const hours = Math.floor(duration / 60);
      const minutes = duration % 60;
      return hours > 0 ? `${hours} h, ${minutes} m` : `${minutes} m`;
    },
    addToRoadMap(id: any, courseName: any) {
      this.$emit("add-to-roadmap", { id, courseName });
    },
    addToFavorite() {
      this.course.IsFavorite = !this.course.IsFavorite;
    },
    editCourse(courseId: string | undefined) {
      if (courseId) {
        this.$router.push({ name: "edit-course", params: { courseId } });
      }
    },
    getLevelStarsCount(level: string) {
      switch (level) {
        case "Junior":
          return 1;
        case "MidLevel":
          return 2;
        case "Senior":
          return 3;
      }
    },
    getSkillName(id: string) {
      return this.skills.filter((item: any) => item.id == id)[0].Text;
    },
    navigateToDetail() {
      this.$router.push({
        name: "CourseDetail",
        params: { courseId: this.course.Id ?? "" },
      });
    },
  },
});
</script>

<style lang="scss" scoped>
@import "node_modules/shared-components/assets/colors.scss";
@import "node_modules/shared-components/assets/_sizes.scss";

.requirement-clickable {
  cursor: pointer;
  color: $info;
}

.card-style {
  margin-bottom: 15px;
}

.part {
  display: flex;
  flex-direction: row;
  gap: 1px;
  padding-bottom: 0px;
}

.course-card {
  margin-top: 20px;
  padding: 20px;
  border-radius: 8px !important;
  border: 1px solid $light_gray;
}
.course_image {
  padding-left: 15px;
  max-height: 265px;
  cursor: pointer;
  border-radius: 5px;
}
.course-details {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap;
  position: relative;
}
.heart_icon {
  color: $racecar;
}
.title {
  font-size: $size-m;
  font-weight: 500;
  cursor: pointer;
}

.description {
  text-justify: auto;
  font-size: $size-s;
}

.favorite-btn {
  position: absolute;
  right: 5px;
}

.tag-section {
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
}
</style>
