import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "reset-pass" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "actions" }
const _hoisted_4 = { class: "login-link" }
const _hoisted_5 = {
  key: 1,
  class: "text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextField = _resolveComponent("TextField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_PasswordField = _resolveComponent("PasswordField")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    loading: _ctx.pageLoading,
    disabled: _ctx.pageLoading,
    class: "custom-card"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            ((_ctx.token && _ctx.email) || !_ctx.token)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_v_form, {
                    ref: "resetForm",
                    autocomplete: "off"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { cols: "12" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_TextField, {
                                modelValue: _ctx.email,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                                "prepend-icon": "$vuetify.icons.email",
                                label: "Email",
                                placeholder: "Email",
                                type: "text",
                                class: "login-field",
                                outlined: "",
                                rules: [_ctx.validations.RequiredCheck, _ctx.validations.EmailCheck],
                                readonly: _ctx.token && _ctx.token.length > 0 && _ctx.email && _ctx.email.length > 0
                              }, null, 8, ["modelValue", "rules", "readonly"])
                            ]),
                            _: 1
                          }),
                          (_ctx.token && _ctx.token.length > 0 && _ctx.email && _ctx.email.length > 0)
                            ? (_openBlock(), _createBlock(_component_v_col, {
                                key: 0,
                                cols: "12"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_PasswordField, {
                                    "prepend-icon": "$vuetify.icons.key",
                                    rules: [_ctx.validations.RequiredCheck],
                                    modelValue: _ctx.newPassword,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newPassword) = $event)),
                                    label: "Enter New Password",
                                    placeholder: "Enter New Password",
                                    outlined: ""
                                  }, null, 8, ["rules", "modelValue"])
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 512),
                  _createElementVNode("div", _hoisted_3, [
                    (!_ctx.token)
                      ? (_openBlock(), _createBlock(_component_v_btn, {
                          key: 0,
                          disabled: _ctx.loading,
                          loading: _ctx.loading,
                          color: "accent",
                          onClick: _ctx.resetPassword
                        }, {
                          default: _withCtx(() => _cache[2] || (_cache[2] = [
                            _createTextVNode("Reset")
                          ])),
                          _: 1
                        }, 8, ["disabled", "loading", "onClick"]))
                      : _createCommentVNode("", true),
                    (_ctx.token && _ctx.email)
                      ? (_openBlock(), _createBlock(_component_v_btn, {
                          key: 1,
                          disabled: _ctx.pageLoading,
                          loading: _ctx.pageLoading,
                          color: "accent",
                          onClick: _ctx.saveNewPassword
                        }, {
                          default: _withCtx(() => _cache[3] || (_cache[3] = [
                            _createTextVNode("Save")
                          ])),
                          _: 1
                        }, 8, ["disabled", "loading", "onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_router_link, { to: "/register/login" }, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createTextVNode("Back")
                      ])),
                      _: 1
                    })
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.token && !_ctx.email && !_ctx.pageLoading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, "There is an error on Validating Token"))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["loading", "disabled"]))
}