<template>
  <div class="container">
    <h3>Welcome to our Team member portal</h3>
    <p>
      Please create an account, then verify your email, and make sure to complete your profile and upload your CV into your profile. We will match
      your skills with potential opportunities and contact you for the next steps. This might be a beginning of a big thing for you
    </p>
  </div>
</template>

<style scoped lang="scss">
.container {
    h3 {
        text-align: center;
        margin-bottom: 10px;
    }
}
</style>