import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, null, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dates, (date) => {
        return (_openBlock(), _createBlock(_component_v_col, {
          md: "1",
          class: "pa-1",
          key: `date-${date.name}`
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              class: _normalizeClass(["dateItem", date.isClicked ? 'selectedDate' : '']),
              onClick: ($event: any) => (_ctx.selectMonth(date))
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(date.name), 1)
              ]),
              _: 2
            }, 1032, ["class", "onClick"])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}