import { SystemErrors } from "shared-components/src/definitions/systemErrors";
import { CryptoCurrency } from "shared-components/src/models/CryptoCurrency";
import { BankAccount } from "shared-components/src/models/BankAccount";
import { WiseAccount } from "shared-components/src/models/WiseAccount";
import { Paypal } from "shared-components/src/models/Paypal";
import ApiService from "shared-components/src/services/ApiService";

export default class PaymentMethodService {
  public static getTeammemberCryptoCurrency(): Promise<CryptoCurrency[] | null> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/paymentMethod/cryptoCurrency`, "")
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve(null);
          } else {
            resolve(res.data.value);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public static saveTeammemberCryptoCurrency(model: CryptoCurrency): Promise<CryptoCurrency[] | null> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/paymentMethod/cryptoCurrency`, model)
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve(null);
          } else {
            resolve(res.data.value);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public static getTeammemberBankAccount(): Promise<BankAccount[] | null> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/paymentMethod/bankAccount`, "")
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve(null);
          } else {
            resolve(res.data.value);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public static saveTeammemberBankAccount(model: BankAccount): Promise<BankAccount[] | null> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/paymentMethod/bankAccount`, model)
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve(null);
          } else {
            resolve(res.data.value);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static getTeammemberWiseAccount(): Promise<WiseAccount[] | null> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/paymentMethod/wiseAccount`, "")
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve(null);
          } else {
            resolve(res.data.value);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public static saveTeammemberWiseAccount(model: WiseAccount): Promise<WiseAccount[] | null> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/paymentMethod/wiseAccount`, model)
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve(null);
          } else {
            resolve(res.data.value);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static getTeammemberPaypal(): Promise<Paypal[] | null> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/paymentMethod/paypal`, "")
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve(null);
          } else {
            resolve(res.data.value);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public static saveTeammemberPaypal(model: Paypal): Promise<Paypal[] | null> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/paymentMethod/paypal`, model)
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve(null);
          } else {
            resolve(res.data.value);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
