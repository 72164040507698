import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "avatars-group pa-3 pt-0 stacked" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = {
  key: 1,
  class: "text-white"
}
const _hoisted_4 = {
  key: 0,
  class: "avatars-group__item"
}
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = {
  key: 1,
  class: "text_white"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item_avatar = _resolveComponent("v-list-item-avatar")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_content = _resolveComponent("v-list-item-content")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createBlock(_component_v_row, null, {
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.avatars, (avatar) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass([avatar.isClicked ? 'selectedAvatar ' + _ctx.touchClass + '' : _ctx.touchClass, "avatars-group__item"]),
            key: `avatar-id-${avatar.id}`
          }, [
            _createVNode(_component_v_tooltip, { top: "" }, {
              activator: _withCtx(({ props }) => [
                (avatar.id)
                  ? (_openBlock(), _createBlock(_component_v_avatar, _mergeProps({
                      key: 0,
                      class: "selectedAvatarIcon",
                      ref_for: true
                    }, props, {
                      color: !avatar.photoUrl ? _ctx.getColour(avatar.fullName) : null
                    }), {
                      default: _withCtx(() => [
                        _renderSlot(_ctx.$slots, "default", {}, undefined, true),
                        (avatar.photoUrl)
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 0,
                              class: "pa-1 profile-photo",
                              src: avatar.photoUrl,
                              alt: avatar.fullName
                            }, null, 8, _hoisted_2))
                          : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.getInitials(avatar.fullName)), 1))
                      ]),
                      _: 2
                    }, 1040, ["color"]))
                  : _createCommentVNode("", true)
              ]),
              default: _withCtx(() => [
                _createElementVNode("b", null, _toDisplayString(avatar.lable), 1),
                _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
                _createElementVNode("span", null, _toDisplayString(avatar.fullName), 1)
              ]),
              _: 2
            }, 1024)
          ], 2))
        }), 128)),
        (_ctx.showMore && _ctx.hasMore)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_v_avatar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_menu, {
                    modelValue: _ctx.menu,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.menu) = $event)),
                    "close-on-content-click": false,
                    location: "end"
                  }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_btn, _mergeProps({ color: "indigo" }, props, { icon: "mdi-dots-horizontal" }), null, 16)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, { class: "avatarMenu" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list, null, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.avatars, (avatar) => {
                                return (_openBlock(), _createBlock(_component_v_list_item, {
                                  key: `avatar-menu-id-${avatar.id}`
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_row, {
                                      class: _normalizeClass([avatar.isClicked ? 'selectedAvatarListItem' : '', "avatar-moreList"])
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_col, { md: "2" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_list_item_avatar, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_avatar, {
                                                  color: !avatar.photoUrl ? _ctx.getColour(avatar.fullName) : null
                                                }, {
                                                  default: _withCtx(() => [
                                                    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
                                                    (avatar.photoUrl)
                                                      ? (_openBlock(), _createElementBlock("img", {
                                                          key: 0,
                                                          class: "pa-1 profile-photo",
                                                          src: avatar.photoUrl,
                                                          alt: avatar.fullName
                                                        }, null, 8, _hoisted_5))
                                                      : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.getInitials(avatar.fullName)), 1))
                                                  ]),
                                                  _: 2
                                                }, 1032, ["color"])
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 2
                                        }, 1024),
                                        _createVNode(_component_v_col, { class: "moreList ml-0" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_list_item_content, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_list_item_title, { class: "pl-2" }, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(avatar.fullName), 1)
                                                  ]),
                                                  _: 2
                                                }, 1024)
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 2
                                        }, 1024),
                                        _createVNode(_component_v_divider)
                                      ]),
                                      _: 2
                                    }, 1032, ["class"])
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128))
                            ]),
                            _: 3
                          })
                        ]),
                        _: 3
                      })
                    ]),
                    _: 3
                  }, 8, ["modelValue"])
                ]),
                _: 3
              })
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }))
}