import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, toDisplayString as _toDisplayString, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, toHandlers as _toHandlers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "course-container" }
const _hoisted_2 = { class: "back-btn" }
const _hoisted_3 = { class: "favorite-btn" }
const _hoisted_4 = { class: "f-body-1" }
const _hoisted_5 = { class: "f-body-2" }
const _hoisted_6 = { class: "f-body-2" }
const _hoisted_7 = {
  key: 0,
  class: "flex-section f-body-2"
}
const _hoisted_8 = {
  key: 1,
  class: "flex-section f-body-2"
}
const _hoisted_9 = { class: "f-body-2" }
const _hoisted_10 = {
  key: 2,
  class: "f-body-2"
}
const _hoisted_11 = ["href"]
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "f-body-1" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { loading: _ctx.isLoading }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.courseModel)
          ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_v_btn, {
                    icon: "",
                    onClick: _ctx.goBack
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => _cache[0] || (_cache[0] = [
                          _createTextVNode("mdi-arrow-left")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_v_tooltip, { location: "bottom" }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_btn, _mergeProps(props, {
                        style: {"min-width":"0"},
                        icon: ""
                      }), {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, null, {
                            default: _withCtx(() => _cache[1] || (_cache[1] = [
                              _createTextVNode("mdi-heart")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1040)
                    ]),
                    default: _withCtx(() => [
                      _cache[2] || (_cache[2] = _createTextVNode(" Add to Favorites "))
                    ]),
                    _: 1
                  })
                ]),
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, {
                              lg: "5",
                              md: "5",
                              sm: "6",
                              class: "d-flex"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_img, {
                                  src: _ctx.courseModel.ImageDownloadUrl,
                                  width: 300,
                                  cover: "",
                                  "aspect-ratio": "16/9",
                                  class: "course-image"
                                }, null, 8, ["src"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              lg: "7",
                              md: "7",
                              sm: "6",
                              class: "details-section"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", null, [
                                  _createElementVNode("h2", null, _toDisplayString(_ctx.courseModel.Title), 1)
                                ]),
                                _createElementVNode("div", _hoisted_4, [
                                  _createVNode(_component_v_tooltip, { location: "bottom" }, {
                                    activator: _withCtx(({ props }) => [
                                      _createElementVNode("span", _mergeProps({ class: "description" }, props), _toDisplayString(_ctx.courseModel.Summary), 17)
                                    ]),
                                    default: _withCtx(() => [
                                      _cache[3] || (_cache[3] = _createTextVNode(" Summary "))
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _createElementVNode("div", _hoisted_5, [
                                  _createVNode(_component_v_tooltip, { location: "bottom" }, {
                                    activator: _withCtx(({ props }) => [
                                      _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), [
                                        _createVNode(_component_v_icon, null, {
                                          default: _withCtx(() => _cache[4] || (_cache[4] = [
                                            _createTextVNode("mdi-human-male-board")
                                          ])),
                                          _: 1
                                        }),
                                        _createTextVNode(" " + _toDisplayString(_ctx.courseModel.Author), 1)
                                      ], 16)
                                    ]),
                                    default: _withCtx(() => [
                                      _cache[5] || (_cache[5] = _createTextVNode(" Author "))
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _createElementVNode("div", _hoisted_6, [
                                  _createVNode(_component_v_tooltip, { location: "bottom" }, {
                                    activator: _withCtx(({ props }) => [
                                      _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), [
                                        _createVNode(_component_v_icon, null, {
                                          default: _withCtx(() => _cache[6] || (_cache[6] = [
                                            _createTextVNode("mdi-timer-sand")
                                          ])),
                                          _: 1
                                        }),
                                        _createTextVNode(" " + _toDisplayString(_ctx.formatDuration(_ctx.courseModel.Duration)), 1)
                                      ], 16)
                                    ]),
                                    default: _withCtx(() => [
                                      _cache[7] || (_cache[7] = _createTextVNode(" Duration "))
                                    ]),
                                    _: 1
                                  }),
                                  _cache[11] || (_cache[11] = _createTextVNode(" - ")),
                                  _createVNode(_component_v_tooltip, { location: "bottom" }, {
                                    activator: _withCtx(({ on, attrs }) => [
                                      _createElementVNode("span", _mergeProps(attrs, _toHandlers(on, true)), [
                                        _createVNode(_component_v_icon, null, {
                                          default: _withCtx(() => _cache[8] || (_cache[8] = [
                                            _createTextVNode("mdi-bulletin-board")
                                          ])),
                                          _: 1
                                        }),
                                        _createTextVNode(" " + _toDisplayString(_ctx.courseModel.Steps.length) + " Step ", 1)
                                      ], 16)
                                    ]),
                                    default: _withCtx(() => [
                                      _createTextVNode(" " + _toDisplayString(_ctx.courseModel.Steps.filter((item) => item.Type == "Lesson").length) + " Lesson and " + _toDisplayString(_ctx.courseModel.Steps.filter((item) => item.Type == "Assessment").length) + " Assessment ", 1)
                                    ]),
                                    _: 1
                                  }),
                                  _cache[12] || (_cache[12] = _createTextVNode(" - ")),
                                  _createVNode(_component_v_tooltip, { location: "bottom" }, {
                                    activator: _withCtx(({ props }) => [
                                      _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getLevelStarsCount(_ctx.courseModel.Level), (i, index) => {
                                          return (_openBlock(), _createBlock(_component_v_icon, { key: index }, {
                                            default: _withCtx(() => _cache[9] || (_cache[9] = [
                                              _createTextVNode("mdi-star")
                                            ])),
                                            _: 2
                                          }, 1024))
                                        }), 128)),
                                        _createTextVNode(" " + _toDisplayString(_ctx.courseModel.Level), 1)
                                      ], 16)
                                    ]),
                                    default: _withCtx(() => [
                                      _cache[10] || (_cache[10] = _createTextVNode(" Level "))
                                    ]),
                                    _: 1
                                  })
                                ]),
                                (_ctx.courseModel.Tags.length > 0)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                      _createVNode(_component_v_tooltip, { location: "bottom" }, {
                                        activator: _withCtx(({ props }) => [
                                          _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), [
                                            _createVNode(_component_v_icon, null, {
                                              default: _withCtx(() => _cache[13] || (_cache[13] = [
                                                _createTextVNode("mdi-tag")
                                              ])),
                                              _: 1
                                            })
                                          ], 16)
                                        ]),
                                        default: _withCtx(() => [
                                          _cache[14] || (_cache[14] = _createTextVNode(" Tags "))
                                        ]),
                                        _: 1
                                      }),
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.courseModel.Tags, (tag, index) => {
                                        return (_openBlock(), _createBlock(_component_v_chip, { key: index }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(tag.Title), 1)
                                          ]),
                                          _: 2
                                        }, 1024))
                                      }), 128))
                                    ]))
                                  : _createCommentVNode("", true),
                                (_ctx.courseModel.Skills.length > 0)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                      _createVNode(_component_v_tooltip, { location: "bottom" }, {
                                        activator: _withCtx(({ props }) => [
                                          _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), [
                                            _createVNode(_component_v_icon, null, {
                                              default: _withCtx(() => _cache[15] || (_cache[15] = [
                                                _createTextVNode("mdi-shape")
                                              ])),
                                              _: 1
                                            })
                                          ], 16)
                                        ]),
                                        default: _withCtx(() => [
                                          _cache[16] || (_cache[16] = _createTextVNode(" Skill "))
                                        ]),
                                        _: 1
                                      }),
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.courseModel.Skills, (skill, index) => {
                                        return (_openBlock(), _createBlock(_component_v_chip, { key: index }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.getSkillName(skill)), 1)
                                          ]),
                                          _: 2
                                        }, 1024))
                                      }), 128))
                                    ]))
                                  : _createCommentVNode("", true),
                                _createElementVNode("div", _hoisted_9, [
                                  _createVNode(_component_v_tooltip, { location: "bottom" }, {
                                    activator: _withCtx(({ props }) => [
                                      _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), [
                                        _createVNode(_component_v_icon, null, {
                                          default: _withCtx(() => _cache[17] || (_cache[17] = [
                                            _createTextVNode("mdi-update")
                                          ])),
                                          _: 1
                                        }),
                                        _createTextVNode(" " + _toDisplayString(_ctx.courseModel.UpdatedAt), 1)
                                      ], 16)
                                    ]),
                                    default: _withCtx(() => [
                                      _cache[18] || (_cache[18] = _createTextVNode(" Last Update "))
                                    ]),
                                    _: 1
                                  })
                                ]),
                                (_ctx.courseModel.Dependencies.length > 0)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                      _createVNode(_component_v_row, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_col, { cols: "12" }, {
                                            default: _withCtx(() => _cache[19] || (_cache[19] = [
                                              _createTextVNode(" Dependencies: ")
                                            ])),
                                            _: 1
                                          }),
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.courseModel.Dependencies, (dependency, index) => {
                                            return (_openBlock(), _createBlock(_component_v_col, {
                                              cols: "12",
                                              key: index
                                            }, {
                                              default: _withCtx(() => [
                                                (dependency.DependencyId)
                                                  ? (_openBlock(), _createElementBlock("a", {
                                                      key: 0,
                                                      href: dependency.DependencyId,
                                                      target: "_blank",
                                                      class: "dependency-link"
                                                    }, _toDisplayString(dependency.Text), 9, _hoisted_11))
                                                  : (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(dependency.Text), 1))
                                              ]),
                                              _: 2
                                            }, 1024))
                                          }), 128))
                                        ]),
                                        _: 1
                                      })
                                    ]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { cols: "12" }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_13, [
                                  _createVNode(_component_v_tooltip, { location: "bottom" }, {
                                    activator: _withCtx(({ props }) => [
                                      _createElementVNode("span", _mergeProps({ class: "description" }, props), _toDisplayString(_ctx.courseDetail.Description), 17)
                                    ]),
                                    default: _withCtx(() => [
                                      _cache[20] || (_cache[20] = _createTextVNode(" Description "))
                                    ]),
                                    _: 1
                                  })
                                ])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { cols: "12" }, {
                              default: _withCtx(() => _cache[21] || (_cache[21] = [
                                _createElementVNode("strong", null, "Steps: ", -1)
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              class: "pt-5 pb-5"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_list, null, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.courseModel.Steps, (step, index) => {
                                      return (_openBlock(), _createBlock(_component_v_list_item, { key: index }, {
                                        default: _withCtx(() => [
                                          (step.Type == 'Lesson')
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(step.Title) + " - " + _toDisplayString(_ctx.formatDuration(step.Duration)), 1))
                                            : _createCommentVNode("", true),
                                          (step.Type == 'Assessment')
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(step.AssessmentDetails.Title) + " - " + _toDisplayString(_ctx.formatDuration(step.AssessmentDetails.Questions.reduce((total, item) => total + item.Duration, 0))), 1))
                                            : _createCommentVNode("", true)
                                        ]),
                                        _: 2
                                      }, 1024))
                                    }), 128))
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_v_btn, {
                                  variant: "tonal",
                                  class: "start-button",
                                  onClick: _ctx.navigateToLessons
                                }, {
                                  default: _withCtx(() => _cache[22] || (_cache[22] = [
                                    _createTextVNode(" Start the Course ")
                                  ])),
                                  _: 1
                                }, 8, ["onClick"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["loading"]))
}