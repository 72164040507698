<template>
  <div>
    <v-card-title>Please ensure correct address has been entered or payments may be lost.</v-card-title>
    <v-card-text>
      <v-form ref="frmRegisterCrypto" lazy-validation class="form-inputs">
        <v-row>
          <v-col md="4" sm="4" cols="12">
            <TextField
              dense
              :rules="[validations.required]"
              showRequired
              v-model="cryptoModel.WalletAddress"
              outlined
              label="Your wallet address (USDT-BEP20 only)"
            />
          </v-col>
          <v-col md="8" sm="8" cols="12" class="text-right align-self-center pt-6">
            <v-btn color="primary_btn" :loading="loading" :disabled="loading" @click="save()"><v-icon>mdi-check</v-icon> add </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12" cols="12"> <b>Payment History</b> </v-col>
          <v-col>
            <v-data-table :loading="loading" :hide-default-footer="true" class="pt-0" :items="cryptoList" :headers="headers">
              <template v-for="h in headers" v-slot:[`header.${h.value}`]="{ header }">
                <v-tooltip top v-if="h.text === 'Default account'">
                  <template v-slot:activator="{ props }">
                    <span v-props="props">{{h.text}}</span>
                  </template>
                  <span>You can only choose one account as your default account. Your payments will be deposited into your default account. </span>
                </v-tooltip>
                <span v-else>{{h.text}}</span>
              </template>
              <template v-slot:item.WalletAddress="{ item }">
                {{ item.WalletAddress }}
              </template>
              <template v-slot:item.CreatedAt="{ item }">
                {{ formatDate(item.CreatedAt) }}
              </template>
              <template v-slot:item.IsDefault="{ item }">
                <v-col class="text-center">
                  <v-checkbox
                    class="d-inline-flex"
                    :disabled="item.IsDefault || loading"
                    v-model="item.IsDefault"
                    @change="changeItemStatus(item.Id)"
                  ></v-checkbox>
                </v-col>
              </template>
            </v-data-table>
            <v-card-subtitle><span class="warning-text"><strong>*</strong> You can only choose one account as your default account. Your payments will be deposited into your default account.</span></v-card-subtitle>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-dialog persistent v-if="showConfirm" v-model="showConfirm" @keydown.esc="cancel" max-width="500px">
      <confirm-dialog :loading="loading" @confirm="confirmSave" @cancel="cancel" :message="confirmModel"> </confirm-dialog>
    </v-dialog>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { Teammember } from "shared-components/src/models/Teammember";
import validations from "shared-components/src/utils/validations";
import { CryptoCurrency } from "shared-components/src/models/CryptoCurrency";
import ConfirmDialog from "shared-components/src/components/ConfirmDialog.vue";
import Utils from "shared-components/src/utils/Utils";
import { mapMutations } from "vuex";
import { ProjectMessages } from "shared-components/src/definitions/constants";
import store from "@/store";

export default defineComponent({
  props: ["cryptoCurrencyModel", "submitting", "cryptos", "paymentMethodSaveMessage"],
  data() {
    return {
      confirmModel: {
        title: "",
        text: "",
      },
      validations,
      cryptoModel: {
        Id: "",
        IsDefault: false,
        WalletAddress: "",
      } as CryptoCurrency,
      showConfirm: false,
      selectedId: "",
      cryptoList: [] as CryptoCurrency[],
      loading: false,
      walletAddress: "",
      paymentMethodMessage: "",
      inline: 0,
      teamMemberInfo: store.state.teamMemberInfo as Teammember,
      headers: [
        { title: "Wallet address", align: "center", value: "WalletAddress" },
        { title: "Request date", align: "center", value: "CreatedAt" },
        { title: "Default account", align: "center", value: "IsDefault" },
      ],
    };
  },
  components: {
    ConfirmDialog,
  },
  watch: {
    paymentMethodSaveMessage(newVal){
      this.paymentMethodMessage = newVal;
    },
    cryptos(newVal) {
      this.cryptoList = newVal;
    },
    submitting(newVal) {
      this.loading = newVal;
    },
  },
  mounted() {
    this.cryptoList = this.cryptos;
  },
  methods: {
    changeItemStatus(id: any) {
      this.selectedId = id;
      this.confirmModel = {
        title: ProjectMessages.defaultPeymentMethod,
        text: ProjectMessages.cryptoChangeDefaultMessage,
      };
      this.showConfirm = true;
    },
    formatDate(date: Date | null): string {
      if (date) {
        return Utils.toVsDateFormat(date);
      } else {
        return "Not set";
      }
    },
    cancel() {
      this.showConfirm = false;
      if (this.selectedId) {
        var findItem = this.cryptoList.find((c) => c.Id === this.selectedId);
        if (findItem) {
          findItem.IsDefault = false;
        }
      }
      this.selectedId = "";
    },
    confirmSave() {
      if (this.selectedId) {
        var findItem = this.cryptoList.find((c) => c.Id === this.selectedId);
        if (findItem) {
          this.$emit("save", findItem);
        }
      } else {
        if(this.cryptoList.length === 0){
          this.cryptoModel.IsDefault = true;
        }
        this.$emit("save", this.cryptoModel);
      }
      this.showConfirm = false;
      this.selectedId = "";
      this.cryptoModel = {} as CryptoCurrency;
    },
    async save() {
      const isValid = await (this.$refs.frmRegisterCrypto as any).validate();
      if (isValid.valid) {
        this.confirmModel = {
          title: ProjectMessages.addPeymentMethod,
          text: `${this.paymentMethodMessage}Are you sure you want to save and add ${this.cryptoModel.WalletAddress} as a Crypto currency?`,
        };
        this.showConfirm = true;
      }
    },
  },
});
</script>
