<template>
  <div class="commitment-card">
    <div class="commitment-card-inner" :class="{ 'timesheet-mode': TimesheetMode }">
      <div v-if="!TimesheetMode" class="commitment-card-front commitment-card-container text-center">
        <div>
          <p class="truncate">{{ item.Name }}</p>
        </div>
        <div>
          <v-row :class="checkColor(item.Status)" class="mt-0 mb-0 align-center avatar-box">
            <v-col cols="8">
              <AvatarList :items="avatarList(item)"></AvatarList>
            </v-col>
            <v-col cols="4">
              <span class="f-body-1">
                <b>{{ item.Status }}</b>
              </span>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row class="m-0">
            <v-col cols="6">
              <b>Start Date:</b>
              <span class="f-body-2"> {{ formatDate(item.StartDate) }} </span>
            </v-col>
            <v-col cols="6">
              <b>End Date:</b>
              <span class="f-body-2"> {{ formatDate(item.EndDate) }} </span>
            </v-col>
          </v-row>
        </div>
        <hr />
        <div>
          <v-row class="m-0">
            <v-col cols="12"> <b> Hours Per Week </b> </v-col>
            <v-col cols="6">
              <b> Min: </b>
              <span class="f-body-2">
                {{ item.HoursPerWeekMin ?? 0 }}
              </span>
            </v-col>
            <v-col cols="6">
              <b> Max: </b>
              <span class="f-body-2">
                {{ item.HoursPerWeekMax ?? 0 }}
              </span>
            </v-col>
          </v-row>
        </div>
        <hr />
        <div>
          <v-row class="m-0">
            <v-col cols="12"> <b>Projects</b> </v-col>
            <v-col cols="12">
              <div v-if="item.ProjectDetails">
                <SelectField
                  :items="item.ProjectDetails"
                  placeholder="Select a Project"
                  v-model="SelectedProjectId"
                  item-title="Name"
                  item-value="id"
                  outlined
                  :disabled="item.ProjectDetails.length == 1"
                  @change="selectEpics()"
                />
              </div>
            </v-col>
          </v-row>
        </div>
        <hr />
        <div>
          <v-row class="m-0">
            <v-col cols="12"> <b>Epics</b> </v-col>
            <v-col cols="12">
              <div>
                <SelectField
                  :items="Epic?.Items ?? []"
                  placeholder="Select a Epic"
                  v-model="SelectedEpic"
                  item-title="Name"
                  item-value="Name"
                  :loading="epicLoading"
                  :disabled="epicLoading"
                  outlined
                  @change="changeToTimesheetMode"
                />
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
      <div v-else class="commitment-card-back commitment-card-container text-center">
        <v-form ref="frmMain" v-model="valid" lazy-validation>
          <div>
            <v-row class="m-0">
              <v-col cols="6">
                <SelectField
                  :items="Epic?.Items ?? []"
                  placeholder="Select a Epic"
                  label="Epic"
                  v-model="SelectedEpic"
                  item-title="Name"
                  item-value="Name"
                  :loading="epicLoading"
                  :disabled="epicLoading"
                  outlined
                  dense
                  @change="changeToTimesheetMode"
                />
              </v-col>
              <v-col cols="6">
                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                  <template v-slot:activator="{ props}">
                    <label class="input-label"> Date </label>
                    <v-text-field :rules="[rules.required]" dense v-model="formatedSelectedDate" readonly outlined v-bind="props"></v-text-field>
                  </template>
                  <v-date-picker
                    first-day-of-week="1"
                    ref="picker"
                    v-model="timesheetModel.date"
                    :min="minDate"
                    @update:modelValue="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </div>
          <div>
            <v-row class="m-0">
              <v-col cols="6">
                <TextField
                  :rules="[rules.required]"
                  @blur="fillDescription()"
                  placeholder="Ticket / Issue No"
                  label="Ticket#"
                  v-model="timesheetModel.issueNo"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="6">
                <TextField
                  :rules="[rules.required, rules.step, rules.notZero]"
                  min="0"
                  max="10"
                  placeholder="Effort / Hours"
                  label="Effort"
                  type="number"
                  v-model="timesheetModel.effort"
                  outlined
                  dense
                  step="0.25"
                />
              </v-col>
            </v-row>
          </div>
          <div>
            <v-row class="m-0">
              <v-col cols="6">
                <SelectField
                  :rules="[rules.required]"
                  placeholder="Activity"
                  label="Activity"
                  v-model="timesheetModel.activityId"
                  :items="activityList"
                  item-title="Value"
                  item-value="id"
                  outlined
                  dense
                  v-on:change="activityChanged"
                />
              </v-col>
              <v-col cols="6">
                <SelectField
                  :rules="[rules.required]"
                  label="Technology"
                  placeholder="Technology"
                  v-model="timesheetModel.technologyId"
                  :items="technologyList"
                  item-title="Value"
                  item-value="id"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </div>
          <div>
            <v-row class="m-0">
              <v-col cols="12">
                <TextAreaField
                  no-resize
                  rows="3"
                  :rules="[rules.required]"
                  outlined
                  dense
                  placeholder="Description"
                  label="Description"
                  v-model="timesheetModel.description"
                />
              </v-col>
            </v-row>
          </div>
          <div>
            <div class="timesheet-action-box">
              <v-btn class="secondary_btn" :loading="saveLoading" @click="Cancel">Cancel</v-btn>
              <v-btn class="primary_btn" :loading="saveLoading" @click="Save(false)">Save</v-btn>
              <v-btn class="tertiary_btn" :loading="saveLoading" @click="Save(true)">Save & Close</v-btn>
            </div>
          </div>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { UICommitment } from "shared-components/src/models/Commitment";
import AvatarList from "../AvatarList.vue";
import Utils from "shared-components/src/utils/Utils";
import Avatar from "shared-components/src/models/Avatar";
import Epic from "shared-components/src/models/Epic";
import { Activity } from "shared-components/src/models/Activity";
import { Technology } from "shared-components/src/models/Technology";
import Timesheet from "shared-components/src/models/Timesheet";
import { Teammember } from "shared-components/src/models/Teammember";
import { AuditTrailRecordTypes } from "shared-components/src/definitions/constants";
import UserInfo from "shared-components/src/models/UserInfo";
import AuditTrail from "shared-components/src/models/AuditTrail";
import AuditTrailService from "shared-components/src/services/AuditTrailService";
import TimesheetService from "@/services/TimesheetService";
import store from "@/store";

export default defineComponent({
  props: {
    item: {
      type: Object as () => UICommitment,
      required: true,
    },
    activities: {
      type: Array as () => Activity[],
      required: true,
    },
    technologies: {
      type: Array as () => Technology[],
      required: true,
    },
    epics: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      epicLoading: false,
      SelectedProjectId: "",
      SelectedEpic: "",
      Epic: {} as Epic,
      TimesheetMode: false,
      valid: false,
      rules: {
        required: (value: any) => !!value || "Required.",
        step: (value: any) => value % 0.25 == 0 || "Please enter a value multiple of 0.25",
        notZero: (value: any) => value > 0 || "Please enter a value more than 0",
      },
      activityList: [] as Activity[],
      fullActivityList: [] as Activity[],
      technologyList: [] as Technology[],
      filteredTechnologies: [] as Technology[],
      epicList: [] as Epic[],
      menu: false,
      timesheetModel: {
        date: null as Date | null,
        issueNo: "",
        effort: 0,
        description: "",
        activityId: "",
        technologyId: "",
      },
      saveLoading: false,
    };
  },
  created() {
    if (this.item.ProjectDetails) {
      this.SelectedProjectId = this.item.ProjectDetails[0].id;
      this.selectEpics();
    }
  },
  methods: {
    Cancel() {
      this.TimesheetMode = false;
      this.resetModel();
    },
    async Save(closeAfterSave: boolean) {
      const isValid = await (this.$refs.frmMain as any).validate();
      if (isValid.valid) {
        this.saveLoading = true;
        if (this.timesheetModel.effort < 0) {
          this.timesheetModel.effort = 0;
        }
        const timesheet = {
          Commitment: { id: this.item.id, Name: this.item.Name },
          CommitmentId: this.item.id,
          Activity: "",
          ActivityId: this.timesheetModel.activityId,
          Epic: this.SelectedEpic,
          Date: this.timesheetModel.date,
          DateLink: null,
          Description: this.timesheetModel.description,
          Effort: Number(this.timesheetModel.effort),
          TeamMemberId: this.teamMemberInfo?.Id,
          IssueNo: this.timesheetModel.issueNo,
          ProjectId: this.SelectedProjectId,
          Project: this.selectedProject(),
          Technology: "",
          TechnologyId: this.timesheetModel.technologyId,
        } as Timesheet;
        await TimesheetService.saveTimesheet(timesheet)
          .then((result) => {
            const audit = {
              RecordType: {
                Type: AuditTrailRecordTypes.Timesheet,
                Id: result,
              },
              Event: timesheet.id ? "Update" : "Create",
              DateTime: new Date(),
              UserId: this.userInfo.id,
            } as AuditTrail;
            AuditTrailService.set(audit);
            if (timesheet.Date) {
              timesheet.Date = Utils.addTimezoneOffset(timesheet.Date);
            }
            if (closeAfterSave) {
              this.TimesheetMode = false;
              this.resetModel();
            } else {
              this.resetModel();
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              store.dispatch("showErrorMessage", error.response.data)
            } else {
              this.errorRaised("There is an error to submit the timesheet record!");
            }
            console.error(error);
          })
          .finally(() => {
            this.saveLoading = false;
          });
      }
    },
    errorRaised(msg: string): void {
      store.dispatch("showErrorMessage", msg)
    },
    selectedProject(): any {
      const projects = this.item.ProjectDetails?.filter((x) => x.id === this.SelectedProjectId);
      if (projects && projects.length > 0) {
        return { Name: projects[0].Name, id: this.SelectedProjectId };
      } else {
        return null;
      }
    },
    resetModel() {
      this.timesheetModel.issueNo = "";
      this.timesheetModel.effort = 0;
      this.timesheetModel.description = "";
      this.timesheetModel.technologyId = "";
      this.timesheetModel.activityId = "";
    },
    async fetchTechnologies(activityId = "") {
      this.technologyList = this.technologies.filter((c) => c.ActivityIds && c.ActivityIds.includes(activityId));
      this.sortTechnologies();
    },
    filterActivity() {
      this.activityList = this.fullActivityList.filter(
        (item) =>
          item.Disabled == false || (item.Disabled == true && this.timesheetModel.activityId.length > 1 && item.id == this.timesheetModel.activityId)
      );
    },
    activityChanged() {
      this.sortTechnologies();
      if (this.timesheetModel.technologyId.length > 0) {
        let selectedTechnology = this.technologyList.find((item) => item.id == this.timesheetModel.technologyId);
        if (selectedTechnology && (selectedTechnology as any).disabled) {
          this.timesheetModel.technologyId = "";
        }
      }
      var selectedActivity = this.activityList.find((c) => c.id === this.timesheetModel.activityId);
      if (selectedActivity && selectedActivity.DefaultTechnology) {
        this.timesheetModel.technologyId = selectedActivity.DefaultTechnology;
      } else {
        this.timesheetModel.technologyId = "";
      }
    },
    sortTechnologies() {
      this.technologyList.forEach((item) => {
        if (this.timesheetModel.activityId == "" || !item.ActivityIds || item.ActivityIds.indexOf(this.timesheetModel.activityId) == -1) {
          (item as any).disabled = true;
        } else {
          (item as any).disabled = false;
        }
      });
      this.technologyList.sort((a, b) => {
        if ((a as any).disabled > (b as any).disabled) return 1;
        if ((a as any).disabled < (b as any).disabled) return -1;
        if (a.Value.toLowerCase() < b.Value.toLowerCase()) {
          return -1;
        }
        if (a.Value.toLowerCase() > b.Value.toLowerCase()) {
          return 1;
        }
        return 0;
      });
    },
    fillDescription() {
      if (this.timesheetModel.issueNo) {
        this.timesheetModel.description = this.timesheetModel.issueNo + " - ";
      } else {
        this.timesheetModel.description = "";
      }
    },
    changeToTimesheetMode() {
      if (this.item.Status == "Active") this.TimesheetMode = true;
    },
    checkColor(status: any) {
      switch (status) {
        case "Inactive":
          return "b-battleship-grey1";
        case "Expired":
          return "b-imperial-red";
        case "Upcoming":
          return "b-light-green";
        case "Active":
          return "b-emerald";
      }
    },
    formatDate(date: Date | null): string {
      if (date) {
        return Utils.toVsDateFormat(date);
      } else {
        return "Not set";
      }
    },
    async selectEpics() {
      if (this.SelectedProjectId) {
        this.epicLoading = true;
        this.Epic = {} as Epic;
        const epic = (this.epicList as any)[this.SelectedProjectId];
        if (epic) {
          this.Epic = epic;
        }
        this.epicLoading = false;
      }
    },
    avatarList(item: UICommitment): any {
      const avatarList: any[] = [];
      if (item.PortfolioManagerDetail) {
        avatarList.push({
          fullName: item.PortfolioManagerDetail.FullName,
          id: item.PortfolioManagerDetail.Id,
          lable: "Portfolio Manager",
          photoUrl: item.PortfolioManagerDetail.PhotoUrl,
        } as Avatar);
      }
      if (item.ProjectLeadDetail) {
        avatarList.push({
          fullName: item.ProjectLeadDetail.FullName,
          id: item.ProjectLeadDetail.Id,
          lable: "Project Lead",
          photoUrl: item.ProjectLeadDetail.PhotoUrl,
        } as Avatar);
      }
      if (item.LineManagerDetail) {
        avatarList.push({
          fullName: item.LineManagerDetail.FullName,
          id: item.LineManagerDetail.Id,
          lable: "Line Manager",
          photoUrl: item.LineManagerDetail.PhotoUrl,
        } as Avatar);
      }

      return avatarList;
    },
    formatedSelectedDate() {
      return Utils.toVsDateFormat(this.timesheetModel.date || new Date());
    },
  },
  computed: {
    teamMemberInfo() {
      return store.state.teamMemberInfo;
    },
    userInfo(): UserInfo {
      return store.state.userInfo;
    },
    minDate(): string {
      return Utils.toVsDateFormat(Utils.GetLimitedDateForTimesheet(false));
    },
  },
  watch: {
    technologies(newVal) {
      if (this.technologyList != newVal) {
        this.technologyList = [...newVal];
        this.sortTechnologies();
      }
    },
    activities(newVal) {
      this.fullActivityList = newVal;
      this.filterActivity();
    },
    epics(newVal) {
      if (this.epicList != newVal) {
        this.epicList = newVal;
        this.selectEpics();
      }
    },
    "timesheetModel.activityId"(newVal) {
      this.fetchTechnologies(newVal);
    },
  },
  components: { AvatarList },
});
</script>
<style lang="scss">
@import "shared-components/assets/style/color.scss";
@import "shared-components/assets/style/style.scss";

.commitment-card {
  .commitment-card-back {
    @extend .b-dark-purple;
    color: $c_white !important;
    transform: rotateY(180deg);
  }

  .commitment-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.5s;
    transform-style: flat;

    &.timesheet-mode {
      transform: rotateY(180deg);
    }
  }

  .commitment-card-container {
    @extend .b-r-5;
    @extend .b-white;
    color: $c_black;
    padding: 15px;
    display: flex;
    flex-flow: column;
    .avatar-box {
      min-height: 75px;
    }

    .timesheet-action-box {
      display: flex;
      gap: 5px;
      justify-content: center;
    }
  }
}
</style>
