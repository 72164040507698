import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "assessment-details" }
const _hoisted_2 = {
  key: 0,
  class: "detail-item"
}
const _hoisted_3 = {
  key: 1,
  class: "detail-item"
}
const _hoisted_4 = { key: 2 }
const _hoisted_5 = {
  key: 3,
  class: "tag-section"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { class: "assessment-card" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("span", {
                  class: "title detail-item",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigateToDetail(_ctx.assessment.Id)))
                }, _toDisplayString(_ctx.assessment.Title), 1),
                (_ctx.assessment.Questions)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createVNode(_component_v_tooltip, { bottom: "" }, {
                        activator: _withCtx(({ props }) => [
                          _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), [
                            _createVNode(_component_v_icon, null, {
                              default: _withCtx(() => _cache[1] || (_cache[1] = [
                                _createTextVNode("mdi-code-tags")
                              ])),
                              _: 1
                            }),
                            _createTextVNode(" " + _toDisplayString(_ctx.getTechnologies(_ctx.assessment.Questions)), 1)
                          ], 16)
                        ]),
                        default: _withCtx(() => [
                          _cache[2] || (_cache[2] = _createTextVNode(" Questions Technologies "))
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.assessment.Questions)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createVNode(_component_v_tooltip, { bottom: "" }, {
                        activator: _withCtx(({ props }) => [
                          _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), [
                            _createVNode(_component_v_icon, null, {
                              default: _withCtx(() => _cache[3] || (_cache[3] = [
                                _createTextVNode("mdi-stairs-up")
                              ])),
                              _: 1
                            }),
                            _createTextVNode(" " + _toDisplayString(_ctx.getLevels(_ctx.assessment.Questions)), 1)
                          ], 16)
                        ]),
                        default: _withCtx(() => [
                          _cache[4] || (_cache[4] = _createTextVNode(" Questions Levels "))
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.assessment.Questions)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_v_tooltip, { bottom: "" }, {
                        activator: _withCtx(({ props }) => [
                          _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), [
                            _createVNode(_component_v_icon, null, {
                              default: _withCtx(() => _cache[5] || (_cache[5] = [
                                _createTextVNode("mdi-clock-outline")
                              ])),
                              _: 1
                            }),
                            _createTextVNode(" " + _toDisplayString(_ctx.getDuration(_ctx.assessment.Questions)), 1)
                          ], 16)
                        ]),
                        default: _withCtx(() => [
                          _cache[6] || (_cache[6] = _createTextVNode(" Questions Duration "))
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.assessment.Tags.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createVNode(_component_v_tooltip, { bottom: "" }, {
                        activator: _withCtx(({ props }) => [
                          _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), [
                            _createVNode(_component_v_icon, null, {
                              default: _withCtx(() => _cache[7] || (_cache[7] = [
                                _createTextVNode("mdi-tag")
                              ])),
                              _: 1
                            })
                          ], 16)
                        ]),
                        default: _withCtx(() => [
                          _cache[8] || (_cache[8] = _createTextVNode(" Tags "))
                        ]),
                        _: 1
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.assessment.Tags, (tag, index) => {
                        return (_openBlock(), _createBlock(_component_v_chip, {
                          link: "",
                          key: index
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(tag.Title), 1)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}