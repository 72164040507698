import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "commitment-card" }
const _hoisted_2 = {
  key: 0,
  class: "commitment-card-front commitment-card-container text-center"
}
const _hoisted_3 = { class: "truncate" }
const _hoisted_4 = { class: "f-body-1" }
const _hoisted_5 = { class: "f-body-2" }
const _hoisted_6 = { class: "f-body-2" }
const _hoisted_7 = { class: "f-body-2" }
const _hoisted_8 = { class: "f-body-2" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = {
  key: 1,
  class: "commitment-card-back commitment-card-container text-center"
}
const _hoisted_11 = { class: "timesheet-action-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AvatarList = _resolveComponent("AvatarList")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_SelectField = _resolveComponent("SelectField")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_date_picker = _resolveComponent("v-date-picker")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_TextField = _resolveComponent("TextField")!
  const _component_TextAreaField = _resolveComponent("TextAreaField")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["commitment-card-inner", { 'timesheet-mode': _ctx.TimesheetMode }])
    }, [
      (!_ctx.TimesheetMode)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", null, [
              _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.item.Name), 1)
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_v_row, {
                class: _normalizeClass([_ctx.checkColor(_ctx.item.Status), "mt-0 mb-0 align-center avatar-box"])
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "8" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AvatarList, {
                        items: _ctx.avatarList(_ctx.item)
                      }, null, 8, ["items"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "4" }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_4, [
                        _createElementVNode("b", null, _toDisplayString(_ctx.item.Status), 1)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["class"])
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_v_row, { class: "m-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "6" }, {
                    default: _withCtx(() => [
                      _cache[17] || (_cache[17] = _createElementVNode("b", null, "Start Date:", -1)),
                      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.formatDate(_ctx.item.StartDate)), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "6" }, {
                    default: _withCtx(() => [
                      _cache[18] || (_cache[18] = _createElementVNode("b", null, "End Date:", -1)),
                      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.formatDate(_ctx.item.EndDate)), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _cache[24] || (_cache[24] = _createElementVNode("hr", null, null, -1)),
            _createElementVNode("div", null, [
              _createVNode(_component_v_row, { class: "m-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => _cache[19] || (_cache[19] = [
                      _createElementVNode("b", null, " Hours Per Week ", -1)
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "6" }, {
                    default: _withCtx(() => [
                      _cache[20] || (_cache[20] = _createElementVNode("b", null, " Min: ", -1)),
                      _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.item.HoursPerWeekMin ?? 0), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "6" }, {
                    default: _withCtx(() => [
                      _cache[21] || (_cache[21] = _createElementVNode("b", null, " Max: ", -1)),
                      _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.item.HoursPerWeekMax ?? 0), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _cache[25] || (_cache[25] = _createElementVNode("hr", null, null, -1)),
            _createElementVNode("div", null, [
              _createVNode(_component_v_row, { class: "m-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => _cache[22] || (_cache[22] = [
                      _createElementVNode("b", null, "Projects", -1)
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      (_ctx.item.ProjectDetails)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                            _createVNode(_component_SelectField, {
                              items: _ctx.item.ProjectDetails,
                              placeholder: "Select a Project",
                              modelValue: _ctx.SelectedProjectId,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.SelectedProjectId) = $event)),
                              "item-title": "Name",
                              "item-value": "id",
                              outlined: "",
                              disabled: _ctx.item.ProjectDetails.length == 1,
                              onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectEpics()))
                            }, null, 8, ["items", "modelValue", "disabled"])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _cache[26] || (_cache[26] = _createElementVNode("hr", null, null, -1)),
            _createElementVNode("div", null, [
              _createVNode(_component_v_row, { class: "m-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => _cache[23] || (_cache[23] = [
                      _createElementVNode("b", null, "Epics", -1)
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", null, [
                        _createVNode(_component_SelectField, {
                          items: _ctx.Epic?.Items ?? [],
                          placeholder: "Select a Epic",
                          modelValue: _ctx.SelectedEpic,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.SelectedEpic) = $event)),
                          "item-title": "Name",
                          "item-value": "Name",
                          loading: _ctx.epicLoading,
                          disabled: _ctx.epicLoading,
                          outlined: "",
                          onChange: _ctx.changeToTimesheetMode
                        }, null, 8, ["items", "modelValue", "loading", "disabled", "onChange"])
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createVNode(_component_v_form, {
              ref: "frmMain",
              modelValue: _ctx.valid,
              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.valid) = $event)),
              "lazy-validation": ""
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createVNode(_component_v_row, { class: "m-0" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { cols: "6" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_SelectField, {
                            items: _ctx.Epic?.Items ?? [],
                            placeholder: "Select a Epic",
                            label: "Epic",
                            modelValue: _ctx.SelectedEpic,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.SelectedEpic) = $event)),
                            "item-title": "Name",
                            "item-value": "Name",
                            loading: _ctx.epicLoading,
                            disabled: _ctx.epicLoading,
                            outlined: "",
                            dense: "",
                            onChange: _ctx.changeToTimesheetMode
                          }, null, 8, ["items", "modelValue", "loading", "disabled", "onChange"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, { cols: "6" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_menu, {
                            ref: "menu",
                            modelValue: _ctx.menu,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.menu) = $event)),
                            "close-on-content-click": false,
                            transition: "scale-transition",
                            "offset-y": "",
                            "min-width": "290px"
                          }, {
                            activator: _withCtx(({ props}) => [
                              _cache[27] || (_cache[27] = _createElementVNode("label", { class: "input-label" }, " Date ", -1)),
                              _createVNode(_component_v_text_field, _mergeProps({
                                rules: [_ctx.rules.required],
                                dense: "",
                                modelValue: _ctx.formatedSelectedDate,
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formatedSelectedDate) = $event)),
                                readonly: "",
                                outlined: ""
                              }, props), null, 16, ["rules", "modelValue"])
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_v_date_picker, {
                                "first-day-of-week": "1",
                                ref: "picker",
                                modelValue: _ctx.timesheetModel.date,
                                "onUpdate:modelValue": [
                                  _cache[5] || (_cache[5] = ($event: any) => ((_ctx.timesheetModel.date) = $event)),
                                  _cache[6] || (_cache[6] = ($event: any) => (_ctx.menu = false))
                                ],
                                min: _ctx.minDate
                              }, null, 8, ["modelValue", "min"])
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", null, [
                  _createVNode(_component_v_row, { class: "m-0" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { cols: "6" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_TextField, {
                            rules: [_ctx.rules.required],
                            onBlur: _cache[8] || (_cache[8] = ($event: any) => (_ctx.fillDescription())),
                            placeholder: "Ticket / Issue No",
                            label: "Ticket#",
                            modelValue: _ctx.timesheetModel.issueNo,
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.timesheetModel.issueNo) = $event)),
                            outlined: "",
                            dense: ""
                          }, null, 8, ["rules", "modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, { cols: "6" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_TextField, {
                            rules: [_ctx.rules.required, _ctx.rules.step, _ctx.rules.notZero],
                            min: "0",
                            max: "10",
                            placeholder: "Effort / Hours",
                            label: "Effort",
                            type: "number",
                            modelValue: _ctx.timesheetModel.effort,
                            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.timesheetModel.effort) = $event)),
                            outlined: "",
                            dense: "",
                            step: "0.25"
                          }, null, 8, ["rules", "modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", null, [
                  _createVNode(_component_v_row, { class: "m-0" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { cols: "6" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_SelectField, {
                            rules: [_ctx.rules.required],
                            placeholder: "Activity",
                            label: "Activity",
                            modelValue: _ctx.timesheetModel.activityId,
                            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.timesheetModel.activityId) = $event)),
                            items: _ctx.activityList,
                            "item-title": "Value",
                            "item-value": "id",
                            outlined: "",
                            dense: "",
                            onChange: _ctx.activityChanged
                          }, null, 8, ["rules", "modelValue", "items", "onChange"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, { cols: "6" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_SelectField, {
                            rules: [_ctx.rules.required],
                            label: "Technology",
                            placeholder: "Technology",
                            modelValue: _ctx.timesheetModel.technologyId,
                            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.timesheetModel.technologyId) = $event)),
                            items: _ctx.technologyList,
                            "item-title": "Value",
                            "item-value": "id",
                            outlined: "",
                            dense: ""
                          }, null, 8, ["rules", "modelValue", "items"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", null, [
                  _createVNode(_component_v_row, { class: "m-0" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { cols: "12" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_TextAreaField, {
                            "no-resize": "",
                            rows: "3",
                            rules: [_ctx.rules.required],
                            outlined: "",
                            dense: "",
                            placeholder: "Description",
                            label: "Description",
                            modelValue: _ctx.timesheetModel.description,
                            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.timesheetModel.description) = $event))
                          }, null, 8, ["rules", "modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_v_btn, {
                      class: "secondary_btn",
                      loading: _ctx.saveLoading,
                      onClick: _ctx.Cancel
                    }, {
                      default: _withCtx(() => _cache[28] || (_cache[28] = [
                        _createTextVNode("Cancel")
                      ])),
                      _: 1
                    }, 8, ["loading", "onClick"]),
                    _createVNode(_component_v_btn, {
                      class: "primary_btn",
                      loading: _ctx.saveLoading,
                      onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.Save(false)))
                    }, {
                      default: _withCtx(() => _cache[29] || (_cache[29] = [
                        _createTextVNode("Save")
                      ])),
                      _: 1
                    }, 8, ["loading"]),
                    _createVNode(_component_v_btn, {
                      class: "tertiary_btn",
                      loading: _ctx.saveLoading,
                      onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.Save(true)))
                    }, {
                      default: _withCtx(() => _cache[30] || (_cache[30] = [
                        _createTextVNode("Save & Close")
                      ])),
                      _: 1
                    }, 8, ["loading"])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]))
    ], 2)
  ]))
}