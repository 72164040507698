<template>
  <v-row>
    <v-col lg="4" md="6" sm="12" xs="12" v-for="(item, index) in items" :key="index">
      <CommitmentCardItem
        :item="item"
        :epics="epicList"
        :activities="activityList"
        :technologies="technologyList"
        @fetchTechnologies="fetchTechnologies"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { UICommitment } from "shared-components/src/models/Commitment";
import CommitmentCardItem from "./CommitmentCardItem.vue";
import { Activity } from "shared-components/src/models/Activity";
import { Technology } from "shared-components/src/models/Technology";
import ActivityService from "shared-components/src/services/ActivityService";
import TechnologyService from "shared-components/src/services/TechnologyService";
import Epic from "shared-components/src/models/Epic";
import EpicService from "@/services/EpicService";

export default defineComponent({
  props: {
    items: {
      type: Array as () => UICommitment[],
      required: true,
    },
  },
  data() {
    return {
      activityList: [] as Activity[],
      technologyList: [] as Technology[],
      epicList: {},
    };
  },
  async mounted() {
    await this.fetchActivities();
    await this.fetchTechnologies();
    await this.fetchEpics();
  },
  methods: {
    async fetchTechnologies(): Promise<void> {
      this.technologyList = await TechnologyService.getTechnologyList();
      this.sortTechnologies();
    },
    async fetchActivities(): Promise<void> {
      this.activityList = await ActivityService.getActivityList();
    },
    async fetchEpics(): Promise<void> {
      var pIds = {};
      var epics = {};
      var promises = [] as Promise<void | Epic>[];
      this.items.forEach((item) =>
        item.ProjectIds.forEach((pId) => {
          if (!(pIds as any)[pId]) {
            (pIds as any)[pId] = true;
            promises.push(
              EpicService.getEpicOfProject(pId || "").then((v) => {
                if (v) {
                  (epics as any)[v.ProjectId] = v;
                }
              })
            );
          }
        })
      );
      await Promise.all(promises);
      this.epicList = epics;
    },
    sortTechnologies() {
      this.technologyList.sort((a, b) => {
        if (a.Value < b.Value) {
          return -1;
        }
        if (a.Value > b.Value) {
          return 1;
        }
        return 0;
      });
    },
  },
  components: { CommitmentCardItem },
});
</script>
