import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list_group = _resolveComponent("v-list-group")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_list = _resolveComponent("v-list")!

  return (_openBlock(), _createBlock(_component_v_list, {
    class: "pt-0 pl-0 main-menu-items",
    "open-strategy": "single",
    slim: ""
  }, {
    default: _withCtx(() => [
      (!!_ctx.isAuthenticated && _ctx.profileCompleted)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_ctx.commitments && _ctx.commitments.length > 0)
              ? (_openBlock(), _createBlock(_component_v_list_item, {
                  key: 0,
                  to: "/team-member/dashboard",
                  "prepend-icon": "mdi-view-dashboard",
                  title: "Dashboard"
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_v_list_item, {
              to: "/team-member/profile",
              "prepend-icon": "mdi-account",
              title: "Profile",
              class: "profile-item"
            }),
            _createVNode(_component_v_list_group, { value: "traning" }, {
              activator: _withCtx(({ props }) => [
                _createVNode(_component_v_list_item, _mergeProps(props, {
                  "prepend-icon": "mdi-book",
                  title: "My Training"
                }), null, 16)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_v_list_item, {
                  to: "/team-member/training/roadmaps",
                  "prepend-icon": "mdi-school",
                  title: "My Roadmaps"
                }),
                _createVNode(_component_v_list_item, {
                  to: "/team-member/training/courses",
                  "prepend-icon": "mdi-book-outline",
                  title: "Courses"
                }),
                _createVNode(_component_v_list_item, {
                  to: "/team-member/training/assessments",
                  "prepend-icon": "mdi-school-outline",
                  title: "Assessments"
                })
              ]),
              _: 1
            }),
            (_ctx.commitments && _ctx.commitments.length > 0)
              ? (_openBlock(), _createBlock(_component_v_list_item, {
                  key: 1,
                  to: "/team-member/timesheet",
                  "prepend-icon": "mdi-history",
                  title: "Timesheet"
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_v_list_group, null, {
              activator: _withCtx(({ props }) => [
                _createVNode(_component_v_list_item, _mergeProps(props, {
                  "prepend-icon": "mdi-cash",
                  title: "Billing"
                }), null, 16)
              ]),
              default: _withCtx(() => [
                (_ctx.showPayrunPage)
                  ? (_openBlock(), _createBlock(_component_v_list_item, {
                      key: 0,
                      to: "/team-member/pay-runs",
                      "prepend-icon": "mdi-account-cash",
                      title: "Pay Runs"
                    }))
                  : _createCommentVNode("", true),
                (_ctx.showPayrunPage)
                  ? (_openBlock(), _createBlock(_component_v_list_item, {
                      key: 1,
                      to: "/team-member/loans",
                      "prepend-icon": "mdi-cash-multiple",
                      title: "Loans"
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_v_list_item, {
                  to: "/team-member/paymentMethods",
                  "prepend-icon": "mdi-cash-refund",
                  title: "Payment Method"
                })
              ]),
              _: 1
            }),
            (_ctx.hasActiveCommitment)
              ? (_openBlock(), _createBlock(_component_v_list_item, {
                  key: 2,
                  to: "/team-member/repositories",
                  "prepend-icon": "mdi-source-repository",
                  title: "Repositories"
                }))
              : _createCommentVNode("", true),
            (_ctx.hasActiveCommitment)
              ? (_openBlock(), _createBlock(_component_v_list_item, {
                  key: 3,
                  to: "/team-member/commitments",
                  "prepend-icon": "mdi-handshake",
                  title: "My Commitments"
                }))
              : _createCommentVNode("", true),
            (!!_ctx.isLinemanager)
              ? (_openBlock(), _createBlock(_component_v_list_item, {
                  key: 4,
                  to: "/team-member/access/list",
                  "prepend-icon": "mdi-server-security",
                  title: "Accesses"
                }))
              : _createCommentVNode("", true),
            (!!_ctx.isLinemanager)
              ? (_openBlock(), _createBlock(_component_v_list_item, {
                  key: 5,
                  to: "/team-member/reports/timesheet-report",
                  "prepend-icon": "mdi-chart-arc",
                  title: "Timesheets Report"
                }))
              : _createCommentVNode("", true),
            (!!_ctx.isLinemanager)
              ? (_openBlock(), _createBlock(_component_v_list_item, {
                  key: 6,
                  to: "/team-member/weekly-timesheet-audit",
                  "prepend-icon": "mdi-chart-bar",
                  title: "Weekly Timesheet Audit"
                }))
              : _createCommentVNode("", true),
            (!!_ctx.isLinemanager)
              ? (_openBlock(), _createBlock(_component_v_list_item, {
                  key: 7,
                  to: "/team-member/authors",
                  "prepend-icon": "mdi-git",
                  title: "Authors Management"
                }))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true),
      _withDirectives(_createVNode(_component_v_divider, null, null, 512), [
        [_vShow, _ctx.appMenu.length > 0]
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appMenu, (item, index) => {
        return (_openBlock(), _createBlock(_component_v_list_item, {
          key: index,
          to: item.to,
          "prepend-icon": item.icon,
          title: item.text
        }, null, 8, ["to", "prepend-icon", "title"]))
      }), 128))
    ]),
    _: 1
  }))
}