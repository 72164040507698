import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withKeys as _withKeys, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_new_access = _resolveComponent("new-access")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_confirm_dialog = _resolveComponent("confirm-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, { "no-gutters": "" }, {
              default: _withCtx(() => [
                _cache[10] || (_cache[10] = _createTextVNode(" Access List ")),
                _createVNode(_component_v_divider, {
                  class: "mx-4",
                  inset: "",
                  vertical: ""
                }),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  disabled: !_ctx.hasAction,
                  class: "mr-5 mb-2 success action-btn",
                  onClick: _ctx.applyAction
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                        _createTextVNode("mdi-check")
                      ])),
                      _: 1
                    }),
                    _cache[7] || (_cache[7] = _createTextVNode("Apply Actions"))
                  ]),
                  _: 1
                }, 8, ["disabled", "onClick"]),
                _createVNode(_component_v_btn, {
                  color: "primary_btn_v2",
                  dark: "",
                  class: "mb-2 action-btn",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showNewItem = true))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createTextVNode("mdi-plus")
                      ])),
                      _: 1
                    }),
                    _cache[9] || (_cache[9] = _createTextVNode(" New Access "))
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_dialog, {
                  modelValue: _ctx.showNewItem,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showNewItem) = $event)),
                  persistent: "",
                  "max-width": "600",
                  onKeydown: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.showNewItem = false), ["esc"]))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_new_access, {
                      loading: _ctx.createLoading,
                      teammembers: _ctx.teammemberList,
                      projects: _ctx.projectList.items,
                      onFetchProjects: _ctx.fetchProjects,
                      onFetchTeammembers: _ctx.fetchTeammembers,
                      onCancel: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showNewItem = false)),
                      onSave: _ctx.accessAdded,
                      onShowError: _ctx.showError
                    }, null, 8, ["loading", "teammembers", "projects", "onFetchProjects", "onFetchTeammembers", "onSave", "onShowError"])
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_card_text, { class: "access-body" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_data_table, {
              items: _ctx.filteredDesserts,
              headers: _ctx.headers,
              loading: _ctx.pageLoading
            }, {
              "item.data.GrantedOn": _withCtx(({ item }) => [
                _createTextVNode(_toDisplayString(_ctx.formatDate(item.data.GrantedOn)), 1)
              ]),
              "item.data.RevokedOn": _withCtx(({ item }) => [
                _createTextVNode(_toDisplayString(_ctx.formatDate(item.data.RevokedOn)), 1)
              ]),
              "item.Actions": _withCtx(({ item }) => [
                _createVNode(_component_v_select, {
                  disabled: _ctx.getAvailableActions(item).length === 0,
                  "append-icon": "mdi-settings",
                  style: {"width":"200px"},
                  items: _ctx.getAvailableActions(item),
                  label: item.action ? 'Action' : 'No Action',
                  clearable: "",
                  "clear-icon": "mdi-close",
                  modelValue: item.action,
                  "onUpdate:modelValue": ($event: any) => ((item.action) = $event),
                  outlined: "",
                  class: "mt-5"
                }, null, 8, ["disabled", "items", "label", "modelValue", "onUpdate:modelValue"])
              ]),
              _: 1
            }, 8, ["items", "headers", "loading"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showConfirm,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showConfirm) = $event)),
      "max-width": "500",
      onKeydown: _cache[5] || (_cache[5] = _withKeys(($event: any) => (_ctx.showConfirm = false), ["esc"]))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_confirm_dialog, {
          loading: _ctx.loading,
          message: _ctx.confirmModel,
          onConfirm: _ctx.confirmDialog,
          onCancel: _ctx.cancelDialog
        }, null, 8, ["loading", "message", "onConfirm", "onCancel"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}