<template>
  <v-card>
    <v-card-title>
      <v-row no-gutters>
        Access List
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-btn :disabled="!hasAction" class="mr-5 mb-2 success action-btn" @click="applyAction"><v-icon>mdi-check</v-icon>Apply Actions</v-btn>
        <v-btn color="primary_btn_v2" dark class="mb-2 action-btn" @click="showNewItem = true"> <v-icon>mdi-plus</v-icon> New Access </v-btn>
        <v-dialog v-model="showNewItem" persistent max-width="600" @keydown.esc="showNewItem = false">
          <new-access
            :loading="createLoading"
            :teammembers="teammemberList"
            :projects="projectList.items"
            @fetchProjects="fetchProjects"
            @fetchTeammembers="fetchTeammembers"
            @cancel="showNewItem = false"
            @save="accessAdded"
            @showError="showError"
          ></new-access>
        </v-dialog>
      </v-row>
    </v-card-title>
    <v-card-text class="access-body">
      <v-data-table :items="filteredDesserts" :headers="headers" :loading="pageLoading">
        <template v-slot:item.data.GrantedOn="{ item }">
          {{ formatDate(item.data.GrantedOn) }}
        </template>
        <template v-slot:item.data.RevokedOn="{ item }">
          {{ formatDate(item.data.RevokedOn) }}
        </template>
        <template v-slot:item.Actions="{ item }">
          <v-select
            :disabled="getAvailableActions(item).length === 0"
            append-icon="mdi-settings"
            style="width: 200px"
            :items="getAvailableActions(item)"
            :label="item.action ? 'Action' : 'No Action'"
            clearable
            clear-icon="mdi-close"
            v-model="item.action"
            outlined
            class="mt-5"
          ></v-select>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
  <v-dialog v-model="showConfirm" max-width="500" @keydown.esc="showConfirm = false">
    <confirm-dialog :loading="loading" :message="confirmModel" @confirm="confirmDialog" @cancel="cancelDialog"></confirm-dialog>
  </v-dialog>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Access from "shared-components/src/models/Access";
import Utils from "shared-components/src/utils/Utils";
import NewAccess from "./NewAccess.vue";
import ConfirmDialog from "shared-components/src/components/ConfirmDialog.vue";

type actions = "grant" | "revoke" | "send grant request" | "send revoke request";

interface DessertItem {
  data: Access;
  action: actions | null;
}

export default defineComponent({
  props: ["desserts", "loading", "projects", "teammembers", "cLoading"],
  components: {
    NewAccess,
    ConfirmDialog,
  },
  data() {
    return {
      createLoading: false,
      totalDesserts: 0,
      teammemberList: [] as Array<{ id: string; Name: string }>,
      dessertList: [] as DessertItem[],
      pageLoading: true,
      showNewItem: false,
      showConfirm: false,
      projectList: {
        loading: true,
        items: [] as Array<{ id: string; Name: string }>,
      },
      selectedProjects: [] as Array<{ id: string; Name: string }>,
      headers: [
        { title: "Project", align: "start", value: "data.Project.Name" },
        { title: "Team Member", value: "data.TeamMember.Name" },
        { title: "Tool", value: "data.Tool" },
        { title: "Email", value: "data.Email" },
        { title: "Url", value: "data.Url" },
        { title: "Notes", sortable: false, value: "data.Notes" },
        { title: "Granted on", value: "data.GrantedOn" },
        { title: "Revoked on", value: "data.RevokedOn" },
        { title: "Actions", sortable: false, value: "Actions" },
      ],
      confirmModel: {
        title: "",
        text: "",
      },
    };
  },
  async mounted() {
    await this.fetchAccess();
    await this.fetchProjects();
  },
  watch: {
    options: {
      async handler() {
        await this.fetchAccess();
      },
      deep: true,
    },
    "projects.items"(newVal) {
      this.projectList.items = newVal;
      this.projectList.loading = false;
    },
    cLoading(newVal) {
      this.createLoading = newVal;
    },
    loading(newVal) {
      this.pageLoading = newVal;
    },
    desserts(newVal) {
      this.dessertList = newVal;
      this.showNewItem = false;
    },
    teammembers(newVal) {
      this.teammemberList = newVal;
    },
  },
  methods: {
    fetchTeammembers(): void {
      this.$emit("fetchTeammembers");
    },
    showError(msg: string): void {
      this.$emit("showError", msg);
    },
    async fetchProjects(): Promise<void> {
      this.projectList.loading = true;
      await this.$emit("fetchProjects");
    },
    getAvailableActions(item: DessertItem): string[] {
      if (!item.data.GrantedOn) {
        return ["grant", "send grant request"];
      } else if (!item.data.RevokedOn) {
        return ["revoke", "send revoke request"];
      } else {
        return [];
      }
    },
    applyAction(): void {
      this.confirmModel.title = "Confirm";
      this.confirmModel.text = "<strong>Are you sure about flowing actions?</strong>";
      if (this.grantingItems.length) {
        this.confirmModel.text += `<br>${this.grantingItems.length} item(s) Granting.`;
      }
      if (this.revokingItems.length) {
        this.confirmModel.text += `<br>${this.revokingItems.length} item(s) Revoking.`;
      }
      if (this.notifyingItems.length) {
        this.confirmModel.text += `<br>${this.notifyingItems.length} item(s) Notifying.`;
      }
      this.showConfirm = true;
    },
    async fetchAccess(): Promise<void> {
      this.pageLoading = true;
      await this.$emit("fetchAccess");
    },
    formatDate(date: Date | null): string {
      if (!date) {
        return "";
      } else {
        return `${Utils.toVsDateFormat(date)}  ${date.getHours()}:${date.getMinutes()}`;
      }
    },
    async accessAdded($model: any): Promise<void> {
      await this.$emit("accessAdded", $model);
    },
    async confirmDialog() {
      this.showConfirm = false;
      this.$emit("revokeAndGrant");
      if (this.notifyingItems.length) {
        this.$emit(
          "notify",
          this.notifyingItems.map((x) => x.data)
        );
      }
    },
    cancelDialog() {
      this.showConfirm = false;
    },
  },
  computed: {
    grantingItems(): DessertItem[] {
      return this.dessertList.filter((x) => x.action === "grant");
    },
    revokingItems(): DessertItem[] {
      return this.dessertList.filter((x) => x.action === "revoke");
    },

    filteredDesserts(): DessertItem[] {
      if (this.selectedProjects.length) {
        return this.dessertList.filter((dessert) => {
          if (dessert.data.Project && dessert.data.Project.id) {
            return this.selectedProjects.findIndex((x) => x.id === dessert.data.Project.id) > -1;
          } else {
            return false;
          }
        });
      } else {
        return this.dessertList;
      }
    },
    hasAction(): boolean {
      return this.dessertList.findIndex((x) => x.action) > -1;
    },
    notifyingItems(): DessertItem[] {
      return this.dessertList.filter((x) => x.action === "send grant request" || x.action === "send revoke request");
    },
  },
});
</script>

<style scoped>
@media (max-width: 581px) {
  .access-body {
    padding-top: 120px;
  }
  .action-btn {
    margin-top: 15px;
    width: 100%;
  }
}
</style>
