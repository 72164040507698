import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flip-box" }
const _hoisted_2 = { class: "flip-box-inner" }
const _hoisted_3 = { class: "flip-box-front" }
const _hoisted_4 = { class: "roadmap-details" }
const _hoisted_5 = { class: "roadmap-title" }
const _hoisted_6 = { class: "roadmap-info" }
const _hoisted_7 = { class: "details-section" }
const _hoisted_8 = { class: "information" }
const _hoisted_9 = { class: "information" }
const _hoisted_10 = { class: "information" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { class: "status-section" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { class: "flip-box-back" }
const _hoisted_16 = { class: "roadmap-details" }
const _hoisted_17 = { class: "roadmap-title" }
const _hoisted_18 = { class: "roadmap-info" }
const _hoisted_19 = { class: "details-section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, { cols: "5" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_text_field, {
              modelValue: _ctx.searchTitle,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchTitle) = $event)),
              onKeyup: _withKeys(_ctx.loadRoadMaps, ["enter"]),
              placeholder: "Search For...",
              outlined: "",
              "onClick:appendInner": _ctx.loadRoadMaps,
              "hide-details": ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, {
                  color: "black",
                  onClick: _ctx.loadRoadMaps,
                  class: "clickable-icon"
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode(" mdi-magnify ")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }, 8, ["modelValue", "onKeyup", "onClick:appendInner"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_v_col, {
              key: 0,
              cols: "12",
              class: "loading-container"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_progress_circular, {
                  color: "primary",
                  indeterminate: "",
                  size: 80
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" Loading")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (!_ctx.isLoading)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.roadMaps, (item) => {
              return (_openBlock(), _createBlock(_component_v_col, {
                key: item.Id,
                cols: "12",
                sm: "6",
                md: "4",
                lg: "3"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        _createVNode(_component_v_card, {
                          class: _normalizeClass(["project-card", { 'started-card': item.StartedByTM }]),
                          onClick: ($event: any) => (_ctx.navigateToRoadmap(item))
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_4, [
                              _createElementVNode("div", _hoisted_5, _toDisplayString(item.Title), 1),
                              _createElementVNode("div", _hoisted_6, [
                                _createElementVNode("div", _hoisted_7, [
                                  _createElementVNode("div", null, [
                                    _createVNode(_component_v_icon, null, {
                                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                                        _createTextVNode("mdi-subtitles-outline")
                                      ])),
                                      _: 1
                                    }),
                                    _createTextVNode(" " + _toDisplayString(_ctx.truncate(item.Description)), 1)
                                  ]),
                                  _createElementVNode("div", _hoisted_8, [
                                    _createVNode(_component_v_icon, { class: "pr-1" }, {
                                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                                        _createTextVNode("mdi-book-open-page-variant")
                                      ])),
                                      _: 1
                                    }),
                                    _createElementVNode("span", null, _toDisplayString(_ctx.getSteps(item)), 1)
                                  ]),
                                  _createElementVNode("div", _hoisted_9, [
                                    _createVNode(_component_v_icon, null, {
                                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                                        _createTextVNode("mdi-timer-sand-empty")
                                      ])),
                                      _: 1
                                    }),
                                    _createElementVNode("span", null, _toDisplayString(_ctx.formatDuration(item.Duration)), 1)
                                  ]),
                                  _createElementVNode("div", _hoisted_10, [
                                    _createVNode(_component_v_icon, null, {
                                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                                        _createTextVNode("mdi-account")
                                      ])),
                                      _: 1
                                    }),
                                    _createElementVNode("span", null, _toDisplayString(item.Talents.length), 1)
                                  ]),
                                  _createElementVNode("div", null, [
                                    (item.Tags)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                          _createVNode(_component_v_icon, { class: "pr-1" }, {
                                            default: _withCtx(() => _cache[7] || (_cache[7] = [
                                              _createTextVNode("mdi-tag-outline")
                                            ])),
                                            _: 1
                                          }),
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.Tags, (tag, index) => {
                                            return (_openBlock(), _createBlock(_component_v_chip, {
                                              key: index,
                                              outlined: ""
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(tag), 1)
                                              ]),
                                              _: 2
                                            }, 1024))
                                          }), 128))
                                        ]))
                                      : _createCommentVNode("", true)
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_12, [
                                  (item.StartedByTM)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                        _createVNode(_component_v_icon, { class: "icon-color" }, {
                                          default: _withCtx(() => _cache[8] || (_cache[8] = [
                                            _createTextVNode(" mdi-check")
                                          ])),
                                          _: 1
                                        }),
                                        _cache[9] || (_cache[9] = _createElementVNode("span", { class: "pl-2 icon-color" }, "Started", -1))
                                      ]))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                        _createVNode(_component_v_icon, { class: "notStarted" }, {
                                          default: _withCtx(() => _cache[10] || (_cache[10] = [
                                            _createTextVNode("mdi-close")
                                          ])),
                                          _: 1
                                        }),
                                        _cache[11] || (_cache[11] = _createElementVNode("span", { class: "pl-2 notStarted" }, "Not Started", -1))
                                      ])),
                                  _createElementVNode("div", null, [
                                    _createVNode(_component_v_progress_circular, {
                                      class: "mr-5 mb-2",
                                      rotate: -90,
                                      size: 50,
                                      width: 5,
                                      value: item.value,
                                      color: "#4caf50"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(item.ProcessPercent), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["value"])
                                  ])
                                ])
                              ])
                            ])
                          ]),
                          _: 2
                        }, 1032, ["class", "onClick"])
                      ]),
                      _createElementVNode("div", _hoisted_15, [
                        _createVNode(_component_v_card, {
                          class: _normalizeClass(["project-card", { 'started-card': item.StartedByTM }]),
                          onClick: ($event: any) => (_ctx.navigateToRoadmap(item))
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_16, [
                              _createElementVNode("div", _hoisted_17, _toDisplayString(item.Title), 1),
                              _createElementVNode("div", _hoisted_18, [
                                _createElementVNode("div", _hoisted_19, [
                                  _createElementVNode("div", null, [
                                    _createVNode(_component_v_icon, null, {
                                      default: _withCtx(() => _cache[12] || (_cache[12] = [
                                        _createTextVNode("mdi-subtitles-outline")
                                      ])),
                                      _: 1
                                    }),
                                    _createTextVNode(" " + _toDisplayString(item.Description), 1)
                                  ])
                                ])
                              ])
                            ])
                          ]),
                          _: 2
                        }, 1032, ["class", "onClick"])
                      ])
                    ])
                  ])
                ]),
                _: 2
              }, 1024))
            }), 128))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}