import ApiService from "./ApiService";
import CourseModel from "shared-components/src/models/CourseModel";
import FindCoursesRequest from "shared-components/src/models/requests/FindCoursesRequest";

export default class CourseService {
  public static async UpdateCourseStepState(courseId: string, isComplete: boolean, stepId: string, roadMapId?: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/course/${courseId}/step/updateState`, { IsComplete: isComplete, StepId: stepId, RoadMapId: roadMapId })
        .then((result) => {
          if (!result.data as boolean) {
            reject("");
          } else {
            resolve(result.data as boolean);
          }
        })
        .catch((error) => reject(error));
    });
  }

  public static async GetList(criteria: FindCoursesRequest | null = null): Promise<CourseModel[]> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/tmCourse/find`, criteria ? criteria : ({} as FindCoursesRequest))
        .then((result) => {
          if (!result.data) {
            resolve([] as CourseModel[]);
          } else {
            var getCourses = result.data as CourseModel[];
            resolve(getCourses);
          }
        })
        .catch((error) => reject(error));
    });
  }

  public static async GetById(courseId: string): Promise<CourseModel> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/tmCourse/${courseId}`, "")
        .then((result) => {
          if (!result.data) {
            resolve({} as CourseModel);
          } else {
            var getCourses = result.data as CourseModel;
            resolve(getCourses);
          }
        })
        .catch((error) => reject(error));
    });
  }
}
