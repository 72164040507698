<template>
  <div id="profileSetupTab">
    <v-row>
      <v-col md="12" sm="12" cols="12">
        <h1>Set up your professional profile</h1>
      </v-col>

      <v-col md="4" sm="12" cols="12">
        <UploadProfilePhoto :personalDetail="personalDetail" @setImage="setImage" :isFileValid="validFile" />
      </v-col>
      <v-col md="5" sm="12" cols="12">
        <UploadResume :personalDetail="personalDetail" :isFileValid="validResume" @setResume="setResume" />
      </v-col>
      <v-col md="12" sm="12" cols="12">
        <v-form ref="frmProfileDetail" lazy-validation>
          <TextAreaField
            counter
            placeholder="Write a brief summary of your professional experience"
            :rules="[validations.required, validations.minCharDesc]"
            v-model="description"
            showRequired
            outlined
            label="Bio (About Me)"
          ></TextAreaField>
        </v-form>
      </v-col>
    </v-row>
    <v-card-actions v-if="isAfterSignUp" class="mt-5">
      <v-col md="12" class="text-center">
        <v-col class="text-center pb-0 pt-0">
          <p>You are done. you can start your personalized screening experience.</p>
        </v-col>
        <div class="wizard-action">
          <v-btn class="primary_btn_v2" @click="goBack()" :loading="submitting" :disabled="submitting"><v-icon>mdi mdi-arrow-left</v-icon>Back</v-btn>
          <v-tooltip top attach>
            <template v-slot:activator="{ props }">
              <v-btn class="secondary_btn" v-bind="props" @click="submitForm(true)" :disabled="submitting" :loading="submitting"
                ><v-icon>mdi mdi-check</v-icon>Save for later</v-btn
              >
            </template>
            <span>You can save your progress and return to the application process later</span>
          </v-tooltip>
          <v-btn class="primary_btn_v2" @click="submitForm()" :loading="submitting" :disabled="submitting"
            ><v-icon>mdi mdi-check</v-icon>Finish</v-btn
          >
        </div>
      </v-col>
    </v-card-actions>
    <v-dialog v-model="showSaveForLaterConfirm" max-width="500" @keydown.esc="showSaveForLaterConfirm = false">
      <confirm-dialog :loading="submitting" :message="confirmModel" @confirm="save(true)" @cancel="cancelSaving"></confirm-dialog>
    </v-dialog>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import TeamMemberService from "shared-components/src/services/TeamMemberService";
import UploadProfilePhoto from "shared-components/src/components/Profile/UploadProfilePhoto.vue";
import validations from "shared-components/src/utils/validations";
import UploadResume from "shared-components/src/components/Profile/UploadResume.vue";
import { PersonalDetailModel } from "shared-components/src/models/PersonalDetailModel";
import ConfirmDialog from "shared-components/src/components/ConfirmDialog.vue";

export default defineComponent({
  props: ["teammemberModel", "isAfterSignUp", "loading"],
  components: {
    UploadProfilePhoto,
    UploadResume,
    ConfirmDialog,
  },
  data() {
    return {
      validations,
      downloading: false,
      validFile: true,
      validResume: true,
      submitting: false,
      personalDetail: null as PersonalDetailModel | null,
      resumeFile: null as File | null,
      description: "",
      uploadImage: false,
      confirmModel: {
        title: "",
        text: "",
      },
      showSaveForLaterConfirm: false,
    };
  },
  watch: {
    loading(newVal) {
      this.submitting = newVal;
    },
    teammemberModel(newVal) {
      this.personalDetail = { ...newVal };
      this.setUi();
    },
  },
  mounted() {
    this.personalDetail = {...this.teammemberModel};
    this.setUi();
  },
  methods: {
    setUi(){
      if (this.personalDetail?.PhotoUrl) {
        this.validFile = true;
      }
      if (this.personalDetail?.CV) {
        this.validResume = true;
      }
      if (this.personalDetail?.Description) {
        this.description = this.personalDetail?.Description;
      }
    },
    cancelSaving() {
      this.showSaveForLaterConfirm = false;
      this.submitting = false;
    },
    setResume(resume: any) {
      this.resumeFile = resume;
    },
    async submitForm(isForLater = false) {
      if (isForLater) {
        this.confirmModel.title = `Save Progress for Later?`;
        this.confirmModel.text =
          "You're almost ready to move on to your personalized screening experience. Are you sure you want to save your progress for later instead?";
        this.showSaveForLaterConfirm = true;
      } else {
        const isValid = await (this.$refs.frmProfileDetail as any).validate();
        this.validFile = this.personalDetail?.PhotoUrl ? true : false;
        this.validResume = this.resumeFile || this.personalDetail?.CV ? true : false;
        if (isValid.valid && this.validFile && this.validResume) {
          this.save();
        } else {
          var el = document.querySelectorAll("#profileSetupTab .error-border:first-of-type, #profileSetupTab .v-messages.error--text:first-of-type");
          this.$emit("scrollToElement", el[0]);
        }
      }
    },
    async save(isForLater = false) {
      this.submitting = true;
      if (this.personalDetail) {
        if (this.resumeFile) {
          this.personalDetail.CV = await TeamMemberService.uploadResume(this.resumeFile);
        }
        this.personalDetail.Description = this.description;
        this.$emit("submitForm", {
          tm: this.personalDetail,
          isForLator: isForLater,
          isLastStep: true
        });
      }
      this.cancelSaving();
    },
    goBack() {
      this.$emit("goBack", 4);
    },
    setImage(data: any) {
      if (this.personalDetail) {
        this.personalDetail.PhotoUrl = data;
      }
    },
  },
});
</script>
