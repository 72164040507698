import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "code-editor-container" }
const _hoisted_2 = { class: "code-editor-header" }
const _hoisted_3 = { class: "code-editor-header-question" }
const _hoisted_4 = { class: "code-editor-header-action" }
const _hoisted_5 = { class: "editor" }
const _hoisted_6 = {
  key: 1,
  class: "pane output-panel"
}
const _hoisted_7 = {
  ref: "output",
  class: "editor-output"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_CodeEditor = _resolveComponent("CodeEditor")!
  const _component_MultiplePanelResizer = _resolveComponent("MultiplePanelResizer")!
  const _component_MultiplePanelHorizontal = _resolveComponent("MultiplePanelHorizontal")!
  const _component_MultiplePanelVertical = _resolveComponent("MultiplePanelVertical")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.Question?.Question), 1),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.showExecute)
          ? (_openBlock(), _createBlock(_component_v_btn, {
              key: 0,
              onClick: _ctx.execute
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Execute")
              ])),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true),
        _createVNode(_component_v_btn, { onClick: _ctx.submit }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Submit")
          ])),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _createVNode(_component_MultiplePanelVertical, {
      class: "custom-resizer",
      layout: "vertical"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: "pane editors-panel",
          style: _normalizeStyle({ height: _ctx.showExecute == true ? 'unset' : '100%' })
        }, [
          _createVNode(_component_MultiplePanelHorizontal, {
            class: "custom-code-resizer",
            layout: "horizontal"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.editorValues, (item, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, null, [
                  _createElementVNode("div", {
                    class: "pane",
                    style: _normalizeStyle({ width: 100 / _ctx.editorValues.length + '%' })
                  }, [
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode(_component_CodeEditor, {
                        mode: item.mode,
                        code: item.value,
                        "onUpdate:code": ($event: any) => ((item.value) = $event)
                      }, null, 8, ["mode", "code", "onUpdate:code"])
                    ])
                  ], 4),
                  _createVNode(_component_MultiplePanelResizer, { layout: "h" })
                ], 64))
              }), 256))
            ]),
            _: 1
          })
        ], 4),
        (_ctx.showExecute)
          ? (_openBlock(), _createBlock(_component_MultiplePanelResizer, {
              key: 0,
              layout: "v"
            }))
          : _createCommentVNode("", true),
        (_ctx.showExecute)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, null, 512)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}