<template>
  <v-form ref="frmLogin" :id="formId" @submit.prevent="submit" class="form-inputs">
    <v-row>
      <v-col md="12" sm="12" lg="12">
        <TextField
          outlined
          v-model="model.userName"
          prepend-icon="mdi-account"
          label="User Name"
          placeholder="User Name"
          type="text"
          class="login-field"
          :rules="[validations.required]"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" lg="12">
        <TextField
          outlined
          v-model="model.password"
          prepend-icon="mdi-key"
          label="Password"
          placeholder="Password"
          type="password"
          class="login-field"
          :rules="[validations.required]"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="more-options text-right pt-0 pb-0">
        <router-link to="/register/reset-password">Reset password</router-link>
      </v-col>
    </v-row>
  </v-form>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import UserService from "../../services/UserService";
import TeamMemberService from "../../services/TeamMemberService";
import UserInfo from "shared-components/src/models/UserInfo";
import { Teammember } from "shared-components/src/models/Teammember";
import validations from "shared-components/src/utils/validations";
import store from "@/store";

export default defineComponent({
  props: {
    formId: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      validations,
      model: {
        userName: localStorage.username || "",
        password: localStorage.password || "",
      },
      rememberMe: localStorage.rememberme === "true" ? true : false,
    };
  },
  methods: {
    setUserInfo(userInfo: UserInfo) {
      return store.dispatch("setUserInfo", { userInfo, vm: this });
    },
    setTeamMemberInfo(teamMemberInfo: Teammember) {
      return store.dispatch("setTeamMemberInfo", { teamMemberInfo, vm: this });
    },
    async submit() {
      const isValid = await (this.$refs.frmLogin as any).validate();
      if (isValid.valid) {
        const values = this.model;
        if (values.userName) {
          values.userName = values.userName.toLowerCase().trim();
        }

        localStorage.rememberme = this.rememberMe;

        this.$emit("submitting", true);
        try {
          const userInfo = await UserService.login(values);
          if (userInfo.id) {
            this.setUserInfo(userInfo);
            try {
              const setPushToken = inject("setPushToken", () => {});
              setPushToken();
            } catch (error: any) {
              console.log("getting fierbase token error: " + error.message);
            }

            try {
              const teamMemberInfo = await TeamMemberService.getTeammemberByEmail(userInfo.email || "");
              if (teamMemberInfo) {
                this.setTeamMemberInfo(teamMemberInfo);
              }
            } catch (err: any) {
              store.dispatch("showErrorMessage", err.message || err.toStrting());
            }

            this.$emit("success", userInfo);
          } else {
            store.dispatch("showErrorMessage", "Wrong Email or Password");
          }
        } catch (error: any) {
          store.dispatch("showErrorMessage", error.response.data.message || error.toStrting());
        } finally {
          this.$emit("submitting", false);
        }
      }
    },
  },
});
</script>

<style scoped>
@media screen and (min-width: 800px) {
  .login-field {
    max-width: 600px;
  }
}
</style>
