<template>
  <v-card>
    <v-card-title> Loan Request </v-card-title>
    <v-card-text>
      <v-form ref="frmRegister" v-model="valid" lazy-validation class="form-inputs">
        <v-row>
          <v-col cols="auto" md="4" sm="12">
            <CurrencyTextField
              :rules="[rules.required, rules.currency]"
              dense
              outlined
              label="Amount AUD"
              v-model="model.Amount"
              :readonly="isReadOnly"
            />
          </v-col>
          <v-col cols="auto" md="4" sm="12">
            <TextField
              :rules="[rules.required, rules.int, isValidInstallments]"
              outlined
              placeholder="Number Of Installments"
              label="Number Of Installments"
              v-model="model.Installment"
            />
          </v-col>
          <v-col cols="12" md="4" sm="12">
            <v-menu ref="startMenu" v-model="startMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
              <template v-slot:activator="{ props }">
                <label class="input-label"> Requested Start Date </label>
                <v-text-field
                  :rules="[rules.required]"
                  v-model="formatedProposedDate"
                  placeholder="Requested Start Date"
                  readonly
                  outlined
                  v-bind="props"
                ></v-text-field>
              </template>
              <v-date-picker
                first-day-of-week="1"
                ref="picker"
                v-model="model.ProposedPaymentDate"
                :min="minDate"
                @update:modelValue="startMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <TextAreaField v-model="model.Description" outlined label="Description" placeholder="Description" />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-col md="12">
        <v-btn color="secondary" class="mr-10" @click="save" :loading="loading"><v-icon>mdi-check</v-icon> Save</v-btn>
        <v-btn text color="primary" @click="cancel"> cancel</v-btn>
      </v-col>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Utils from "shared-components/src/utils/Utils";
import { LoanStatus, LoanVM } from "shared-components/src/models/Loan";

export default defineComponent({
  props: ["id", "loading"],
  data() {
    return {
      valid: true,
      startMenu: false,
      endMenu: false,
      aaaa: "",
      model: {
        id: "",
        Amount: 0,
        Installment: 0,
        Description: "",
        ProposedPaymentDate: new Date(),
      } as LoanVM,
      rules: {
        required: (value: any) => (!(value instanceof Array) && !!value) || (value instanceof Array && value.length > 0) || "Required.",
        int: (v: any) => !v || /^[1-9][0-9]?$/gi.test(v) || "Number bigger than zero and less than 99 is valid",
        currency: (v: any) => !v || /^(\-|\+)?[0-9,]+(.[0-9]+)?$/gi.test(v) || "Number bigger than zero is valid",
      },
    };
  },
  methods: {
    resetModel(): void {
      this.model = {
        id: "",
        Amount: 0,
        Installment: 0,
        Description: "",
        TeamMemberId: "",
        ProposedPaymentDate: new Date(),
        TeamMemberName: "",
        Status: LoanStatus.Pending,
      };
    },
    formatNumber(value: number): string {
      return Utils.moneyFormat(value);
    },
    setAmount($event: any): void {
      this.model.Amount = Utils.currencyToNumber($event);
    },
    cancel(): void {
      this.resetModel();
      this.$emit("cancel");
    },
    async save(): Promise<void> {
      const isValid = await (this.$refs.frmRegister as any).validate();
      if (isValid.valid && this.isValidInstallments) {
        this.$emit("upsert", this.model);
      }
    },
  },
  computed: {
    installmentAmountPerMonth(): number {
      if (this.model.Installment) {
        const instPerMonth = (this.model.Amount / this.model.Installment).toString();
        return parseFloat(Number.parseFloat(instPerMonth).toFixed(2));
      }
      return 0;
    },
    formatedProposedDate() {
      return this.model.ProposedPaymentDate ? Utils.toVsDateFormat(this.model.ProposedPaymentDate) : null;
    },
    isValidInstallments(): boolean {
      if (this.model.Installment) {
        return this.installmentAmountPerMonth * this.model.Installment === this.model.Amount;
      }
      return false;
    },
    minDate(): string {
      const dateLimit = new Date();
      dateLimit.setDate(dateLimit.getDate());
      dateLimit.setHours(0);
      dateLimit.setMinutes(0);
      dateLimit.setSeconds(0);
      dateLimit.setMilliseconds(0);
      return Utils.toVsDateFormat(dateLimit);
    },
  },
});
</script>
