import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "profileSetupTab" }
const _hoisted_2 = { class: "wizard-action" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_UploadProfilePhoto = _resolveComponent("UploadProfilePhoto")!
  const _component_UploadResume = _resolveComponent("UploadResume")!
  const _component_TextAreaField = _resolveComponent("TextAreaField")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_confirm_dialog = _resolveComponent("confirm-dialog")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          md: "12",
          sm: "12",
          cols: "12"
        }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [
            _createElementVNode("h1", null, "Set up your professional profile", -1)
          ])),
          _: 1
        }),
        _createVNode(_component_v_col, {
          md: "4",
          sm: "12",
          cols: "12"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_UploadProfilePhoto, {
              personalDetail: _ctx.personalDetail,
              onSetImage: _ctx.setImage,
              isFileValid: _ctx.validFile
            }, null, 8, ["personalDetail", "onSetImage", "isFileValid"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          md: "5",
          sm: "12",
          cols: "12"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_UploadResume, {
              personalDetail: _ctx.personalDetail,
              isFileValid: _ctx.validResume,
              onSetResume: _ctx.setResume
            }, null, 8, ["personalDetail", "isFileValid", "onSetResume"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          md: "12",
          sm: "12",
          cols: "12"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_form, {
              ref: "frmProfileDetail",
              "lazy-validation": ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_TextAreaField, {
                  counter: "",
                  placeholder: "Write a brief summary of your professional experience",
                  rules: [_ctx.validations.required, _ctx.validations.minCharDesc],
                  modelValue: _ctx.description,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.description) = $event)),
                  showRequired: "",
                  outlined: "",
                  label: "Bio (About Me)"
                }, null, 8, ["rules", "modelValue"])
              ]),
              _: 1
            }, 512)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.isAfterSignUp)
      ? (_openBlock(), _createBlock(_component_v_card_actions, {
          key: 0,
          class: "mt-5"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              md: "12",
              class: "text-center"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { class: "text-center pb-0 pt-0" }, {
                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createElementVNode("p", null, "You are done. you can start your personalized screening experience.", -1)
                  ])),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_v_btn, {
                    class: "primary_btn_v2",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goBack())),
                    loading: _ctx.submitting,
                    disabled: _ctx.submitting
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                          _createTextVNode("mdi mdi-arrow-left")
                        ])),
                        _: 1
                      }),
                      _cache[10] || (_cache[10] = _createTextVNode("Back"))
                    ]),
                    _: 1
                  }, 8, ["loading", "disabled"]),
                  _createVNode(_component_v_tooltip, {
                    top: "",
                    attach: ""
                  }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_btn, _mergeProps({ class: "secondary_btn" }, props, {
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.submitForm(true))),
                        disabled: _ctx.submitting,
                        loading: _ctx.submitting
                      }), {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, null, {
                            default: _withCtx(() => _cache[11] || (_cache[11] = [
                              _createTextVNode("mdi mdi-check")
                            ])),
                            _: 1
                          }),
                          _cache[12] || (_cache[12] = _createTextVNode("Save for later"))
                        ]),
                        _: 2
                      }, 1040, ["disabled", "loading"])
                    ]),
                    default: _withCtx(() => [
                      _cache[13] || (_cache[13] = _createElementVNode("span", null, "You can save your progress and return to the application process later", -1))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_btn, {
                    class: "primary_btn_v2",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.submitForm())),
                    loading: _ctx.submitting,
                    disabled: _ctx.submitting
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => _cache[14] || (_cache[14] = [
                          _createTextVNode("mdi mdi-check")
                        ])),
                        _: 1
                      }),
                      _cache[15] || (_cache[15] = _createTextVNode("Finish"))
                    ]),
                    _: 1
                  }, 8, ["loading", "disabled"])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showSaveForLaterConfirm,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.showSaveForLaterConfirm) = $event)),
      "max-width": "500",
      onKeydown: _cache[6] || (_cache[6] = _withKeys(($event: any) => (_ctx.showSaveForLaterConfirm = false), ["esc"]))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_confirm_dialog, {
          loading: _ctx.submitting,
          message: _ctx.confirmModel,
          onConfirm: _cache[4] || (_cache[4] = ($event: any) => (_ctx.save(true))),
          onCancel: _ctx.cancelSaving
        }, null, 8, ["loading", "message", "onCancel"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}