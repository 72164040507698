<template>
  <v-card class="main-container" :loading="isLoading">
    <div class="border">
      <div class="search">
        <v-toolbar-title class="title">Search</v-toolbar-title>
        <v-text-field placeholder="Search in course" outlined append-icon="mdi-magnify"></v-text-field>
      </div>
    </div>

    <div class="lesson-part">
      <div class="text-container">
        <v-tabs vertical v-model="activeTabIndex">
          <v-icon class="left-chevron tab-expand-icon" @click="hideMenu">{{ !menuExpanded ? "mdi-chevron-left" : "mdi-chevron-right" }}</v-icon>
          <v-tab v-for="(step, index) in courseModel.Steps" :key="index" class="menu">
            <template v-if="step.IsCompeleted"> <v-icon left class="completed-btn">mdi-checkbox-marked-circle</v-icon> </template>
            <template v-else>
              <v-icon left>mdi-circle-outline</v-icon>
            </template>
            <span v-if="!menuExpanded">{{ step.Type == "Lesson" ? step.Title : step.AssessmentDetails?.Title }}</span>
          </v-tab>

          <v-tab-item v-for="(step, index) in courseModel.Steps" :key="index">
            <v-card class="tab-container" flat v-if="activeTabIndex === index">
              <template>
                <div v-if="step.Type == 'Lesson'" v-html="step.Content"></div>
                <div v-if="step.Type == 'Assessment' && step.AssessmentDetails">
                  <RoadMapAssessmentDetails
                    :assessmentModel="step.AssessmentDetails"
                    :courseId="courseId"
                    :roadMapId="roadMapId"
                    @nextStep="goToNextLesson"
                    @AssessmentSubmited="AssessmentSubmited"
                  ></RoadMapAssessmentDetails>
                </div>
              </template>
              <v-row>
                <v-col v-if="!step.IsCompeleted && step.Type != 'Assessment'" class="buttons">
                  <v-btn @click="updateCourseStepState(true, step.Id)" :loading="isLoading" :disabled="isLoading">Mark as Completed</v-btn>
                </v-col>
                <v-col v-if="step.IsCompeleted && step.Type != 'Assessment'" cols="12" class="buttons">
                  <v-btn class="secondary_btn" @click="goToNextLesson" :loading="isLoading" :disabled="isLoading">Next Step</v-btn>
                  <span class="completed-btn">
                    completed
                    <v-icon class="completed-btn">mdi-check</v-icon>
                  </span>
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </div>
    </div>
  </v-card>
</template>

<script lang="ts">
import CourseModel from "../../../../../ProjectPortal-SharedComponents/src/models/CourseModel";
import RoadMapService from "@/services/RoadMapService";
import CourseService from "@/services/CourseService";
import AssessmentEditor from "./AssessmentEditor.vue";
import RoadMapAssessmentDetails from "./RoadMapAssessmentDetails.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    AssessmentEditor,
    RoadMapAssessmentDetails,
  },
  props: {
    roadMapId: {
      type: String,
      required: true,
    },
    courseId: {
      type: String,
      required: true,
    },
  },
  async mounted() {
    await this.loadCourseModels();
  },
  data() {
    return {
      menuExpanded: false,
      activeTabIndex: 0,
      courseModel: {} as CourseModel,
      isLoading: false,
    };
  },
  methods: {
    async updateCourseStepState(isComplete: boolean, stepId: string) {
      this.isLoading = true;
      var response = await CourseService.UpdateCourseStepState(this.courseId, isComplete, stepId, this.roadMapId);
      if (response) {
        var index = this.courseModel.Steps.findIndex((item) => item.Id == stepId);
        if (index != -1) {
          this.courseModel.Steps[index].IsCompeleted = isComplete;
        }
      }
      this.isLoading = false;
    },
    async AssessmentSubmited(assessmentId: any) {
      const step = this.courseModel.Steps.find((item) => item.AssessmentDetails && item.AssessmentDetails.Id == assessmentId);
      if (step) {
        await this.updateCourseStepState(true, step?.Id);
      }
    },
    async loadCourseModels() {
      if (this.roadMapId && this.courseId) {
        this.isLoading = true;
        this.courseModel = await RoadMapService.StartTeammemberRoadMapCourse(this.roadMapId, this.courseId);
        this.isLoading = false;
      }
    },
    goToNextLesson() {
      if (this.activeTabIndex < this.courseModel.Steps.length - 1) {
        this.activeTabIndex++;
      }
    },
    hideMenu() {
      this.menuExpanded = !this.menuExpanded;
    },
  },
});
</script>

<style scoped lang="scss">
@import "node_modules/shared-components/assets/style/font.scss";
@import "node_modules/shared-components/assets/colors.scss";

.tab-expand-icon {
  cursor: pointer;
  padding: 12px 16px;
  display: flex;
  justify-content: flex-start;
}
.code-mirror {
  width: 100%;
  height: 300px;
}

.text-container {
  width: 100%;
}

.main-container {
  padding: 0px;
  display: flex;
  flex-direction: column;
}

.tab-container {
  max-height: 700px;
  overflow-y: auto;
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 150px;
}

.search {
  display: flex;
  width: 30%;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 30px;
  margin-left: 15px;
  margin-right: 15px;
}

.border {
  border-bottom: 3px solid #aaaaaa;
}

.border-color {
  display: flex;
  justify-content: flex-start;
}

.title {
  font-weight: 700;
  margin-top: 10px;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-top: 15px;
}

.completed-btn {
  color: $success;
}

.next-btn {
  background-color: $racecar !important;
}

.menu.v-tab {
  justify-content: flex-start !important;
  box-sizing: border-box;
  min-width: 48px;
}

:deep(.v-tabs-slider) {
  background-color: #dc1e28 !important;
}

.left-icon {
  padding: 12px 16px;
  display: flex;
  justify-content: flex-start;
}

.lesson-part {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.left-chevron {
  &::after {
    width: 0 !important;
  }
}
</style>
