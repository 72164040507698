import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_access_list = _resolveComponent("access-list")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_access_list, {
      desserts: _ctx.desserts,
      loading: _ctx.loading,
      cLoading: _ctx.createLoading,
      projects: _ctx.projects,
      teammembers: _ctx.teammembers,
      onFetchTeammembers: _ctx.fetchTeammembers,
      onAccessAdded: _ctx.accessAdded,
      onFetchAccess: _ctx.fetchAccess,
      onFetchProjects: _ctx.fetchProjects,
      onRevokeAndGrant: _ctx.revokeAndGrant,
      onNotify: _ctx.sendNotification,
      onShowError: _ctx.showError
    }, null, 8, ["desserts", "loading", "cLoading", "projects", "teammembers", "onFetchTeammembers", "onAccessAdded", "onFetchAccess", "onFetchProjects", "onRevokeAndGrant", "onNotify", "onShowError"])
  ]))
}