import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          cols: "12",
          md: "12"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("h3", null, "Your email address hasn't been verified, please click on the verification link you have received in your email", -1)
          ])),
          _: 1
        }),
        _createVNode(_component_v_col, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_btn, {
              loading: _ctx.isLoading,
              color: "secondary",
              onClick: _ctx.resend
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, null, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode("mdi mdi-refresh")
                  ])),
                  _: 1
                }),
                _cache[2] || (_cache[2] = _createTextVNode("Resend "))
              ]),
              _: 1
            }, 8, ["loading", "onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}