import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "profile-wizard" }
const _hoisted_2 = { class: "mb-0" }
const _hoisted_3 = {
  key: 0,
  class: "step-divider"
}
const _hoisted_4 = { class: "step-progress" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_stepper_item = _resolveComponent("v-stepper-item")!
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!
  const _component_v_stepper_header = _resolveComponent("v-stepper-header")!
  const _component_PersonalDetail = _resolveComponent("PersonalDetail")!
  const _component_v_stepper_window_item = _resolveComponent("v-stepper-window-item")!
  const _component_ExperienceDetail = _resolveComponent("ExperienceDetail")!
  const _component_Skills = _resolveComponent("Skills")!
  const _component_CareerHistory = _resolveComponent("CareerHistory")!
  const _component_ProfilePhoto = _resolveComponent("ProfilePhoto")!
  const _component_v_stepper_window = _resolveComponent("v-stepper-window")!
  const _component_confirm_dialog = _resolveComponent("confirm-dialog")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_stepper = _resolveComponent("v-stepper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_stepper, {
      ref: "mainRef",
      modelValue: _ctx.currentStep,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.currentStep) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_stepper_header, null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (n, index) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: `${n.step}-step`
              }, [
                _createVNode(_component_v_stepper_item, {
                  complete: _ctx.currentStep > n.step,
                  step: n.step,
                  value: n.step
                }, {
                  title: _withCtx(() => [
                    _createTextVNode(_toDisplayString(n.title), 1)
                  ]),
                  subtitle: _withCtx(() => [
                    _createElementVNode("p", _hoisted_2, [
                      _createVNode(_component_v_icon, { class: "mr-1" }, {
                        default: _withCtx(() => _cache[7] || (_cache[7] = [
                          _createTextVNode("mdi-timer")
                        ])),
                        _: 1
                      }),
                      _createTextVNode(_toDisplayString(n.description), 1)
                    ])
                  ]),
                  _: 2
                }, 1032, ["complete", "step", "value"]),
                (index != _ctx.steps.length - 1)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128)),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_v_progress_linear, {
                value: _ctx.wizardStep,
                color: "#0ECB7C",
                "background-color": "#F7FFF7"
              }, null, 8, ["value"]),
              _createElementVNode("span", null, "Completion: " + _toDisplayString(_ctx.wizardStep) + " %", 1)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_v_stepper_window, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_stepper_window_item, { value: 1 }, {
              default: _withCtx(() => [
                (_ctx.currentStep == 1)
                  ? (_openBlock(), _createBlock(_component_PersonalDetail, {
                      key: 0,
                      loading: _ctx.submitting,
                      onSubmitPersonalDetail: _ctx.submitPersonalDetail,
                      onScrollToElement: _ctx.scrollToElement,
                      onGetEducations: _ctx.getEducations,
                      personalDetailModel: _ctx.personalDetailModel,
                      isAfterSignUp: true,
                      educations: _ctx.educations,
                      countries: _ctx.countries
                    }, null, 8, ["loading", "onSubmitPersonalDetail", "onScrollToElement", "onGetEducations", "personalDetailModel", "educations", "countries"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_v_stepper_window_item, { value: 2 }, {
              default: _withCtx(() => [
                _createVNode(_component_ExperienceDetail, {
                  onScrollToElement: _ctx.scrollToElement,
                  formLoading: _ctx.submitting,
                  professionalDetailModel: _ctx.professionalDetailModel,
                  details: _ctx.details,
                  loading: _ctx.submitting,
                  teamMember: _ctx.teamMemberModel,
                  sendReqeustLoading: _ctx.sendReqeustLoading,
                  isAfterSignUp: true,
                  onSubmitExperienceDetail: _ctx.submitExperienceDetail,
                  onSubmitExperienceCareerObjectiveDetail: _ctx.submitExperienceCareerObjectiveDetail,
                  onRemoveCareerObjective: _ctx.RemoveCareerObjective,
                  onSendJobRequest: _ctx.sendJobRequest,
                  onGoBack: _ctx.prevStep,
                  onShowMessage: _ctx.showMessage
                }, null, 8, ["onScrollToElement", "formLoading", "professionalDetailModel", "details", "loading", "teamMember", "sendReqeustLoading", "onSubmitExperienceDetail", "onSubmitExperienceCareerObjectiveDetail", "onRemoveCareerObjective", "onSendJobRequest", "onGoBack", "onShowMessage"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_stepper_window_item, { value: 3 }, {
              default: _withCtx(() => [
                (_ctx.stepMounted.skill)
                  ? (_openBlock(), _createBlock(_component_Skills, {
                      key: 0,
                      onScrollToElement: _ctx.scrollToElement,
                      formLoading: _ctx.submitting,
                      details: _ctx.details,
                      loading: _ctx.submitting,
                      teamMember: _ctx.teamMemberModel,
                      sendReqeustLoading: _ctx.sendReqeustLoading,
                      isAfterSignUp: true,
                      languages: _ctx.languages,
                      skills: _ctx.skills,
                      teammemberSkills: _ctx.teammemberSkills,
                      uploadedCertificate: _ctx.uploadedCertificate,
                      showEditSkillModal: _ctx.showEditSkillModal,
                      skillLoading: _ctx.skillLoading,
                      certificates: _ctx.certificates,
                      onSubmitSkillsDetail: _ctx.submitSkillsDetail,
                      onGoBack: _ctx.prevStep,
                      onShowLimitationMessage: _ctx.showLimitationMessage,
                      onSkillDownload: _ctx.skillDownload,
                      onSkillCertificateChanged: _ctx.skillCertificateChanged,
                      onSaveSkill: _ctx.saveSkill,
                      onRemove: _ctx.remove,
                      onShowEditSkill: _ctx.showEditSkill,
                      onSendAddRequest: _ctx.sendSkillAddRequest,
                      onCloseEditModal: _ctx.closeEditModal,
                      onGetCertificates: _ctx.getCertificates,
                      onSetLanguagesState: _ctx.setLanguagesState
                    }, null, 8, ["onScrollToElement", "formLoading", "details", "loading", "teamMember", "sendReqeustLoading", "languages", "skills", "teammemberSkills", "uploadedCertificate", "showEditSkillModal", "skillLoading", "certificates", "onSubmitSkillsDetail", "onGoBack", "onShowLimitationMessage", "onSkillDownload", "onSkillCertificateChanged", "onSaveSkill", "onRemove", "onShowEditSkill", "onSendAddRequest", "onCloseEditModal", "onGetCertificates", "onSetLanguagesState"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_v_stepper_window_item, { value: 4 }, {
              default: _withCtx(() => [
                (_ctx.stepMounted.career)
                  ? (_openBlock(), _createBlock(_component_CareerHistory, {
                      key: 0,
                      onGoNext: _ctx.nextStep,
                      onScrollToElement: _ctx.scrollToElement,
                      onSendJobRequest: _ctx.sendJobRequest,
                      onGoBack: _ctx.prevStep,
                      onGetCareerHistories: _ctx.getCareerHistories,
                      onSetCareerHistoryDescriptionState: _ctx.setCareerHistoryDescriptionState,
                      onUpdateJobTitle: _ctx.updateJobTitle,
                      onUpdateSkill: _ctx.updateSkillList,
                      formLoading: _ctx.submitting,
                      isAfterSignUp: true,
                      skills: _ctx.skills,
                      careerHistories: _ctx.careerHistories,
                      careerHistoryDescription: _ctx.careerHistoryDescription,
                      teammemberSkills: _ctx.teammemberSkills,
                      submitting: _ctx.submitting,
                      details: _ctx.details
                    }, null, 8, ["onGoNext", "onScrollToElement", "onSendJobRequest", "onGoBack", "onGetCareerHistories", "onSetCareerHistoryDescriptionState", "onUpdateJobTitle", "onUpdateSkill", "formLoading", "skills", "careerHistories", "careerHistoryDescription", "teammemberSkills", "submitting", "details"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_v_stepper_window_item, { value: 5 }, {
              default: _withCtx(() => [
                _createVNode(_component_ProfilePhoto, {
                  loading: _ctx.submitting,
                  onScrollToElement: _ctx.scrollToElement,
                  onSubmitForm: _ctx.submitPersonalDetail,
                  onGoBack: _ctx.prevStep,
                  teammemberModel: _ctx.personalDetailModel,
                  isAfterSignUp: true
                }, null, 8, ["loading", "onScrollToElement", "onSubmitForm", "onGoBack", "teammemberModel"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_ctx.showConfirm)
          ? (_openBlock(), _createBlock(_component_v_dialog, {
              key: 0,
              modelValue: _ctx.showConfirm,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showConfirm) = $event)),
              "max-width": "500",
              onKeydown: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.showConfirm = false), ["esc"]))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_confirm_dialog, {
                  message: _ctx.confirmModel,
                  onConfirm: _ctx.confirmRemove,
                  onCancel: _ctx.cancelRemove
                }, null, 8, ["message", "onConfirm", "onCancel"])
              ]),
              _: 1
            }, 8, ["modelValue"]))
          : _createCommentVNode("", true),
        (_ctx.showJobRequestConfirm)
          ? (_openBlock(), _createBlock(_component_v_dialog, {
              key: 1,
              modelValue: _ctx.showJobRequestConfirm,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showJobRequestConfirm) = $event)),
              "max-width": "500",
              onKeydown: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.showJobRequestConfirm = false), ["esc"]))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_confirm_dialog, {
                  loading: _ctx.sendReqeustLoading,
                  message: _ctx.confirmModel,
                  showInput: "",
                  inputLable: "Job Title",
                  onConfirm: _ctx.confirmSendJobRequest,
                  onCancel: _ctx.cancelSendRequest
                }, null, 8, ["loading", "message", "onConfirm", "onCancel"])
              ]),
              _: 1
            }, 8, ["modelValue"]))
          : _createCommentVNode("", true),
        (_ctx.showSkillRequestConfirm)
          ? (_openBlock(), _createBlock(_component_v_dialog, {
              key: 2,
              modelValue: _ctx.showSkillRequestConfirm,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showSkillRequestConfirm) = $event)),
              "max-width": "500",
              onKeydown: _cache[5] || (_cache[5] = _withKeys(($event: any) => (_ctx.showSkillRequestConfirm = false), ["esc"]))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_confirm_dialog, {
                  loading: _ctx.sendReqeustLoading,
                  message: _ctx.confirmModel,
                  showInput: "",
                  inputLable: "Skill",
                  onConfirm: _ctx.sendAddSkillRequest,
                  onCancel: _ctx.cancelSendRequest
                }, null, 8, ["loading", "message", "onConfirm", "onCancel"])
              ]),
              _: 1
            }, 8, ["modelValue"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}