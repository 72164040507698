import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "editor" }
const _hoisted_2 = { class: "main" }
const _hoisted_3 = { class: "footer" }
const _hoisted_4 = { class: "buttons" }
const _hoisted_5 = { class: "infos" }
const _hoisted_6 = { class: "item" }
const _hoisted_7 = { class: "item" }
const _hoisted_8 = { class: "item" }
const _hoisted_9 = { class: "item" }
const _hoisted_10 = { class: "item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_codemirror = _resolveComponent("codemirror")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_codemirror, {
        modelValue: _ctx.localCode,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localCode) = $event)),
        class: "codemirror",
        placeholder: "Please enter the code.",
        extensions: _ctx.extensions,
        autofocus: true,
        disabled: false,
        "indent-with-tab": true,
        "tab-size": 5,
        onUpdate: _ctx.handleStateUpdate,
        onReady: _ctx.handleReady
      }, null, 8, ["modelValue", "extensions", "onUpdate", "onReady"])
    ]),
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "divider" }, null, -1)),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", {
          class: "item",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleUndo && _ctx.handleUndo(...args)))
        }, "Undo"),
        _createElementVNode("button", {
          class: "item",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleRedo && _ctx.handleRedo(...args)))
        }, "Redo")
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", _hoisted_6, "Length: " + _toDisplayString(_ctx.state.length), 1),
        _createElementVNode("span", _hoisted_7, "Lines: " + _toDisplayString(_ctx.state.lines), 1),
        _createElementVNode("span", _hoisted_8, "Cursor: " + _toDisplayString(_ctx.state.cursor), 1),
        _createElementVNode("span", _hoisted_9, "Selected: " + _toDisplayString(_ctx.state.selected), 1),
        _createElementVNode("span", _hoisted_10, "Language: " + _toDisplayString(_ctx.mode), 1)
      ])
    ])
  ]))
}