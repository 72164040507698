import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_LoginForm = _resolveComponent("LoginForm")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col),
        _createVNode(_component_v_col, { class: "mt-4 mb-6 flex text-center" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_btn, {
              class: "",
              disabled: "",
              color: "accent",
              onClick: _ctx.googleAuth
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, {
                  class: "mr-2",
                  color: "white"
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode("mdi mdi-google")
                  ])),
                  _: 1
                }),
                _cache[2] || (_cache[2] = _createTextVNode(" Login With Google "))
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_col)
      ]),
      _: 1
    }),
    _createVNode(_component_LoginForm, {
      "form-id": "login-form",
      onSubmitting: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onSubmitting($event))),
      onSuccess: _ctx.onLoginSuccess
    }, null, 8, ["onSuccess"]),
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          md: "12",
          sm: "12",
          cols: "12"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_v_btn, {
                disabled: _ctx.submitting,
                loading: _ctx.submitting,
                color: "accent",
                type: "submit",
                form: "login-form"
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode("Login")
                ])),
                _: 1
              }, 8, ["disabled", "loading"]),
              _createVNode(_component_v_btn, {
                class: "secondary",
                to: "/register/signup"
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode(" Signup ")
                ])),
                _: 1
              })
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}