<template>
  <div>
    <Timesheet
      :isAdmin="false"
      :timesheetsVersion="timesheetsVersion"
      :dateOffset="dateOffset"
      :userProjects="userProjects"
      :selectedTeammember="teamMemberInfo"
      :showRegister="showRegister"
      :selectedCommitment="null"
      :fetchAllCommitmentsService="fetchAllCommitments"
      :fetchTimeSheetsService="fetchTimesheets"
      :fetchLeavesService="fetchLeaves"
      :finishedCloneProcess="finishedCloneProcess"
      :cloneTimesheetId="cloneTimesheetId"
      :regLoading="regLoading"
      @registerTimesheet="registerTimesheet"
      @confirmTimesheet="confirmTimesheet"
      @cancelRegister="cancel"
      @errorRaised="errorRaised"
      @confirmClone="confirmClone"
      @deleteLeave="deleteLeave"
      @deleteTimesheet="deleteTimesheet"
      @confirmLeave="confirmLeave"
    ></Timesheet>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Utils from "shared-components/src/utils/Utils";
import Timesheet from "shared-components/src/components/Timesheet/List.vue";
import AuditTrailService from "shared-components/src/services/AuditTrailService";
import LeaveService from "@/services/LeaveService";
import ProjectService from "@/services/ProjectService";
import TeamMemberService from "@/services/TeamMemberService";
import TimesheetService from "@/services/TimesheetService";
import UICommitment from "shared-components/src/models/Commitment";
import TimesheetModel from "shared-components/src/models/Timesheet";
import Leave from "shared-components/src/models/Leave";
import Project from "shared-components/src/models/Project";
import { Profile } from "shared-components/src/definitions/config";
import { AuditTrailRecordTypes } from "shared-components/src/definitions/constants";
import AuditTrail from "shared-components/src/models/AuditTrail";
import store from "@/store";

export default defineComponent({
  components: {
    Timesheet,
  },
  data() {
    return {
      userProjects: [] as Project[],
      cloneTimesheetId: "",
      registerDate: "",
      regLoading: false,
      showRegister: false,
      timesheetsVersion: 0,
      loadingPage: true,
      finishedCloneProcess: false,
      teamMemberInfo: store.state.teamMemberInfo,
    };
  },
  async mounted() {
    await this.fetchUserProjects();
  },
  methods: {
    async fetchUserProjects() {
      this.userProjects = await ProjectService.getByTeammemberId();
    },
    async cancel() {
      this.showRegister = false;
    },
    loadList() {
      this.timesheetsVersion++;
    },
    registerTimesheet(): void {
      this.showRegister = true;
    },
    errorRaised(msg: string): void {
      store.dispatch("showErrorMessage", msg)
    },
    async deleteTimesheet(id: string): Promise<void> {
      await TimesheetService.delete(id)
        .then(() => {})
        .catch((error) => {
          if (error.response.data) {
            this.errorRaised(error.response.data);
          } else {
            this.errorRaised("There is an error to submit the timesheet record!");
          }
        })
        .finally(() => {
          this.loadList();
        });
    },
    async deleteLeave(id: string): Promise<void> {
      await LeaveService.delete(id)
        .then(() => {})
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            this.errorRaised(error.response.data);
          } else {
            this.errorRaised("There is an error to submit the timesheet record!");
          }
          console.error(error);
        })
        .finally(() => {
          this.loadList();
        });
    },
    async fetchAllCommitments(): Promise<UICommitment[]> {
      return await TeamMemberService.getCommitmentsByTeammeberId();
    },
    async confirmTimesheet(saveModel: any): Promise<void> {
      var timesheet = saveModel.timesheet;
      this.regLoading = true;

      TimesheetService.saveTimesheet(timesheet)
        .then((result) => {
          const audit = {
            RecordType: {
              Type: AuditTrailRecordTypes.Timesheet,
              Id: result,
            },
            Event: timesheet.id ? "Update" : "Create",
            DateTime: new Date(),
            UserId: this.teamMemberInfo?.UserAccountsId,
          } as AuditTrail;
          AuditTrailService.set(audit);
          if (timesheet.Date) {
            timesheet.Date = Utils.addTimezoneOffset(timesheet.Date);
          }
          if (!saveModel.addAfterSave) {
            this.cancel();
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            this.errorRaised(error.response.data);
          } else {
            this.errorRaised("There is an error to submit the timesheet record!");
          }
          console.error(error);
        })
        .finally(() => {
          this.loadList();
          this.regLoading = false;
        });
    },
    async fetchTimesheets(model: any) {
      try {
        let timesheets = await TimesheetService.getListInDateRange(this.teamMemberInfo?.Id ?? "", model.startDate, model.endDate);
        timesheets = timesheets.filter((c) => c.Date && c.Date >= model.startDate && c.Date <= model.endDate);
        return timesheets;
      } catch (error) {
        this.errorRaised("There is an error to getting timesheet records");
        console.error(error);
        return [];
      }
    },
    async fetchLeaves(model: any) {
      try {
        return await LeaveService.getListInDateRange(model.startDate, model.endDate);
      } catch (error) {
        this.errorRaised("There is an error to getting leave records");
        console.error(error);
        return [];
      }
    },
    confirmClone(model: any) {
      var timesheet = model.timesheet as TimesheetModel;
      this.finishedCloneProcess = false;
      timesheet.Date = Utils.addTimezoneOffset(timesheet.Date);
      if (model.goToEdit === true) {
        this.finishedCloneProcess = true;
        this.registerTimesheet();
      } else {
        TimesheetService.saveTimesheet(timesheet)
          .then()
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              this.errorRaised(error.response.data);
            }
            this.errorRaised(error.response.data);
          })
          .finally(() => {
            this.finishedCloneProcess = true;
          });
      }
    },
    async confirmLeave(leave: Leave) {
      LeaveService.set(leave)
        .then((data) => {})
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            this.errorRaised(error.response.data);
          } else {
            this.errorRaised("There is an error to submit the leave record!");
          }
          console.error(error);
        })
        .finally(() => {
          this.loadList();
        });
    },
  },
  computed: {
    dateOffset(): number | null {
      if (this.$route.params.dateOffset) {
        return parseInt(this.$route.params.dateOffset as string, 10);
      } else {
        return null;
      }
    },
  },
});
</script>
