import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Timesheet = _resolveComponent("Timesheet", true)!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Timesheet, {
      isAdmin: false,
      timesheetsVersion: _ctx.timesheetsVersion,
      dateOffset: _ctx.dateOffset,
      userProjects: _ctx.userProjects,
      selectedTeammember: _ctx.teamMemberInfo,
      showRegister: _ctx.showRegister,
      selectedCommitment: null,
      fetchAllCommitmentsService: _ctx.fetchAllCommitments,
      fetchTimeSheetsService: _ctx.fetchTimesheets,
      fetchLeavesService: _ctx.fetchLeaves,
      finishedCloneProcess: _ctx.finishedCloneProcess,
      cloneTimesheetId: _ctx.cloneTimesheetId,
      regLoading: _ctx.regLoading,
      onRegisterTimesheet: _ctx.registerTimesheet,
      onConfirmTimesheet: _ctx.confirmTimesheet,
      onCancelRegister: _ctx.cancel,
      onErrorRaised: _ctx.errorRaised,
      onConfirmClone: _ctx.confirmClone,
      onDeleteLeave: _ctx.deleteLeave,
      onDeleteTimesheet: _ctx.deleteTimesheet,
      onConfirmLeave: _ctx.confirmLeave
    }, null, 8, ["timesheetsVersion", "dateOffset", "userProjects", "selectedTeammember", "showRegister", "fetchAllCommitmentsService", "fetchTimeSheetsService", "fetchLeavesService", "finishedCloneProcess", "cloneTimesheetId", "regLoading", "onRegisterTimesheet", "onConfirmTimesheet", "onCancelRegister", "onErrorRaised", "onConfirmClone", "onDeleteLeave", "onDeleteTimesheet", "onConfirmLeave"])
  ]))
}