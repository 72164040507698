import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_repository_list = _resolveComponent("repository-list")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_repository_list, {
      repositories: _ctx.repositories,
      pageLoading: _ctx.pageLoading,
      commitments: _ctx.commitments,
      isAdmin: false,
      onFetchRepositories: _ctx.fetchRepositories,
      onErrorRaised: _ctx.errorRaised
    }, null, 8, ["repositories", "pageLoading", "commitments", "onFetchRepositories", "onErrorRaised"])
  ]))
}