<template>
  <div class="h-100">
    <!-- <v-btn icon class="back-button" @click="goBack"><v-icon>mdi-arrow-left</v-icon></v-btn>/ -->
    <v-btn class="back-button" @click="goBack"><v-icon>mdi-arrow-left</v-icon>Back</v-btn>
    <ol v-if="roadMap && !isLoading">
      <li>
        <v-card class="project-card start-end-card">
          <v-card-title class="title">Start</v-card-title>
        </v-card>
      </li>
      <li v-for="(step, index) in roadMap.Steps" :key="index">
        <v-card
          class="project-card"
          :class="getStepStyle(step)"
          @click="navigateToCourseDetail(step)"
          v-if="step.Type == 'Course'"
          :disabled="index != 0 && roadMap.Steps[index - 1].TalentState != 'Completed'"
        >
          <v-card-title class="title">{{ step.CourseDetail.Title }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col v-if="step.IsFavorite">
                <v-icon class="icon-color">mdi-check</v-icon>
                <span class="pl-2 icon-color">Completed</span>
              </v-col>
              <v-col v-else cols="12" class="rate">
                <v-icon>mdi-star</v-icon>
                <span class="pl-2">3.5</span>
              </v-col>
              <v-col cols="12">
                <span>{{ step.CourseDetail.Level }}</span> - <span>{{ formatDuration(step.CourseDetail.Duration) }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card
          class="project-card"
          :class="getStepStyle(step)"
          @click="navigateToAssessmentDetail(step)"
          v-if="step.Type == 'Assessment'"
          :disabled="index != 0 && roadMap.Steps[index - 1].TalentState != 'Completed'"
        >
          <v-card-title class="title">{{ step.AssessmentDetail.Title }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col v-if="step.IsFavorite">
                <v-icon class="icon-color">mdi-check</v-icon>
                <span class="pl-2 icon-color">Completed</span>
              </v-col>
              <v-col v-else cols="12" class="rate">
                <v-icon>mdi-star</v-icon>
                <span class="pl-2">3.5</span>
              </v-col>
              <v-col cols="12">
                <span>{{ step.AssessmentDetail.Questions.length }} Questions</span> -
                <span>{{ formatDuration(step.AssessmentDetail.Questions.reduce((total, a) => total + a.Duration, 0)) }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </li>
      <li>
        <v-card class="project-card start-end-card">
          <v-card-title class="title">End</v-card-title>
        </v-card>
      </li>
    </ol>
    <div class="loading-container" v-if="isLoading">
      <v-progress-circular color="primary" indeterminate :size="80"> Loading</v-progress-circular>
    </div>
  </div>
</template>

<script lang="ts">
import RoadMapService from "@/services/RoadMapService";
import { Question } from "shared-components/src/models/AssessmentModel";
import TmRoadMapModel from "shared-components/src/models/TmRoadMapModel";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    roadMapId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      roadMap: null as TmRoadMapModel | null,
      isLoading: false,
    };
  },
  async mounted() {
    await this.loadRoadMap();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getStepStyle(step: any) {
      switch (step.TalentState) {
        case "InProgress":
          return "step-in-progress";
        case "Completed":
          return "step-completed";
        case "NotStarted":
          return "step-not-started";
        default:
          return "step-not-started";
      }
    },
    getAssessmentLevels(questions: Question[]) {
      let levels = questions.map((item: any) => item.Level);
      return [...new Set(levels)].join(", ");
    },
    async loadRoadMap() {
      if (this.roadMapId) {
        this.isLoading = true;
        this.roadMap = await RoadMapService.GetTeammemberRoadMapSteps(this.roadMapId);
        this.isLoading = false;
      }
    },
    navigateToCourseDetail(item: any) {
      this.$router.push({
        name: "RoadMapCourseDetail",
        params: { courseId: item.Id },
      });
    },
    navigateToAssessmentDetail(item: any) {
      this.$router.push({
        name: "RoadMapAssessmentDetail",
        params: { assessmentId: item.Id },
      });
    },
    formatDuration(duration: number) {
      const hours = Math.floor(duration / 60);
      const minutes = duration % 60;
      return hours > 0 ? `${hours} h, ${minutes} m` : `${minutes} m`;
    },
  },
});
</script>

<style scoped lang="scss">
@import "node_modules/shared-components/assets/style/color.scss";
.back-button {
  gap: 3px;
  margin-bottom: 10px;
  margin-left: 23px;
  border: 1px solid white;
}

.step-in-progress {
  background-color: $c_chrysler_blue !important;
}

.step-completed {
  background-color: $c_emerald !important;
}

.project-card {
  height: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.2) !important;
  border-radius: 8px !important;
  border: 1px solid white;
  transition: box-shadow 0.3s ease-in-out;
}

.start-end-card {
  display: flex;
  justify-content: center;
  align-items: center;
}

ol {
  list-style: none;
  padding: 0;
  --unit-width: 35px;
  --unit-height: 15px;
  width: 100%;
  margin: 0 auto;
}

li {
  --height: 150px;
  position: relative;
  display: block;
  margin: 30px 0;
  width: 100%;
  height: var(--height);
}

li::before {
  content: "";
  background-color: $c_white;
  position: absolute;
  display: block;
  width: var(--unit-width);
  height: calc(var(--unit-height) - 3px);
  top: 100%;
  left: calc(50% - (var(--unit-height) / 2));
}
li::after {
  content: "";
  position: absolute;
  display: block;
  left: calc(50% - 16px);
  top: calc(100% + var(--unit-width) - 1px);
  border: var(--unit-height) solid transparent;
  border-top-color: inherit;
}
li:last-child::before,
li:last-child::after {
  content: none;
}
@media (max-width: 550px) {
  li {
    margin: 25px 0;
    float: left;
  }
  li::before {
    height: var(--unit-width);
    width: calc(var(--unit-height) - 3px);
  }
}

@media (min-width: 550px) and (max-width: 899px) {
  li {
    margin: 25px;
    width: calc(50% - 50px);
    float: left;
  }
  li:nth-child(4n + 3),
  li:nth-child(4n + 4) {
    float: right;
  }
  li:nth-child(4n + 1)::before {
    top: calc(var(--height) / 2 - var(--unit-height) / 2);
    left: 100%;
  }
  li:nth-child(4n + 1)::after {
    top: calc(var(--height) / 2 - var(--unit-height));
    left: calc(100% + var(--unit-width) - 1px);
    border: var(--unit-height) solid transparent;
    border-left-color: inherit;
  }
  li:nth-child(4n + 3)::before {
    top: calc(var(--height) / 2 - var(--unit-height) / 2);
    left: -35px;
  }
  li:nth-child(4n + 3)::after {
    top: calc(var(--height) / 2 - var(--unit-height) - 1px);
    left: -64px;
    border: var(--unit-height) solid transparent;
    border-right-color: inherit;
  }
  li:nth-child(4n + 2)::before,
  li:nth-child(4n + 4)::before {
    height: var(--unit-width);
    width: calc(var(--unit-height) - 3px);
  }
}
@media (min-width: 900px) and (max-width: 1199px) {
  li {
    margin: 25px;
    width: calc(33.33% - 50px);
    float: left;
  }
  li:nth-child(6n + 4),
  li:nth-child(6n + 5),
  li:nth-child(6n + 6) {
    float: right;
  }
  li:nth-child(6n + 1)::before,
  li:nth-child(6n + 2)::before {
    top: calc(var(--height) / 2 - var(--unit-height) / 2);
    left: 100%;
  }
  li:nth-child(6n + 1)::after,
  li:nth-child(6n + 2)::after {
    top: calc(var(--height) / 2 - var(--unit-height));
    left: calc(100% + var(--unit-width) - 1px);
    border: var(--unit-height) solid transparent;
    border-left-color: inherit;
  }
  li:nth-child(6n + 4)::before,
  li:nth-child(6n + 5)::before {
    top: calc(var(--height) / 2 - var(--unit-height) / 2);
    left: -35px;
  }
  li:nth-child(6n + 4)::after,
  li:nth-child(6n + 5)::after {
    top: calc(var(--height) / 2 - var(--unit-height));
    left: -64px;
    border: var(--unit-height) solid transparent;
    border-right-color: inherit;
  }
  li:nth-child(6n + 3)::before,
  li:nth-child(6n + 6)::before {
    height: var(--unit-width);
    width: calc(var(--unit-height) - 3px);
  }
}
@media (min-width: 1200px) {
  li {
    margin: 25px;
    width: calc(25% - 50px);
    float: left;
  }
  li:nth-child(8n + 5),
  li:nth-child(8n + 6),
  li:nth-child(8n + 7),
  li:nth-child(8n + 8) {
    float: right;
  }
  li:nth-child(8n + 1)::before,
  li:nth-child(8n + 2)::before,
  li:nth-child(8n + 3)::before {
    top: calc(var(--height) / 2 - var(--unit-height) / 2);
    left: 100%;
  }
  li:nth-child(8n + 1)::after,
  li:nth-child(8n + 2)::after,
  li:nth-child(8n + 3)::after {
    top: calc(var(--height) / 2 - var(--unit-height));
    left: calc(100% + (var(--unit-width) - 1px));
    border: var(--unit-height) solid transparent;
    border-left-color: inherit;
  }
  li:nth-child(8n + 5)::before,
  li:nth-child(8n + 6)::before,
  li:nth-child(8n + 7)::before {
    top: calc(var(--height) / 2 - var(--unit-height) / 2);
    left: -35px;
  }
  li:nth-child(8n + 5)::after,
  li:nth-child(8n + 6)::after,
  li:nth-child(8n + 7)::after {
    top: calc(var(--height) / 2 - var(--unit-height) - 1px);
    left: -64px;
    border: var(--unit-height) solid transparent;
    border-right-color: inherit;
  }

  li:nth-child(8n + 4)::before,
  li:nth-child(8n + 8)::before {
    height: var(--unit-width);
    width: calc(var(--unit-height) - 3px);
  }
}
</style>
