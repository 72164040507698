import Project from "shared-components/src/models/Project";
import ApiService from "./ApiService";
import { SystemErrors } from "shared-components/src/definitions/systemErrors";

export default class ProjectService {
  public static async getByTeammemberId(): Promise<Project[]> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/project/GetByTeammember`, "")
        .then((projectResult) => {
          if (!projectResult.data || projectResult.status != SystemErrors.Authentication.Success) {
            resolve([]);
          } else {
            const retVal = projectResult.data.map((d: any) => {
              const item = { id: d.id, ...d } as Project;
              return item;
            });

            resolve(retVal);
          }
        })
        .catch((projectError) => reject(projectError));
    });
  }

  public static async getList(): Promise<Project[]> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/project`, "")
        .then((projectResult) => {
          if (!projectResult.data) {
            resolve([]);
          } else {
            const retVal = projectResult.data.map((d: any) => {
              const item = { id: d.id, ...d } as Project;
              return item;
            });
            resolve(retVal);
          }
        })
        .catch((projectError) => {
          reject(projectError);
        });
    });
  }
}
