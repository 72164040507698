<template>
  <div class="editor">
    <div class="main">
      <codemirror
        v-model="localCode"
        class="codemirror"
        placeholder="Please enter the code."
        :extensions="extensions"
        :autofocus="true"
        :disabled="false"
        :indent-with-tab="true"
        :tab-size="5"
        @update="handleStateUpdate"
        @ready="handleReady"
      />
    </div>
    <div class="divider"></div>
    <div class="footer">
      <div class="buttons">
        <button class="item" @click="handleUndo">Undo</button>
        <button class="item" @click="handleRedo">Redo</button>
      </div>
      <div class="infos">
        <span class="item">Length: {{ state.length }}</span>
        <span class="item">Lines: {{ state.lines }}</span>
        <span class="item">Cursor: {{ state.cursor }}</span>
        <span class="item">Selected: {{ state.selected }}</span>
        <span class="item">Language: {{ mode }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { EditorView, keymap, ViewUpdate } from "@codemirror/view";
import { redo, undo } from "@codemirror/commands";
import { Codemirror } from "vue-codemirror";
import { EditorState } from "@codemirror/state";
import { dracula } from "thememirror";

import { javascript } from "@codemirror/lang-javascript";
import { php } from "@codemirror/lang-php";
import { sass } from "@codemirror/lang-sass";
import { html } from "@codemirror/lang-html";
import { css } from "@codemirror/lang-css";
import { python } from "@codemirror/lang-python";
import { sql } from "@codemirror/lang-sql";
import { vue } from "@codemirror/lang-vue";
import { xml } from "@codemirror/lang-xml";
import { java } from "@codemirror/lang-java";
import { cpp } from "@codemirror/lang-cpp";
import { StreamLanguage } from "@codemirror/language";
import { swift } from "@codemirror/legacy-modes/mode/swift";
import { vb } from "@codemirror/legacy-modes/mode/vb";
import { perl } from "@codemirror/legacy-modes/mode/perl";
import { csharp, kotlin, objectiveC, ceylon, c, scala } from "@codemirror/legacy-modes/mode/clike";
import { ruby } from "@codemirror/legacy-modes/mode/ruby";
import { closeBrackets, closeBracketsKeymap } from "@codemirror/autocomplete";
import { defaultKeymap } from "@codemirror/commands";

export default defineComponent({
  props: {
    mode: {
      type: String,
      default: "javascript",
    },
    code: {
      type: String,
      required: true,
    },
  },
  components: { Codemirror },
  data() {
    return {
      cmView: null as null | EditorView,
      state: {
        lines: null as null | number,
        cursor: null as null | number,
        selected: null as null | number,
        length: null as null | number,
      },
    };
  },
  emits: ["update:code"],
  computed: {
    extensions() {
      return [this.getLanguage(this.mode), dracula, EditorView.lineWrapping];
    },
    localCode: {
      get() {
        return this.code;
      },
      set(value: string) {
        this.$emit("update:code", value);
      },
    },
  },
  mounted() {},
  methods: {
    handleReady({ view }: any) {
      this.cmView = view;
    },

    // https://github.com/codemirror/commands/blob/main/test/test-history.ts
    handleUndo() {
      undo({
        state: this.cmView!.state as EditorState,
        dispatch: this.cmView!.dispatch,
      });
    },

    handleRedo() {
      redo({
        state: this.cmView!.state as EditorState,
        dispatch: this.cmView!.dispatch,
      });
    },
    handleStateUpdate(viewUpdate: ViewUpdate) {
      const ranges = viewUpdate.state.selection.ranges;
      this.state.selected = ranges.reduce((plus, range) => plus + range.to - range.from, 0);
      this.state.cursor = ranges[0].anchor;
      // length
      this.state.length = viewUpdate.state.doc.length;
      this.state.lines = viewUpdate.state.doc.lines;
    },
    getLanguage(mode: string) {
      switch (mode) {
        case "php":
          return php();
        case "sass":
          return sass();
        case "html":
          return html();
        case "javascript":
          return javascript();
        case "css":
          return css();
        case "python":
          return python();
        case "sql":
          return sql();
        case "vue":
          return vue();
        case "xml":
          return xml();
        case "c#":
          return () => StreamLanguage.define(csharp);
        case "perl":
          return () => StreamLanguage.define(perl);
        case "kotlin":
          return () => StreamLanguage.define(kotlin);
        case "objective-c":
          return () => StreamLanguage.define(objectiveC);
        case "ceylon":
          return () => StreamLanguage.define(ceylon);
        case "swift":
          return () => StreamLanguage.define(swift);
        case "c++":
          return cpp();
        case "c":
          return () => StreamLanguage.define(c);
        case "ruby":
          return () => StreamLanguage.define(ruby);
        case "scala":
          return () => StreamLanguage.define(scala);
        case "java":
          return java();
        case "vb":
          return () => StreamLanguage.define(vb);
      }
      return javascript();
    },
  },
});
</script>
<style scoped lang="scss">
@import "node_modules/shared-components/assets/style/color.scss";
.editor {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $c_jet_gray;
  color: $c_dim_gray;
  .divider {
    height: 1px;
    background-color: $c_platinum;
  }

  .main {
    background-color: $c_jet_gray;
  }

  .footer {
    height: 3rem;
    padding: 0 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 90%;

    .buttons {
      .item {
        margin-right: 1em;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        padding: 0 5px 0 5px;
        border: 1px dashed $c_black;
        font-size: 12px;
        color: $c_dim_gray;
        cursor: pointer;
        .iconfont {
          margin-left: 4.4px;
        }
        &:hover {
          color: $c_platinum;
          border-color: $c_platinum;
        }
      }
    }

    .infos {
      .item {
        margin-left: 2em;
        display: inline-block;
        font-feature-settings: "tnum";
      }
    }
  }
}
</style>
