<template>
  <div>
    <access-list
      :desserts="desserts"
      :loading="loading"
      :cLoading="createLoading"
      :projects="projects"
      :teammembers="teammembers"
      @fetchTeammembers="fetchTeammembers"
      @accessAdded="accessAdded"
      @fetchAccess="fetchAccess"
      @fetchProjects="fetchProjects"
      @revokeAndGrant="revokeAndGrant"
      @notify="sendNotification"
      @showError="showError"
    >
    </access-list>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import AccessList from "./AccessList.vue";
import Access, { ProjectOfAccess, TeamMemberOfAccess } from "shared-components/src/models/Access";
import ApiService from "shared-components/src/services/ApiService";
import UserInfo from "shared-components/src/models/UserInfo";
import { Teammember } from "shared-components/src/models/Teammember";
import AccessService from "shared-components/src/services/AccessService";
import ProjectService from "@/services/ProjectService";
import TeamMemberService from "@/services/TeamMemberService";
import { Profile } from "shared-components/src/definitions/config";
import { TeammemberApi } from "shared-components/src/services/openApi/api";
import store from "@/store";

type actions = "grant" | "revoke" | "send grant request" | "send revoke request";

interface DessertItem {
  data: Access;
  action: actions | null;
}

export default defineComponent({
  components: {
    AccessList,
  },
  data() {
    return {
      createLoading: false,
      teammembers: [] as Array<{ id: string; Name: string }>,
      desserts: [] as DessertItem[],
      loading: true,
      projects: {
        loading: true,
        items: [] as Array<{ id: string; Name: string }>,
      },
    };
  },
  methods: {
    async getTeamMember(model: any): Promise<Teammember | null> {
      return new Promise((resolve, reject) => {
        if (model.teamMember) {
          TeamMemberService.getById(model.teamMember.id)
            .then((tmResult) => resolve(tmResult))
            .catch((tmError) => reject(tmError));
        } else {
          resolve(null);
        }
      });
    },

    async fetchProjects(): Promise<void> {
      this.projects.items = (await ProjectService.getList())
        .filter((c) => c.Name)
        .map((item) => {
          const retVal = { id: item.id, Name: item.Name };
          return retVal;
        });
      this.projects.loading = false;
    },
    async fetchAccess(): Promise<void> {
      let items = [] as Access[];
      items = await AccessService.getList();
      this.desserts = items.map((access) => {
        return { action: null, data: access } as DessertItem;
      });
      this.loading = false;
    },
    async accessAdded(accessItem: any): Promise<void> {
      this.createLoading = true;

      const model = { Email: accessItem.email, IsActive: true, Tool: accessItem.tool, Url: accessItem.url, Notes: accessItem.notes } as Access;
      model.TeamMember = {} as TeamMemberOfAccess;
      const teamMember = await this.getTeamMember(accessItem);
      if (teamMember && teamMember.Id) {
        model.TeamMember.id = teamMember.Id;
        model.TeamMember.Name = `${teamMember.FirstName} ${teamMember.LastName}`;
      } else {
        this.createLoading = false;
        this.showError("team member not found!");
      }
      if (accessItem.project) {
        model.Project = { id: accessItem.project.id, Name: accessItem.project.Name } as ProjectOfAccess;
      }
      model.id = (await AccessService.create(model)).id;
      this.desserts.push({ data: model, action: null });
      this.createLoading = false;
    },
    async fetchTeammembers(): Promise<void> {
      new TeammemberApi().getTeammemberList().then(items => {
        this.teammembers = items.data.filter((c) => c.FirstName && c.FirstName.trim() && c.LastName && c.LastName.trim())
        .map((item) => {
          return { id: item.Id, Name: item.FirstName + " " + item.LastName };
        }) as Array<{ id: string; Name: string }>;
      })
    },
    async revokeAndGrant() {
      this.loading = true;
      this.grantingItems.forEach(async (item) => {
        if (!item.data.GrantedOn) {
          item.data.GrantedOn = await AccessService.grant(item.data.id);
        }
      });
      this.revokingItems.forEach(async (item) => {
        if (!item.data.RevokedOn) {
          item.data.RevokedOn = await AccessService.revok(item.data.id);
        }
      });
      this.loading = false;
    },
    showError(msg: string): void {
      store.dispatch("showErrorMessage", msg)
    },
    async sendNotification(items: Access[]): Promise<void> {
      items.forEach(async (item) => {
        await ApiService.get(`/access/sendNotif/${this.userInfo.userName}/${item.id}`);
      });
    },
  },
  computed: {
    userInfo(): UserInfo {
      return store.state.userInfo;
    },
    grantingItems(): DessertItem[] {
      return this.desserts.filter((x) => x.action === "grant");
    },
    revokingItems(): DessertItem[] {
      return this.desserts.filter((x) => x.action === "revoke");
    },
  },
});
</script>
