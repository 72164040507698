<template>
  <div :class="classnames" :style="{ cursor, userSelect }" @mousedown="onMouseDown">
    <slot></slot>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      isResizing: false,
    };
  },

  computed: {
    classnames() {
      return ["multipane", "layout-h", this.isResizing ? "is-resizing" : ""];
    },
    cursor() {
      return this.isResizing ? "col-resize" : "";
    },
    userSelect() {
      return this.isResizing ? "none" : "";
    },
  },

  methods: {
    onMouseDown({ target: resizer, pageX: initialPageX, pageY: initialPageY }) {
      if (resizer.className && resizer.className.match("multipane-resizer-h")) {
        let self = this;
        let { $el: container } = self;

        let pane = resizer.previousElementSibling;
        let nextPane = resizer.nextElementSibling;
        let { offsetWidth: initialPaneWidth } = pane;
        let { offsetWidth: initialNextPaneWidth } = nextPane;

        const { addEventListener, removeEventListener } = window;

        const resize = (initialSize, initialNextSize, offset = 0) => {
          let containerWidth = container.clientWidth;
          let paneWidth = initialSize + offset;
          let nextPanWidth = initialNextSize - offset;
          nextPane.style.width = (nextPanWidth / containerWidth) * 100 + 0.2 + "%";
          return (pane.style.width = (paneWidth / containerWidth) * 100 + "%");
        };

        self.isResizing = true;
        let size = resize();

        const onMouseMove = function ({ pageX, pageY }) {
          size = resize(initialPaneWidth, initialNextPaneWidth, pageX - initialPageX);
        };

        const onMouseUp = function () {
          size = resize(pane.clientWidth, nextPane.clientWidth);

          self.isResizing = false;

          removeEventListener("mousemove", onMouseMove);
          removeEventListener("mouseup", onMouseUp);
        };

        addEventListener("mousemove", onMouseMove);
        addEventListener("mouseup", onMouseUp);
      }
    },
  },
});
</script>

<style lang="scss">
.multipane {
  display: flex;

  &.layout-h {
    flex-direction: row;

    .multipane-resizer-h {
      display: block;
      position: relative;
      z-index: 2;
      width: 10px;
      height: 100%;
      margin-left: -10px;
      left: 5px;
      cursor: col-resize;
    }
  }
}
</style>
