<template>
  <div id="skillsDetailTab">
    <v-form ref="frmMain" lazy-validation>
      <Skill
        v-if="isAfterSignUp"
        @showLimitationMessage="showLimitationMessage"
        @skillDownload="skillDownload"
        @skillCertificateChanged="skillCertificateChanged"
        @saveSkill="saveSkill"
        @remove="remove"
        @showEditSkill="showEditSkill"
        @sendAddRequest="sendSkillAddRequest"
        @closeEditModal="closeEditModal"
        :details="details"
        :uploadedCertificate="uploadedCertificate"
        :sendReqeustLoading="skillLoading"
        :showEditSkillModal="showEditSkillModal"
        :addSkillLoading="skillLoading"
        :removeSkillloading="skillLoading"
        :skills="skills"
        :teammemberSkills="teammemberSkills"
        :tmModel="teamMember"
        :isGettingAllSection="isGettingAllSection"
      />
      <LanguageSkillComponent
        v-if="isAfterSignUp"
        @setLanguagesState="setLanguagesState"
        class="mt-5"
        :isAfterSignUp="isAfterSignUp"
        :isGettingAllSection="isGettingAllSection"
        :languageSkills="languages"
      />
      <CertificateComponent
        v-if="isAfterSignUp"
        class="mt-5"
        :skills="skills"
        :teammemberSkills="teammemberSkills"
        :isGettingAllSection="isGettingAllSection"
        :isAfterSignUp="isAfterSignUp"
        :certificates="certificates"
        @scrollToElement="scrollToElement"
        @getCertificates="getCertificates"
      />
      <v-card-actions class="mt-5">
        <v-col md="12" class="text-center pt-0">
          <v-col md="12" class="text-center pb-0 pt-0">
            <p>You're one step closer to unlocking access to working with the world's top companies!</p>
          </v-col>
          <div class="wizard-action">
            <v-btn class="primary_btn_v2" @click="goBack" :disabled="submitting" :loading="submitting"><v-icon>mdi mdi-arrow-left</v-icon>Back</v-btn>
            <v-tooltip top attach>
              <template v-slot:activator="{ props }">
                <v-btn class="secondary_btn" v-bind="props" @click="getAllDataAndSubmit(true)" :disabled="submitting" :loading="submitting"
                  ><v-icon>mdi mdi-check</v-icon>Save for later</v-btn
                >
              </template>
              <span>You can save your progress and return to the application process later</span>
            </v-tooltip>

            <v-btn class="primary_btn_v2" @click="getAllDataAndSubmit(false)" :disabled="submitting" :loading="submitting"
              >Continue <v-icon>mdi mdi-arrow-right</v-icon></v-btn
            >
          </div>
        </v-col>
      </v-card-actions>
    </v-form>
    <v-dialog v-model="showSaveForLaterConfirm" max-width="500" @keydown.esc="showSaveForLaterConfirm = false">
      <confirm-dialog :loading="savingLoading" :message="confirmModel" @confirm="saveForLator" @cancel="cancelSaving"></confirm-dialog>
    </v-dialog>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Skill from "shared-components/src/components/Profile/skill/Skill.vue";
import validations from "shared-components/src/utils/validations";
import LanguageSkillComponent from "shared-components/src/components/Profile/languageSkill/LanguageSkill.vue";
import CertificateComponent from "shared-components/src/components/Profile/certificate/Certificate.vue";
import ConfirmDialog from "shared-components/src/components/ConfirmDialog.vue";
import Details from "shared-components/src/models/Details";
import { ProjectMessages } from "shared-components/src/definitions/constants";
import store from "@/store";

export default defineComponent({
  props: [
    "teamMember",
    "formLoading",
    "sendReqeustLoading",
    "loading",
    "details",
    "isAfterSignUp",
    "languages",
    "skills",
    "teammemberSkills",
    "skillLoading",
    "uploadedCertificate",
    "showEditSkillModal",
    "certificates",
  ],
  components: {
    Skill,
    LanguageSkillComponent,
    CertificateComponent,
    ConfirmDialog,
  },
  data() {
    return {
      validations,
      dataLoading: false,
      jobRoles: [] as Details[],
      jobTitles: [] as Details[],
      shouldRequest: true,
      requestLoading: false,
      showSaveForLaterConfirm: false,
      confirmModel: {
        title: "",
        text: "",
      },
      submitting: false,
      isGettingAllSection: false,
      savingLoading: false,
    };
  },
  methods: {
    setLanguagesState(items: any) {
      this.$emit("setLanguagesState", items);
    },
    getCertificates() {
      this.$emit("getCertificates");
    },
    closeEditModal() {
      this.$emit("closeEditModal");
    },
    showEditSkill() {
      this.$emit("showEditSkill");
    },
    sendSkillAddRequest(skillName: any) {
      this.$emit("sendAddRequest", skillName);
    },
    remove(skillModel: any) {
      this.$emit("remove", skillModel);
    },
    saveSkill(skillModel: any) {
      this.$emit("saveSkill", skillModel);
    },
    skillCertificateChanged(skill: any) {
      this.$emit("skillCertificateChanged", skill);
    },
    skillDownload(certificateUrl: any) {
      this.$emit("skillDownload", certificateUrl);
    },
    showLimitationMessage(text: any) {
      this.$emit("showLimitationMessage", text);
    },
    goBack() {
      this.$emit("goBack", 2);
    },
    async getAllDataAndSubmit(isForLator = false) {
      const isValid = await (this.$refs.frmMain as any).validate();
      if (isValid.valid) {
        if (isForLator) {
          this.confirmModel.title = `Save Progress for Later?`;
          this.confirmModel.text =
            "You're almost ready to move on to your personalized screening experience. Are you sure you want to save your progress for later instead?";
          this.showSaveForLaterConfirm = true;
        } else {
          this.$emit("submitSkillsDetail", { step: 3 });
        }
      } else {
        var el = document.querySelector("#skillsDetailTab .v-messages.error--text:first-of-type");
        this.scrollToElement(el);
      }
    },
    saveForLator() {
      store.dispatch("showSuccessMessage", ProjectMessages.succesfullyAddSkillDetails)
      this.cancelSaving();
    },
    cancelSaving() {
      this.showSaveForLaterConfirm = false;
    },
    scrollToElement(el: any) {
      this.$emit("scrollToElement", el);
    },
    loadJobRoleAndJobTitle() {
      this.jobRoles = this.details.filter((item: any) => item.Type == "JobRole");
      this.jobTitles = this.details.filter((item: any) => item.Type == "JobTitle");
    },
  },
  watch: {
    details(newVal) {
      this.loadJobRoleAndJobTitle();
    },
    formLoading(newVal) {
      this.dataLoading = newVal;
    },
    loading(newVal) {
      this.submitting = newVal;
    },
    sendReqeustLoading(newVal) {
      this.requestLoading = newVal;
    },
  },
  mounted() {
    this.loadJobRoleAndJobTitle();
    this.$emit("getProfessionalDetail");
  },
});
</script>
