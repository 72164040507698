import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_ctx.token && _ctx.isVerified)
    ? (_openBlock(), _createBlock(_component_v_card, {
        key: 0,
        loading: _ctx.pageLoading
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            md: "12"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("h3", null, "Your email address is verified successfully.", -1)
            ])),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["loading"]))
    : _createCommentVNode("", true)
}